.item-list-main-container {
  border: 2px solid #1E1E1E33;
  border-radius: .5rem;
  display: flex;
  padding: 0px;
  flex-direction: row;
  align-self: stretch;
  margin-bottom: 8px;
}

.item-list-container {
  display: grid;
  flex-direction: column;
}

.item-list-image-container {
  height: auto;
}

.item-list-image-container img {
  width: 100%;
  height: 100%;
}

.item-list-content {
  display: flex;
  padding: 16px;
  padding-left: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-bottom: 8px;
  width: 100%;
}

.item-list-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.line-item-price-container-option {
  align-self: flex-end;
}

.item-list-trash-can-icon {
  display: none;
}

.item-list-trash-button {
  background: none !important;
  margin: 0 !important;
  color: inherit;
  border: none;
  padding: .5rem;
  font: inherit;
  cursor: pointer;
  outline: none !important;
  transition: .3s ease-in-out;
}

.item-list-trash-icon:hover {
  opacity: .7;
  background-image: url('../images/trash-can-red-icon.svg');
}

.line-item-content {
  display: flex;
  flex-direction: column;
}

.line-item-name-text,
.line-item-content p {
  color: #212121;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  line-height: normal;
}

.line-item-content:hover a {
  text-decoration: none;
}

.line-item-content p {
  margin-bottom: 0;
  align-self: stretch;
  font-feature-settings: 'calt' off;
  font-size: 10px;
  font-weight: 400;
  margin-left: 2px;
}

.line-item-name-text {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.item-portioning-main-checkbox {
  display: flex;
  flex-direction: row;
}

.item-portioning-main-checkbox img {
  padding: 6px 8px;
  align-self: first baseline;
}

.item-portiong-checkbox-items-container {
  display: flex;
  flex-direction: column;
}

.item-portioning-size-quantity {
  color: rgba(30, 30, 30, 0.50);
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
}

.item-portioning-checkbox-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.item-portioning-checkbox-item h5 {
  align-self: flex-end;
  margin-bottom: 0;
  padding-left: 8px;
}

.item-portiong-select-span {
  margin-left: 8px;
}

.item-portioning-checkbox-item select {
  width: 48px;
  height: 23px;
}

.item-portiong-select-span,
.item-portioning-checkbox-item select {
  padding: 0px 8px;
  gap: 8px;
  border-radius: 64px;
  background: rgba(255, 255, 255, 0.60);

  color: rgba(30, 30, 30, 0.60);
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;

}

.item-list-price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: -15px;
}

.line-item-product-price,
.line-item-product-total-price {
  color: #1E1E1E;
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
}

.line-item-product-total-price {
  color: #1C8E47;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.line-item-prices-container {
  align-self: flex-end;
}

.item-portioning-tooltip-container {
  position: relative;
  display: inline-block;
}

.item-portioning-tooltip-message {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  border: 1px solid rgba(30, 30, 30, 0.50);
  background: #FFF;
  z-index: 1;
  width: 150px;
  color: rgba(30, 30, 30, 0.80);
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.item-list-container,
.item-portioning-edit-container {
  width: 100%;
}

.item-slider-content {
  width: 85%;
}

.item-portioning-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}

.item-portioning-size-block {
  padding: 2px 10px;
  background: #FFF;
}

.item-portioning-size-quantity-bold-text {
  color: rgba(30, 30, 30, 0.80);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
}

.line-item-observation-main {
  margin-top: 10px;
}

.line-item-observation-main form {
  width: 100%;
}

.line-item-observation-main form textarea {
  width: 100%;
  color: rgba(30, 30, 30, 0.50);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;

  display: flex;
  height: 87px;
  padding: 8px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  background: #F2F5FF;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}

.item-btn-save-observation {
  display: flex;
  justify-self: flex-end;
  width: 55%;
  margin: 1rem 0;
}

.item-btn-save-observation button {
  border-radius: 8px;
  background: #1C8E47;
}

.item-btn-save-observation .button-row {
  width: 100%;
  padding: 0;
  flex-direction: row-reverse;
}

.item-btn-save-observation .row {
  margin: 0;
}

@media only screen and (max-width: 750px) {

  .item-list-main-container {
    flex-direction: column;
  }

  .item-list-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line-item-price-container-option {
    display: none;
  }

  .item-list-image-container {
    flex-direction: column;
    width: 100%;
  }

  .item-list-trash-can-icon {
    display: block;
    align-self: flex-end;
    padding: 10px 10px 0px 0px;
  }

  .item-list-container {
    margin-bottom: 10px;
  }

  .item-portioning-tooltip-container {
    margin: 0px 20px;
  }

  .item-list-content {
    padding: 16px;
    margin-bottom: 0;
  }

  .item-portioning-edit {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5px;
    padding-right: 5px;
  }

  .item-slider-content {
    width: 98%;
  }

  .item-portioning-size-block {
    margin-top: 46px;
    width: 100%;
    text-align: center;
    padding: 0px;
  }

  .item-portioning-size-quantity {
    display: flex;
    align-self: center;
    flex-direction: column;
  }

  .item-btn-save-observation {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 75%;
  }


  .item-btn-save-observation .button-row {
    width: 100%;
  }

  .image-list-main {
    margin: auto;
    width: 100%;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1200px) {
  .item-btn-save-observation {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .item-btn-save-observation {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 65%;
  }
}
