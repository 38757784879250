.panel-card {
  width: 100%;
  height: auto;
  margin: 0;
  border-radius: 0.5rem;
  border: 1px solid rgba(30, 30, 30, 0.5);
}

.panel-card.completed {
  border: none !important;
}

.panel-card .panel-card-header {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #fff;
}

.panel-card .panel-card-header .panel-card-header-info {
  display: flex;
  gap: .5rem;
  align-items: center;
  justify-content: center;
}

.panel-card .panel-card-header .panel-card-header-button {
  background: none;
  outline: none;
  border: none;
  align-self: flex-end;
  transition: all .5s ease-in-out;
}

.panel-card .panel-card-header .panel-card-header-button i {
  color: #fff;
  font-size: 1.5rem;
}

.panel-card .panel-card-header .panel-card-header-button:hover {
  opacity: .6;
}

.panel-card .panel-card-header.completed {
  background-color: #1c8e47 !important;
}

.panel-card .panel-card-header.open {
  border-radius: 0.5rem 0.5rem 0px 0px !important;
  border-bottom: 2px solid rgba(30, 30, 30, 0.2);
}

.panel-card .panel-card-header-step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  border: 1px solid #fff;
  color: #eb5b1e;
  border: 1px solid #eb5b1e;
}

.panel-card .panel-card-header-step.completed {
  color: #fff;
  border: 1px solid #fff;
}

.panel-card .panel-card-header-step.open {
  color: #eb5b1e;
}

.panel-card .panel-card-header-title {
  margin: 0;
  color: #ffff;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  color: #1e1e1e;
}

.panel-card .panel-card-header-title.completed {
  color: #ffff;
}

.panel-card .panel-card-header-title.open {
  margin: 0;
  color: #1e1e1e;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
}

.panel-card .panel-card-body {
  visibility: hidden;
  max-height: 0;
  overflow: hidden !important;
  transition: max-height 0.5s ease-in-out;
}

.panel-card .panel-card-body-open {
  display: block;
  padding: 2rem 1.5rem;
  visibility: visible;
  max-height: 2000px;
}

.spinner-style {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.panel-card .panel-card-header-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width:1000px) {
  .panel-card .panel-card-header-title {
    font-size: 1rem !important;
  }
}
