.input-with-address-autocomplete-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  position: relative;
}


.input-with-address-autocomplete-label {
  position: absolute;
  pointer-events: none;
  transition: 0.5s ease all;
  left: 5px;
  top: 18px;
}



.input-with-address-autocomplete-label:focus~label,
.input-with-address-autocomplete-label:valid~label,
.input-with-address-autocomplete-label:-webkit-autofill~label {
  top: -10px;
  font-size: 12px;
}

.input-with-address-autocomplete {
  border: none;
  outline: none !important;
  border: 1px solid rgba(30, 30, 30, 0.5);
  border-radius: 8px;
  width: 100%;
  padding: 8px;
}

.input-with-address-autocomplete-list {
  position: absolute;
  background-color: #f9f9f9;
  left: 0;
  top: 58px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.input-with-address-autocomplete-list-item {
  color: black;
  padding: 12px 16px;
  display: block;
}

.input-with-address-autocomplete-list-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}



.input-with-address-autocomplete::placeholder {
  background: transparent;
}

.input-with-address-autocomplete-error {
  color: #ce3c25;
  font-size: 10px;
}

.input-with-address-autocomplete-error-animation {
  -webkit-animation-name: inputWithAddressError;
  animation-name: inputWithAddressError;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@-webkit-keyframes inputWithAddressError {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
