.countdown-wrapper {
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.countdown-title {
  font-weight: 700;
  color: white;
  font-size: 130px;
  text-shadow:
    0 0 5px rgba(255, 255, 255, 0.7),
    0 0 10px rgba(255, 255, 255, 0.11),
    0 0 15px rgba(255, 0, 0, 0.6);
  width: 50%;
}

.countdown-description {
  color: white;
  font-weight: 700;
  font-size: 40px;
}

@media (max-width: 1280px) {
  .countdown-wrapper {
    flex-direction: column;
  }

  .countdown-title {
    width: 100%;
    text-align: center;
    font-size: 120px;
  }
}

@media (max-width: 768px) {
  .countdown-wrapper {
    border-radius: 0;
  }
}

@media (max-width: 600px) {
  .countdown-wrapper {
    flex-direction: column;
    border-radius: 0;
  }

  .countdown-title {
    width: 100%;
    text-align: center;
    font-size: 100px;
  }
}

@media (max-width: 500px) {
  .countdown-wrapper {
    padding: 1rem 4rem;
  }

  .countdown-title {
    width: 100%;
    text-align: center;
    font-size: 80px;
  }
}
