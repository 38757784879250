.cart-indicator-main {
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-indicator-link:hover {
  text-decoration: none;
}

.cart-indicator-main > a:hover {
  text-decoration: none;
}

.cart-indicator-main > a:focus {
  text-decoration: none;
}
