#wishlist-search .ais-SearchBox {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 46px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px;
}

#wishlist-search .ais-SearchBox-form {
  width: 100%;
  height: 100%;
}

#wishlist-search .ais-SearchBox-input {
  padding: 8px 16px;
  background-color: #fff !important;
  border: none !important;
  outline: none !important;
  border: 1px solid rgba(30, 30, 30, 0.5) !important;
  border-radius: 1rem !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  appearance: none;
  background: transparent;
  display: inline-block;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  box-shadow: none !important;
  background: #ffffff !important;
  padding: 0;
  padding-right: 36px;
  padding-left: 46px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  content: '';
  appearance: none;
}

#wishlist-search .ais-SearchBox-submit {
  position: absolute;
  top: 0;
  right: inherit;
  left: 0;
  margin: 0;
  border: 0;
  border-radius: 4px;
  padding: 0;
  width: 46px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}


#wishlist-search .ais-SearchBox-submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: '' 2;
}


#wishlist-search .ais-SearchBox-reset {
  display: none;
  position: absolute;
  top: 0px;
  right: 13px;
  bottom: 0;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: #bfc7d8;
}

#wishlist-search .ais-SearchBox-reset:focus {
  outline: 0;
}


#wishlist-search .ais-SearchBox-reset svg {
  display: block;
  margin: 0px 4px 4px 4px;
  width: 12px;
  height: 12px;
}

#wishlist-search .ais-SearchBox-input:valid~.ais-SearchBox-reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
}



/* ALGOLIA OVERRIDE BY US*/

#wishlist-search .ais-SearchBox-submit {
  background-color: #ce3c25 !important;
  color: white !important;

  font-size: 20px !important;
  font-weight: bold !important;

  width: 50px;
  height: 37px;

  border-radius: 7px 0px 0px 7px !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

#wishlist-search .ais-SearchBox-submit svg {
  fill: white;
  height: 20px;
  width: 20px;
  stroke-width: 12px;
}

#wishlist-search .use {
  stroke-width: 12px;
  font-weight: bold;
}

#wishlist-search .ais-SearchBox-input {
  background-color: #f6f6f6;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-indent: 15px !important;
  height: 37px;
  flex-grow: 1;
  border-width: 1px;
  border-radius: .5rem !important;
  border-style: none;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

#wishlist-search .ais-InstantSearch__root {
  height: 37px;
  width: 100%;
}

#wishlist-search .ais-SearchBox {
  height: 37px;
  width: 100%;
  max-width: 100%;
}

#wishlist-search .ais-Pagination-link.ais-Pagination-link--selected {
  color: #eb5b1e !important;
}

@media only screen and (max-width: 750px) {
  #wishlist-search .ais-SearchBox-submit {
    background-color: #ce3c25 !important;
    color: #fff !important;
    font-size: 20px !important;
    font-weight: bold !important;
    width: 50px;
    height: 37px;
    border: 1px solid #f6f6f6 !important;
    border-radius: 7px 0px 0px 7px !important;
    box-shadow: none !important;
  }

  #wishlist-search .ais-SearchBox-submit svg {
    fill: #fff !important;
    height: 20px;
    width: 20px;
    stroke-width: 12px;
  }

  #wishlist-search .ais-SearchBox-input {
    background-color: #f6f6f6;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-indent: 5px !important;
    height: 37px;
    flex-grow: 1;
    border-width: 1px;
    border-radius: 7px 7px 7px 7px !important;
    border-style: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
}
