.nav-list-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.nav-list-main>a:nth-child(1) {
  color: #ce3c25 !important;
  font-weight: 700;
}

.nav-list-main>a {
  font-family: 'Open sans', sans-serif;
  color: #292b2c !important;
  font-size: 13px !important;
  font-weight: 400;
  position: relative;
  transition: 0.5s ease-in-out;
  padding: 0 5px;
  border-right: 1px solid rgba(30, 30, 30, 0.5);
  white-space: nowrap;
}

.nav-list-main>h3>a {
  font-family: 'Open sans', sans-serif;
  color: #ce3c25 !important;
  font-size: 13px;
  font-weight: 700;

  padding: 0 5px;
  border-right: 1px solid rgba(30, 30, 30, 0.5);
  transition: 0.5s ease-in-out;
}

.nav-list-departments a {
  border: none !important;
}

.nav-list-main>a:hover {
  text-decoration: none;
  cursor: pointer;
  filter: opacity(0.6);
}

.nav-list-main>h3>a:hover {
  text-decoration: none;
  cursor: pointer;
  filter: opacity(0.6);
}

.nav-list-main .nav-list-blend>a {
  position: relative;
}

.nav-list-main {
  max-width: 800px;
  overflow: hidden;
  white-space: nowrap;
}

.nav-list-main::-webkit-scrollbar {
  height: .2rem;
}

.nav-list-main::-webkit-scrollbar-track {
  background: rgba(31, 31, 31, 0.014);
  border-radius: 15px;
}

.nav-list-main::-webkit-scrollbar-thumb {
  background: rgba(30, 30, 30, 0.50);
  border-radius: 15px;
}

@media (max-width: 1400px) {
  .nav-list-main {
    max-width: 35.2rem;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    margin-right: 10px;
  }
}

@media (max-width: 1250px) {
  .nav-list-main {
    max-width: 28rem;
  }
}

@media (max-width: 1160px) {
  .nav-list-main {
    max-width: 21.2rem;
  }
}

@media (max-width: 1010px) {
  .nav-list-main {
    max-width: 14.6rem;
  }
}
