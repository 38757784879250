.product-sqaure-main {
  color: #212121;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 224px;
  gap: 4px;
  margin-right: 16px !important;
  overflow: hidden;
}

.product-square-image-section {
  width: 224px;
  height: 224px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 224px !important;
  cursor: pointer;
  position: relative;
}

.product-square-image-section-discount {
  position: absolute;
  width: 42px;
  height: 50px;
  right: 0;
  top: 0;
  clip-path: polygon(0 0, 100% 0%, 100% 65%, 50% 100%, 0 65%);
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Open Sans' !important;
  font-style: normal;
  color: #fff !important;
}

.product-square-image-section-discount>p {
  margin: 0;
  padding: 0;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 150%;
}

.product-square-image-section-discount>span {
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
}

.product-favorite-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.product-square-info-section {
  font-family: 'Open Sans';
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 224px;
  height: 60px;
}

.product-square-variant {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'calt' off;
  color: #212121;
  margin: 0;
  padding: 0;
}

.product-square-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  width: 224px;
  height: 44px;
  color: #212121;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-square-link {
  width: 224px;
  height: 224px;
}

.product-square-link:hover {
  cursor: pointer;
  text-decoration: none;
}

.product-square-shopping-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .product-sqaure-main {
    gap: 6px;
    width: 160px;
    margin-right: 8px !important;
    margin-bottom: 16px;
  }

  .product-square-image-section {
    width: 160px;
  }

  .product-square-image-section-discount {
    width: 33.6px;
    height: 40px;
  }

  .product-square-image-section-discount>p {
    font-size: 12px;
  }

  .product-square-image-section-discount>span {
    font-size: 6px;
  }

  .product-square-info-section {
    width: 160px;
    height: 46px;
  }

  .product-square-variant {
    font-size: 8px;
  }

  .product-square-title {
    height: 33px;
    width: 160px;
    font-size: 12px;
    line-height: 16px;
  }
}
