.order-line-item-main {
  color: #1E1E1E;
  text-decoration: none !important;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  min-height: 35px;
  margin-bottom: 1rem;
  background-color: none;
  border-bottom: 1px solid rgba(30, 30, 30, 0.20);
  transition: .5s ease-in-out;
  padding: 1rem;
  min-width: 100%;
}

.order-line-item-main:hover {
  cursor: pointer;
  opacity: .7;
  color: #1E1E1E;
}

.order-line-item-main-actions {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.order-line-item-main .order-line-item-section {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.order-line-item-main .order-line-item-section-title {
  font-size: 0.875rem;
  font-family: "Open Sans", sans-serif;
  color: rgba(30, 30, 30, 0.50);
  font-style: normal;
  font-weight: 400;
  margin: 0;
  line-height: normal;
}

.order-line-item-main .order-line-item-section-text {
  font-size: 1.25rem;
  font-weight: 700;
  color: #1E1E1E;
  margin: 0;
  font-family: "Open sans", sans-serif;
}

.order-line-item-section-text-alt {
  color: #EB5B1E !important;
}

.order-line-order-copy-button {
  padding: .5rem;
  border: 1px solid #EB5B1E;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  gap: .5rem;
  transition: .5s ease-in-out;
  outline: none !important;
  background: none;
}

.order-line-order-copy-button:hover {
  opacity: .7;
}

.order-line-order-see-more-button {
  text-decoration: none !important;
  padding: .5rem;
  border: 1px solid #EB5B1E;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  transition: .5s ease-in-out;
  background: none;
  color: #EB5B1E !important;
  font-weight: 700;
}

.order-line-order-see-more-button:hover {
  opacity: .7;
}

@media screen and (max-width: 1000px) {
  .order-line-item-main {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-line-item-main .order-line-item-section {
    margin-bottom: 1rem;
  }

  .order-line-item-main-actions .order-line-order-see-more-button {
    width: 100%;
    justify-content: center;
  }


  .order-line-item-main-actions {
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: flex-end;
    margin: .5rem 0;
    width: 100%;
  }

  .order-line-item-main .order-line-item-section-title {
    font-size: 0.875rem;
  }

  .order-line-item-main .order-line-item-section-text {
    font-size: 1rem;
  }
}
