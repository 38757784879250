.about-us-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 104px;
  margin-top: 1.5rem;
  margin-bottom: 5rem;
}

.about-us-video-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}

.about-us-video-row>.video-player-container {
  width: auto;
}

.about-us-video {
  width: 48rem;
  height: 27rem;
}

.about-us-text-column {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 1rem;
}

.about-us-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
}

.about-us-row :nth-child(2)>img {
  padding-left: 1rem !important;
}

.about-us-row-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.about-us-row-animation {
  width: 8rem;
  height: 8rem;
}

.about-us-row-item h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  color: #ce3c25;
}

.about-us-row-item p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #1e1e1e;
}

@media screen and (max-width: 1000px) {
  .about-us-wrapper {
    padding: 0 16px;
  }

  .about-us-video {
    width: 22.5rem;
    height: 15.625rem;
  }

  .about-us-row {
    flex-wrap: wrap;
  }

  .about-us-video-row {
    align-items: center;
    justify-content: center;
  }

  .about-us-row-item p {
    text-align: left;
  }
}
