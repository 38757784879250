.terms-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1440px;
  padding: 0 104px;
  margin: 0 auto;
  margin-bottom: 80px;
  margin-top: 20px;
}

.terms-main-text {
  font-size: 18px;
  text-align: justify;
  line-height: 1.7rem;
}

@media only screen and (max-width: 750px) {
  .terms-main {
    padding: 0 16px;
  }
}
