.timed-promotion-container {
  display: flex;
  padding: 64px 0px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.timed-promotion-announcement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.timed-promotion-announcement-title {
  color: #1e1e1e;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  white-space: nowrap;
}

.timed-promotion-announcement-title b {
  background-image: linear-gradient(90deg, #ce3c25 0%, #eb5b1e 57.81%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bolder;
}

.timed-promotion-announcement-timer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.timed-promotion-announcement-timer-text {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}

.timed-promotion-announcement-timer-countdown {
  display: flex;

  gap: 4px;
}

.timed-promotion-announcement-timer-countdown > div,
span {
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.timed-promotion-announcement-timer-countdown > span {
  font-size: 40px;
}

.timed-promotion-announcement-timer-countdown-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.timed-promotion-announcement-timer-countdown-value {
  display: flex;
  border-radius: 8px;
  padding: 8px;
  color: #fff;
}

.timed-promotion-product-content {
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-end;
}
.timed-promotion-product-variant {
  display: flex;
  justify-content: flex-end;
}
.timed-promotion-product-link {
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.timed-promotion-product-square {
  display: none;
}

.timed-promotion-product-image {
  display: flex;
  height: 160px;
  width: 160px;
}

.timed-promotion-product-image img {
  object-fit: cover;
}

.timed-promotion-product-description p {
  margin: 0;
}
.timed-promotion-product-description {
  width: 40%;
  margin: 0 25px;
}

.timed-promotion-product-description-manufacutrer {
  color: #212121;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.timed-promotion-product-description-name {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.timed-promotion-product-cost {
  scale: 1.2;
  padding-right: 20px;
}

@media screen and (max-width: 1156px) {
  .timed-promotion-product-square {
    display: flex;
  }
  .timed-promotion-product-content {
    display: none;
  }
}
@media screen and (max-width: 950px) {
  .timed-promotion-container {
    flex-direction: column;
    gap: 16px;
  }
  .timed-promotion-announcement {
    align-items: center;
  }
  .timed-promotion-announcement-timer {
    align-items: center;
  }
  .timed-promotion-product {
    margin-top: 16px;
  }
  .timed-promotion-announcement-title {
    font-size: 32px;
  }
}
