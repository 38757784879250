.password-recovery-main {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 104px;
}

.password-recovery-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.password-recovery-box {
  margin-bottom: 2rem;
  width: 100%;
  max-width: 449px;
  height: auto;
  border: 1px solid rgba(30, 30, 30, 0.5);
  border-radius: 8px;
  padding: 2rem;
}

.password-recovery-box .password-recovery-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.password-recovery-box .password-recovery-image {
  width: 60px;
  height: 60px;
  margin-bottom: 1.5rem;
}

.password-recovery-box .password-recovery-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.password-recovery-box .password-recovery-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;
}

.password-recovery-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2.5rem;
}

.password-recovery-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #EB5B1E;
  margin-bottom: 2.5rem;
  text-align: center;
  position: relative;
}

.password-recovery-title::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: #EB5B1E;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: -16px;
}



@media screen and (max-width:1000px) {
  .password-recovery-main {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
  }
}
