.image-carousel-img {
  position: relative;
  width: 100%;
  height: auto;
}

.image-carousel-wrappe {
  position: relative;
}

.image-carousel-wrapper .slick-list {
  border-radius: 16px;
}

.image-carousel-wrapper .slick-dots {
  position: absolute;
  bottom: 0;
  right: 50%;
  left: 45%;
  list-style: none;
  display: flex !important;
  padding: 0 !important;
  margin: 0 !important;
  gap: 0.5rem;
}

.image-carousel-wrapper .slick-dots button {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  color: transparent;
  border: none !important;
  outline: none !important;
  border: 1px solid #ce3c25 !important;
  background-color: rgba(255, 255, 255, 0.7);
}

.image-carousel-wrapper .slick-dots li.slick-active button {
  background-color: #ce3c25 !important;
}

.banner-arrow-container-right {
  position: absolute;
  z-index: 100;

  top: 40%;
  right: 5px;
}

.banner-arrow-container-left {
  position: absolute;
  z-index: 100;

  top: 40%;
  left: 5px;
}

@media screen and (max-width: 1000px) {
  .image-carousel-wrapper {
    position: relative;
  }

  .image-carousel-wrapper .slick-dots {
    right: 45%;
    left: 45%;
  }
}

@media only screen and (max-width: 750px
  /*SMALL_MEDIA_SIZE*/
) {
  .banner-arrow-container-right {
    display: none;
  }

  .banner-arrow-container-left {
    display: none;
  }

  .image-carousel-wrapper {
    padding: 0 16px;
  }
}

@media screen and (max-width: 480px) {
  .image-carousel-wrapper .slick-list {
    border-radius: 16px;
  }
}
