.login-box-main {
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  border: 1px solid rgba(30, 30, 30, 0.5);
  border-radius: 8px;
  max-width: 449px;
}
.login-box-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.login-box-actions > .checkbox-div {
  padding: 0;
  margin: 0;
}
.login-box-actions .checkbox-div > label {
  padding: 0;
  margin: 0;
}
.login-box-forgot-password {
  margin: 0;
  padding: 0;
}

.login-box-forgot-password > a {
  color: #626166;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.login-box-forgot-password > a {
  color: #eb5b1e;
}

.login-box-forgot-password > a:hover {
  color: #eb5c1eb9;
}
.login-box-fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1000px) {
  .login-box-main {
    padding: 1rem;
  }
}
