@font-face {
  font-family: QUARTZO-Light;
  src: url('../fonts/QUARTZO-light.ttf');
}
.controlled-modal-backdrop {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.controlled-modal-title {
  font-family: QUARTZO-Light, sans-serif;
  font-weight: 400;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
}
.controlled-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
}
.controlled-modal-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.controlled-modal-card {
  position: relative;
  width: 22rem;
  height: 25.625rem;
  background-color: #ce3c25;
  border-radius: 1rem;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
}
.controlled-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  min-height: 100%;
  padding: 0 35px 0 35px;
}
.controlled-modal-text {
  width: 100%;
  font-size: 0.875rem;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  text-align: center;
  max-height: 5.25rem;
  margin-bottom: 1rem;
}
.controlled-modal-btn-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}
.controlled-modal-btn,
.controlled-modal-btn-close {
  font-family: 'Open-sans', sans-serif;
  outline: none !important;
  border: none !important;
  background-color: #fff;
  color: #ce3c25;
  border-radius: 6px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
  width: 100%;
  transition: all ease-in-out 0.5s;
  cursor: pointer;
}
.controlled-modal-btn {
  opacity: 0.9;
}
.controlled-modal-btn-close {
  background-color: transparent;
  width: 1.875rem;
  color: #fff;
  font-size: 1.5rem;
}
.controlled-modal-section-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  width: 100%;
}
.controlled-modal-card-image {
  height: 10.5rem;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}
.controlled-modal-zoomIn {
  -webkit-animation-name: controlled-modal-zoomIn;
  animation-name: controlled-modal-zoomIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes controlled-modal-zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes controlled-modal-zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .controlled-modal-title {
    font-size: 1.3rem;
  }
  .controlled-modal-card {
    width: 21rem;
    height: 26rem;
  }
}

@media (max-width: 600px) {
  .controlled-modal-title {
    font-size: 1.1rem;
  }
  .controlled-modal-card-image {
    height: 6.2rem;
  }
  .controlled-modal-card {
    width: 18rem;
    height: 22rem;
  }
}
