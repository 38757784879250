.confirmation-page-container {
  gap: 10px;
  margin: auto;
  max-width: 1440px;
  margin-bottom: 150px;
  margin-top: 20px;
  overflow: hidden;
}

.confirmation-page-content {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
}

.confirmation-page-title-box {
  order: 1;
  color: #1C8E47;
  font-size: 40px;
}

.confirmation-page-description-box {
  order: 3;
  color: rgba(30, 30, 30, 0.50);
  font-size: 16px;
}

.confirmation-page-navigation-button-box {
  order: 4;
}

.confirmation-page-orders-button-box {
  order: 5;
}

.confirmation-page-image-box {
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.confirmation-page-title-box,
.confirmation-page-description-box {
  margin: 0;
  flex: 1;
  font-family: "Open sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.confirmation-page-title-box span,
.confirmation-page-description-box span {
  color: #1C8E47;
  font-weight: 700;
}

.confirmation-page-go-to-navigation-button,
.confirmation-page-go-to-orders-button {
  color: #FFF;
  text-align: center;
  font-family: "Open sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-decoration: none;
}

.confirmation-page-go-to-navigation-button:hover,
.confirmation-page-go-to-orders-button:hover {
  color: #FFF;
  text-decoration: none;
}

.confirmation-page-go-to-navigation-button {
  border-radius: 16px;
  background: linear-gradient(90deg, #CE3C25 0%, #EB5B1E 57.81%);
  margin-bottom: 32px;
  padding: 16px;
}

.confirmation-page-go-to-orders-button {
  border-radius: 16px;
  background: #1C8E47;
  padding: 16px 8px;
}

@media (min-width: 767px) {
  .confirmation-page-container {
    gap: 10px;
    padding: 0px 0px 104px 76px;
    margin: auto;
    max-width: 1440px;
  }

  .confirmation-page-content {
    grid-template-columns: 1fr 1fr;
    padding: 0px 50px;
  }

  .confirmation-page-go-to-navigation-button,
  .confirmation-page-go-to-orders-button {
    font-size: 24px;
  }

  .confirmation-page-image-box {
    grid-column: 2;
    grid-row: 1 / span 3;
    width: 600px;
    height: 600px;
  }

  .confirmation-page-title-box,
  .confirmation-page-description-box {
    margin: 0;
    flex: 1;
    font-family: "Open sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .confirmation-page-title-box {
    color: #1C8E47;
    font-size: 40px;
    margin-top: 100px;
  }

  .confirmation-page-title-box span,
  .confirmation-page-description-box span {
    color: #1C8E47;
    font-weight: 700;
  }

  .confirmation-page-description-box {
    color: rgba(30, 30, 30, 0.50);
    font-size: 16px;
    width: 600px;
    max-width: 980px;
    margin-top: -80px;
  }

  .confirmation-page-navigation-button-box {
    display: flex;
    margin-top: -50px;
    height: 100px;
    align-items: center;
    text-align: center;
  }

  .confirmation-page-orders-button-box {
    margin-top: -100px;
    display: flex;
    height: 50px;
    text-align: center;
    align-items: center;
  }

  .confirmation-page-navigation-button-box a,
  .confirmation-page-orders-button-box a {
    text-decoration: none;
    width: 100%;
  }

  .confirmation-page-navigation-button-box a:hover,
  .confirmation-page-orders-button-box a:hover {
    color: white;
  }
}

@media (max-width: 767px) {
  .confirmation-page-container {
    margin: 16px;
  }

  .confirmation-page-title-box {
    order: 1;
    margin: auto;
    margin-top: 16px;
    text-align: center;
  }

  .confirmation-page-image-box {
    order: 2;
    width: 100%;
    height: auto;
  }

  .confirmation-page-description-box {
    order: 3;
    margin-top: 0;
    text-align: center;
    margin-bottom: 16px;
  }

  .confirmation-page-navigation-button-box {
    order: 4;
    display: block;
    margin-top: 0;
    height: auto;
  }

  .confirmation-page-orders-button-box {
    order: 5;
    margin-top: 0;
    height: auto;
  }

  .confirmation-page-go-to-navigation-button,
  .confirmation-page-go-to-orders-button {
    display: block;
    margin: 10px 0;
  }

  .confirmation-page-image {
    order: 2;
    width: 100%;
    max-width: 350px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .confirmation-page-container {
    padding: 0px 40px;
  }

  .confirmation-page-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .confirmation-page-title-box {
    order: 1;
    margin-top: 20px;
    text-align: center;
  }

  .confirmation-page-image-box {
    order: 2;
    width: 100%;
    height: auto;
    grid-column: 2;
  }

  .confirmation-page-description-box {
    order: 3;
    margin-top: 20px;
  }

  .confirmation-page-navigation-button-box {
    order: 4;
    margin-top: 20px;
    display: flex;
    height: 100px;
    align-items: center;
    text-align: center;
    width: 270px;
  }

  .confirmation-page-orders-button-box {
    order: 5;
    margin-top: 20px;
    display: flex;
    height: 50px;
    text-align: center;
    align-items: center;
    width: 270px;
    margin-top: 0px;
    margin-bottom: 32px;
  }

  .confirmation-page-go-to-navigation-button,
  .confirmation-page-go-to-orders-button {
    font-size: 20px;
    padding: 12px 6px;
    margin: 10px 0;
  }
}

@media (max-width: 1024px) and (min-width: 500px) and (max-height: 600px) and (orientation: landscape) {
  .confirmation-page-image {
    order: 2;
    width: 100%;
    max-width: 500px;
    height: 400px;
  }
}
