.input-field-error-message-text {
  color: #ce3c25;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin-left: 3px;
  width: 100%;
}

@media only screen and (max-width: 450px) {
  .input-field-error-message-text {
    font-size: 8px;
  }
}