.cart-page-content-main-container {
  margin-bottom: 4rem;
}

.cart-page-content-main-container .scroller-main {
  padding: 0 340px;
}

.cart-page-out-of-stock-notice {
  max-width: 1440px;
  gap: 16px;
  margin: 0 auto;
  padding: 0px 104px;
  max-width: 1440px;
}

.cart-page-left-container {
  width: 75%;
}

.cart-page-right-container {
  position: sticky;
  top: 0;
  height: 325px;
}

.cart-page-left-container>.cart-panel-container {
  margin-bottom: 16px;
}

.cart-page-main-container {
  display: flex;
  gap: 16px;
  margin: 0 auto;
  padding: 40px 104px;
  max-width: 1440px;
  padding-top: 16px;
  flex-direction: row;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cart-big-club-preview {
  max-height: 94px;
  overflow: hidden;
  margin-bottom: 16px;
}

.cart-resume-preview-container {
  max-height: 220px;
  margin-bottom: 16px;
}

.cart-list-line {
  width: 100%;
  max-width: 1500px;
  border-width: 2px;
}

.cart-list-price {
  width: 100%;
}

.cart-list-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cart-list-button:hover {
  cursor: pointer;
}

.cart-list-button-disabled:hover {
  cursor: default;
}

.cart-list-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ce3c25;
  color: #fff;
  border-radius: 2px;
  width: 200px;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
}

.cart-list-btn-disabled {
  background-color: #a5a5a5;
  pointer-events: none;
}

.cart-list-btn:hover {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}

.cart-page-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.cart-page-empty>h3 {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0px 5px 30px 5px;
  text-align: center;
}

.cart-page-empty>img {
  max-width: 250px;
  height: auto;
  margin-bottom: 60px;
}

.cart-page-left-content-container {
  width: 75%;
}

@media only screen and (max-width: 600px) {
  .cart-page-right-container {
    position: static;
  }

  .cart-page-left-container {
    margin-bottom: 300px;
  }
}

@media only screen and (max-width: 767px) {

  .cart-page-content-main-container .scroller-main {
    padding: 0 25px;
  }

  .cart-page-out-of-stock-notice {
    padding: 0px 20px;
  }

  .cart-page-left-container {
    position: static;
    margin-bottom: 0px;
    max-height: none !important;
  }

  .cart-page-right-container .cart-resume-preview-container {
    margin-top: 2rem;
  }

  .cart-page-left-container,
  .cart-page-right-container {
    width: 100%;
  }

  .cart-page-left-container {
    max-height: 250px;
  }

  .cart-page-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 20px;
    margin: 16px;
    margin-bottom: 2rem;
  }

  .cart-page-content-main-container .scroller-main {
    padding: 1rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .cart-page-content-main-container .scroller-main {
    padding: 0 35px;
    margin: auto;
  }

  .cart-page-main-container {
    padding: 40px 15px;
    margin: auto;
    flex-direction: column;
  }

  .cart-page-left-container {
    width: 100%;
  }

  .cart-page-right-container {
    position: static;
  }

  .cart-page-content-main-container .scroller-main {
    padding: 40px 15px !important;
    margin: auto;
  }
}


@media only screen and (max-width: 1870px) {
  .cart-page-content-main-container .scroller-main {
    padding: 0 104px;
    margin: auto;
    max-width: 1440px;
  }
}
