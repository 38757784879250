.big-club-banner-container {
  margin: 10px auto;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  height: 64px;
  border-radius: 16px;
  position: relative;
  border-radius: 16px;
  padding: 0.5rem;
  background: linear-gradient(90deg, #ce3c25 0%, #eb5b1e 57.81%);
  text-decoration: none !important;
}

.big-club-banner-divisor {
  width: 1px;
  background-color: #fff;
  height: 1.5rem;
}

.big-club-banner-image {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.big-club-banner-title {
  padding: 0;
  margin: 0;
  color: #fff;
  z-index: 1;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
}

.big-club-banner-title > span {
  font-weight: 400;
}

@media screen and (max-width: 700px) {
  .big-club-banner {
    padding: 0 8px;
  }

  .big-club-banner-container {
    height: 48px;
  }

  .big-club-banner-title {
    font-size: 12px;
  }

  .big-club-banner-container .big-club-banner-image {
    max-width: 150px;
  }
}
