.faq-hit-main {
  width: 100%;
  padding: 20px 0;
  cursor: pointer;
}
.faq-hit-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}
.faq-hit-header .faq-hit-header-line {
  width: 100%;
  min-height: 1px !important;
  background: rgba(30, 30, 30, 0.5);
  border-radius: 8px;
}
.faq-hit-header > h3 {
  font-size: 1.5rem;
  font-weight: 700;
  word-break: keep-all;
  min-width: fit-content;
}
.faq-hit-header > .faq-hit-header-button {
  border: none !important;
  background: none !important;
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.faq-hit-header > .faq-hit-header-button:hover {
  filter: opacity(0.7);
}
.faq-hit-header > .faq-hit-header-button:active {
  filter: brightness(1.1);
}
.faq-hit-main > p {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0px;
}
.faq-hit-fade-in {
  -webkit-animation-name: faq-fade-in;
  animation-name: faq-fade-in;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes faq-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes faq-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 1000px) {
  .faq-hit-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
  }
  .faq-hit-header > h3 {
    font-size: 1rem;
    min-width: auto;
  }
  .faq-hit-header .faq-hit-header-line {
    min-width: 20%;
  }
}
@media only screen and (max-width: 350px) {
  .faq-hit-main > h3 {
    font-size: 1.25rem;
    word-break: break-all;
    min-width: none;
  }

  .faq-hit-main > p {
    font-size: 0.8rem;
  }
}
