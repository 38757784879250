.cart-resume-preview-container {
  width: 100%;
  border: 2px solid #1E1E1E33;
  border-radius: .5rem;
  padding: 1rem;
}

.cart-resume-preview-header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
}

.cart-resume-preview-header h3 {
  margin-bottom: 0;
}

.cart-resume-preview-title {
  font-size: 1.25rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.cart-resume-preview-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5rem;
}

.cart-resume-preview-info-text {
  font-family: 'Open Sans', sans-serif;
  font-size: .9rem;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  color: rgba(30, 30, 30, 1);
}

.cart-resume-preview-info-text-alt {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  color: rgba(30, 30, 30, 1);
}

.cart-resume-preview-horizontal-rule {
  width: 100%;
  background: #1E1E1E33;
  height: 1px;
  margin: 1rem 0;
}

.cart-resume-preview-info-text-orange {
  font-size: 1.25rem;
  color: #EB5B1E;
}

.cart-resume-previewinfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cart-resume-preview-button {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #EB5B1E;
}

.cart-resume-preview-btn {
  color: #FFF;
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-resume-preview-btn:hover {
  text-decoration: none;
  color: #ffffff;
}

.cart-list-button-disabled {
  background-color: #a5a5a5;
  pointer-events: none;
}
