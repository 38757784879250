@font-face {
  font-family: QUARTZO-Light;
  src: url('../fonts/QUARTZO-light.ttf');
}

.barbecue-calculator-landing-banner-container {
  background-image: url('../images/barbecue-landing-banner-background.jpg');
  background-size: 100vw;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 36.5625%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.barbecue-calculator-landing-banner-content {
  max-width: 80%;
  text-align: center;
  flex-direction: column;
}

.barbecue-calculator-landing-banner-button-content {
  margin-bottom: 50%;
}

.barbecue-calculator-landing-header {
  background: #000;
  display: flex;
  overflow-wrap: break-word;
  justify-content: center;
  align-items: center;
}

.barbecue-calculator-landing-header .phrase {
  padding: 4vw 0;
  height: 100%;
  font-family: QUARTZO-Light;
  color: #fff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.barbecue-calculator-landing-first-box-content {
  height: 55vw;
  max-height: 500px;
  width: 100%;
  background: #eb5b1c;
  padding: 0 10%;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}

.barbecue-calculator-landing-first-box-text {
  color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 60%;
  text-align: center;
}

.barbecue-calculator-landing-first-box-title {
  font-family: QUARTZO-Light;
  font-weight: 400;
  margin-bottom: 5px;
}

.barbecue-calculator-landing-first-box-subtitle {
  margin: auto;
  max-width: 80%;
}

.barbecue-calculator-landing-first-box-image {
  margin-left: 20px;
  border-radius: 50%;
  width: 40%;
  max-width: 343px;
  height: auto;
}

.barbecue-calculator-landing-second-box-content {
  height: 55vw;
  max-height: 500px;
  width: 100%;
  background: #fff;
  padding: 0 10%;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}

.barbecue-calculator-landing-second-box-text {
  display: flex;
  color: #ec5a1d;
  flex-direction: column;
  max-width: 60%;
  text-align: center;
}

.barbecue-calculator-landing-second-box-title {
  font-family: QUARTZO-Light;
  font-weight: 400;
  margin-bottom: 5px;
}

.barbecue-calculator-landing-second-box-subtitle {
  margin: auto;
  max-width: 80%;
}

.barbecue-calculator-landing-second-box-image {
  margin-right: 20px;
  border-radius: 50%;
  width: 40%;
  max-width: 343px;
  height: auto;
}

.barbecue-calculator-landing-button-box-content {
  height: 55vw;
  max-height: 500px;
  width: 100%;
  background: #eb5b1c;
  padding: 0 10%;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}

.barbecue-calculator-landing-button-box-title {
  font-family: QUARTZO-Light;
  color: white;
  text-align: center;
}

.barbecue-calculator-landing-header .phrase,
.barbecue-calculator-landing-first-box-title,
.barbecue-calculator-landing-second-box-title,
.barbecue-calculator-landing-button-box-title {
  font-size: calc(calc(40 / 1920) * 100vw);
}

.barbecue-calculator-landing-first-box-subtitle,
.barbecue-calculator-landing-second-box-subtitle {
  font-size: calc(calc(26 / 1920) * 100vw);
}

@media screen and (max-width: 960px) {

  .barbecue-calculator-landing-header .phrase,
  .barbecue-calculator-landing-container .title,
  .barbecue-calculator-landing-button-box-title {
    font-size: 21px;
  }

  .barbecue-calculator-landing-container .subtitle {
    font-size: 17px;
  }
}

@media screen and (max-width: 500px) {

  .barbecue-calculator-landing-header .phrase,
  .barbecue-calculator-landing-container .title,
  .barbecue-calculator-landing-button-box-title {
    font-size: 12px;
  }

  .barbecue-calculator-landing-container .subtitle {
    font-size: 10px;
  }
}

@media screen and (max-width: 360px) {

  .barbecue-calculator-landing-header .phrase,
  .barbecue-calculator-landing-container .title,
  .barbecue-calculator-landing-button-box-title {
    font-size: calc(calc(12 / 360) * 100vw);
  }

  .barbecue-calculator-landing-container .subtitle {
    font-size: calc(calc(10 / 360) * 100vw);
  }
}