.container {
  width: 170px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
}

.out-of-stock-text {
  font-size: 14px;
  font-weight: 500;
  color: #ce3c25;

  margin: 0px;
  text-align: center;
  letter-spacing: 1px;
}

.without-button {
  font-size: 18px;
}
