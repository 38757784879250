.wishlist-create-backdrop {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}


.wishlist-create-modal {
  background-color: #fefefe;
  margin: 0 auto;
  width: 700px;
  border-radius: 1rem;
  border: 1px solid rgba(30, 30, 30, 0.20);
  background: #FFF;
  padding: 1rem 1.5rem;
  box-shadow: 0px 0.31286px 0.79136px 0px rgba(0, 0, 0, 0.05), 0px 0.71085px 1.79803px 0px rgba(0, 0, 0, 0.07), 0px 1.2372px 3.12938px 0px rgba(0, 0, 0, 0.08), 0px 1.9654px 4.97131px 0px rgba(0, 0, 0, 0.09), 0px 3.03247px 7.67037px 0px rgba(0, 0, 0, 0.11), 0px 4.73193px 11.969px 0px rgba(0, 0, 0, 0.12), 0px 7.85574px 19.87041px 0px rgba(0, 0, 0, 0.14), 0px 15.69616px 39.70205px 0px rgba(0, 0, 0, 0.19);
}

.wishlist-create-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.wishlist-create-modal-close:hover,
.wishlist-create-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.wishlist-create-modal-title {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Open sans", sans-serif;
  color: #1e1e1e;
  margin-bottom: 1.5rem;
}

.wishlist-create-modal-subtitle {
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Open sans", sans-serif;
  color: #1e1e1e;
}

.wishlist-create-modal-row {
  display: flex;
}

.wishlist-create-modal-grid .ais-Hits-list {
  padding: 1rem 0;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: .5rem;
  height: 400px;
  overflow: auto !important;
  width: 100%;
}


@media screen and (max-width: 1000px) {
  .wishlist-create-modal {
    width: 90%;
  }

}
