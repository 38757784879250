.progressive-image-loader-final-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    transition: opacity 0.3s ease-in;
    opacity: 1;
}

.progressive-image-loader-mini-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    filter: blur(10px);
    transform: scale(1);
    opacity: 0.7;
}
