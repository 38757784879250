.bottomrow-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  margin-bottom: 10px;
}
.bottomrow-bigclub-main {
  margin-top: 1rem;
  text-decoration: none !important;
}

.bottomrow-bigclub {
  outline: #ce3c25 !important;
  padding: 12px 20px;
  border-radius: 8px;
  border: 1px solid #ce3c25;
  color: #ce3c25;
  transition: all 0.5s ease-in-out;
  font-family: 'Open Sans';
  cursor: pointer;
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  -webkit-font-smoothing: antialiased;
}

.bottomrow-bigclub > p {
  font-size: 26px;
  margin: 0;
}

.bottomrow-bigclub > div {
  width: 0.5px;
  height: 24px;
  background-color: #1e1e1e;
}
.bottomrow-bigclub > span {
  font-size: 12px;
  color: #1e1e1e;
}

.bottomrow-bigclub:hover {
  filter: opacity(0.7);
}
.bottomrow-bigclub:active {
  filter: opacity(0.5);
}
@media screen and (max-width: 800px) {
  .bottomrow-main {
    display: none;
  }
}
