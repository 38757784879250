.landing-banner {
  height: 30.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
}
.landing-banner-text-on-top {
  align-items: flex-start;
  padding-top: 2rem;
}
.landing-banner-text {
  font-size: min(2.6rem, 5vw);
  font-weight: 700;
  width: 45%;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.landing-banner-text > span {
  font-size: min(3rem, 10vw);
  color: #fdd532;
  text-align: center;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1366px) {
  .landing-banner {
    height: 24.875rem;
  }
}

@media screen and (max-width: 800px) {
  .landing-banner-text {
    width: 80%;
  }

  .landing-banner {
    height: 13.875rem;
  }
}
