.my-account-evaluations {
  border-radius: 1rem;
  background-color: #EDF2F499;
  padding: 1rem;
}

.my-account-evaluations-title {
  color: #1E1E1E;
  font-family: "Open Sans", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: .5rem;
}

.my-account-evaluations-divisor {
  width: 100%;
  height: 1px;
  background: rgba(30, 30, 30, 0.20);
}
