.christmas-base-banner-main-container {
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  margin-bottom: 4rem
}

.christmas-base-banner-top-content {
  display: flex;
  margin-bottom: 1rem;
  gap: .5rem;
}

.christmas-base-banner-top-content-left {
  flex: .7;
  height: 537px;
}

.christmas-base-banner-top-content-left video {
  height: 537px;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem 0 0 1rem;
}

.christmas-base-banner-top-content-right {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.christmas-base-banner-top-content-top {
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: #1E1E1E;
  align-items: center;
  border-radius: 0 1rem 0 0;
}

.christmas-base-banner-top-content-top-text h6 {
  color: #FFF;
  font-family: "Raleway", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.christmas-base-banner-top-content-top-text p {
  color: #FFF;
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.18px;
}

.christmas-base-banner-top-content-top div:first-of-type {
  width: 70%;
  height: 265px;
  background-image: url('../images/christmas-page-steak-example.png');
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: .5rem;
}

.christmas-base-banner-top-content-bottom {
  flex: 2;
  display: flex;
  gap: .5rem;
}

.christmas-base-banner-top-content-bottom-left {
  background: #EB5B1E;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 264px;
}

.christmas-base-banner-top-content-bottom-left-text {
  padding: 1.5rem .5rem 1.5rem 2rem;
}

.christmas-base-banner-top-content-bottom-left h6 {
  color: #EDEDED;
  font-family: "Raleway", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.christmas-base-banner-top-content-bottom-left p {
  color: #EDEDED;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.christmas-base-banner-top-content-bottom-left {
  flex: 2;
}

.christmas-base-banner-top-content-bottom-left div:first-of-type {
  width: 150%;
  height: 264px;
  flex-direction: column;
  background-image: url('../images/christmas-page-cutting-example.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.christmas-base-banner-top-content-bottom-right {
  flex: 1;
}

.christmas-base-banner-top-content-bottom-right {
  background: #CE3C25;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 1rem 0;
  border: none;
}

.christmas-base-banner-top-content-bottom-right div:nth-of-type(2) {
  background-image: url('../images/christmas-page-meat-example.png');
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0 0 1rem 0;
}

.christmas-base-banner-top-content-bottom-right-text {
  padding: 24px;
}

.christmas-base-banner-top-content-bottom-right-text h6 {
  color: #EDEDED;
  font-family: "Raleway", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.christmas-base-banner-top-content-bottom-right-text p {
  color: #EDEDED;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.christmas-base-banner-lower-content {
  background-image: url('../images/christmas-page-base-banner-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem;
  border-radius: 16px;
  width: 100%;
  margin: auto;
}

.christmas-base-banner-lower-container {
  position: relative;
}

.christmas-base-banner-lower-container img:first-of-type {
  position: absolute;
  top: -12px;
  right: -19px;
  z-index: 1;
}

.christmas-base-banner-lower-container img:nth-of-type(2) {
  position: absolute;
  top: 268px;
  right: -16px;
  z-index: 1;
}

.christmas-base-banner-lower-container img:nth-of-type(3) {
  position: absolute;
  top: 290px;
  left: -15px;
  z-index: 1;
}

.christmas-base-banner-text-lower-content-text {
  padding: 0 1rem;
}

.christmas-base-banner-lower-content h1,
.christmas-base-banner-lower-content span {
  color: #fff;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64.5px;
  letter-spacing: -1px;
  width: 100%;
  display: block;
}

.christmas-base-banner-lower-content span {
  font-weight: 700;
}

.christmas-base-banner-lower-links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  margin: auto;
  margin-top: 2rem;
  gap: 5px;
}

.christmas-base-banner-link {
  text-decoration: none;
  border-radius: 8.659px;
  background: #ce3c25;
  width: 250px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-feature-settings: 'calt' off;
  font-family: 'Open Sans', sans-serif;
  font-size: 19.483px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.978px;
  letter-spacing: -0.195px;
}

.christmas-base-banner-link:hover {
  color: #fff;
  text-decoration: none;
}

.christmas-base-banner-text-lower-content-text span {
  display: inline;
}

@media only screen and (max-width: 767px) {
  .christmas-base-banner-top-content {
    flex-direction: column;
    margin-bottom: .5rem;
  }

  .christmas-base-banner-top-content>h1 {
    font-size: 56px;
    font-weight: 700;
    width: 100%;
    padding: 0 2rem;
    padding-bottom: 1rem;
  }

  .christmas-base-banner-text-lower-content-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .christmas-base-banner-top-content-first-container {
    flex-direction: column;
    width: 100%;
  }

  .christmas-base-banner-top-content-first-container div {
    margin-bottom: 2rem;
    padding-top: 3rem;
    width: 330px;
  }

  .christmas-base-banner-top-content-second-container {
    width: 330px;
    margin: auto;
    padding-top: 3rem;
  }

  .christmas-base-banner-top-content-second-container p {
    margin: 1rem 0 2rem 0;
    width: 280px;
  }

  .christmas-base-banner-lower-content {
    margin-top: 70px;
    width: 100%;
    border-radius: 1rem;
    padding: 1rem 0rem;
    background-size: 100% 100%;
    background-position: center;
    background-size: cover;
    background-image: url('../images/christmas-page-base-banner-mobile-image.png');
  }

  .christmas-base-banner-lower-container img {
    display: none;
  }

  .christmas-base-banner-lower-content h1 {
    padding: 2.5rem 1rem 0 1rem;
    width: 100%;
  }

  .christmas-base-banner-lower-content h1,
  .christmas-base-banner-lower-content span {
    font-size: 34px;
    font-style: normal;
    line-height: 37.5px;
    letter-spacing: -1px;
    text-align: center;
  }

  .christmas-base-banner-signup-link {
    margin-bottom: 1rem;
  }

  .christmas-base-banner-lower-links {
    flex-direction: column;
  }

  .christmas-base-banner-top-content-left {
    flex: auto;
  }

  .christmas-base-banner-top-content-left video {
    height: 537px;
    width: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }

  .christmas-base-banner-top-content-bottom {
    display: flex;
    flex-direction: column;
  }

  .christmas-base-banner-top-content-top {
    flex-direction: column;
    flex: auto;
    border-radius: 1rem;
  }

  .christmas-base-banner-top-content-top div:first-of-type {
    width: 100%;
    height: 250px;
    background-image: url('../images/christmas-page-base-banner-mobile-meat-image.png');
    background-size: cover;
    margin-bottom: 1.5rem;
  }

  .christmas-base-banner-top-content-top-text {
    padding: 1rem 2rem;
  }

  .christmas-base-banner-top-content-bottom-left {
    flex-direction: column;
    border-radius: 1rem;
  }

  .christmas-base-banner-top-content-bottom-left div:first-of-type {
    width: 100%;
    height: 250px;
    flex-direction: column;
    background-image: url('../images/christmas-page-cutting-example-mobile.png');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 1rem 1rem 1rem 1rem;
  }

  .christmas-base-banner-top-content-bottom-left-text {
    padding: 2rem 2.5rem;
  }

  .christmas-base-banner-top-content-bottom-right {
    border-radius: 1rem;
  }

  .christmas-base-banner-top-content-bottom-right div:nth-of-type(2) {
    background-image: url('../images/christmas-page-mobile-meat-image.png');
    background-repeat: no-repeat;
    height: 200px;
    border-radius: 1rem 1rem 0 0;
  }

  .christmas-base-banner-top-content-bottom-right-text {
    order: 1;
  }

  .christmas-base-banner-lower-content {
    margin-top: 0;
    height: 500px;
  }

  .christmas-base-banner-text-lower-content-text h1 {
    padding: 0rem .3rem;
    width: 100%;
  }

  .christmas-base-banner-lower-links {
    width: 70%;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .christmas-base-banner-main-container {
    margin-bottom: 0;
  }

  .christmas-base-banner-top-content-first-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .christmas-base-banner-top-content {
    flex-direction: column;
  }

  .christmas-base-banner-top-content-left video {
    border-radius: 1rem;
  }

  .christmas-base-banner-top-content-top div:first-of-type {
    background-size: cover;
  }

  .christmas-base-banner-top-content-top-text {
    padding: 0 1rem;
  }

  .christmas-base-banner-top-content-bottom-right-text {
    padding: 1rem;
  }

  .christmas-base-banner-top-content>h1 {
    padding: 0 3rem 1rem 3rem;
    width: 100%;
  }

  .christmas-base-banner-top-content-second-container {
    width: 90%;
    padding: 0 1rem 1rem 1rem;
  }

  .christmas-base-banner-lower-content {
    padding: 6rem 2rem 5rem 2rem;
  }

  .christmas-base-banner-lower-container img {
    display: none;
  }

  .christmas-base-banner-lower-content h1,
  .christmas-base-banner-lower-content span {
    font-size: 48px;
  }

  .christmas-base-banner-lower-links {
    width: 70%;
  }
}
