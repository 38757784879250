.checkout-page-loading {
  height: 400px;
}

.checkout-page-loading-container {
  min-height: 60vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;
  padding: 1rem;
}

.checkout-page-loading-title {
  font-size: 2rem;
  color: #1e1e1e;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;

}

@media screen and (max-width:1000px) {
  .checkout-page-loading-title {
    font-size: 1.5rem;
  }

  .checkout-page-loading {
    height: 256px;
  }
}
