.my-account-navigator-main {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background: rgba(237, 242, 244, 0.60);
  padding: 1rem;
  max-height: 300px;
}

.my-account-navigator-main-expand {
  max-height: 400px;
}

.my-account-navigator-main h3 {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-bottom: 1.5rem;
}

.my-account-navigator {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.my-account-navigator-main-link {
  background: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;

  width: 180px;
  height: 30px;
  padding: 1.2rem .5rem;
  color: #000;
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.my-account-navigator-dropdown-link-active {
  color: #EB5B1E;
}

.my-account-navigator-dropdown-link-active:hover {
  color: #EB5B1E !important;
}

.my-account-navigator-dropdown-button {
  border: none;
  cursor: pointer;
  outline: none;
}

.my-account-navigator-dropdown-button:focus,
.my-account-navigator-dropdown-button:active {
  outline: none;
}

.my-account-navigator-main-link-active {
  background-color: #fff;
  color: #000;
  font-weight: 700;
  border-left: 2px solid #EB5B1E;
}

.my-account-navigator-main-link:hover {
  color: #000;
  text-decoration: none;
}

.my-account-navigator-logout {
  color: #D0021B;
}

.my-account-navigator-logout:hover {
  color: #D0021B !important;
}

.my-account-mobile-navigator {
  display: none;
}

.my-account-dropdown-content {
  display: none;
}

.my-account-dropdown-content.open ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.my-account-dropdown-content.open a {
  margin-top: 1rem;
  width: 80%;
}

.my-account-dropdown-content.open {
  display: block;
  margin-top: .5rem;
  margin-bottom: 0;
  padding-left: 20px;
}

@media only screen and (max-width: 750px
  /*SMALL_MEDIA_SIZE*/
) {
  .my-account-navigator-main {
    max-height: 400px;
    width: 100%;
  }

  .my-account-navigator-main-expand {
    max-height: 500px;
  }

  .my-account-mobile-navigator {
    display: block;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    height: 30px;
  }

  .my-account-mobile-navigator:hover {
    cursor: pointer
  }

  .my-account-navigator-main-link {
    border-radius: 3px;
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
  }


}
