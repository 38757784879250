.bigclub-rules-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1440px;
  padding: 0 104px;
  margin: 0 auto;
  margin-bottom: 80px;
  margin-top: 20px;
}

.bigclub-rules-main-text {
  font-size: 18px;
  text-align: justify;
  line-height: 1.7rem;
}

.bigclub-rules-column {
  margin-bottom: 1rem;
}

.bigclub-rules-column p {
  margin-bottom: .2rem;
}


@media screen and (max-width:1000px) {
  .bigclub-rules-main {
    padding: 1rem;
  }
}
