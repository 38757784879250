.past-order-index-container {
  background: rgba(237, 242, 244, 0.60);
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.past-order-index-title {
  color: #1E1E1E;
  font-family: "Open Sans", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
