.christmas-landing-page-main-container {
  display: flex;
  gap: 16px;
  margin: 0 auto;
  padding: 40px 104px;
  padding-bottom: 0;
  max-width: 1440px;
  padding-top: 16px;
  flex-direction: column;
  box-sizing: border-box;
}

@media only screen and (max-width: 767px) {
  .christmas-landing-page-main-container {
    padding: 1.5rem 1rem;
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .christmas-landing-page-main-container {
    padding: 40px 50.5px;
  }
}
