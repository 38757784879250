.wishlist-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 104px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.wishlist-header {
  width: 100%;
  background: #EDF2F499;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  border-radius: .5rem;
}

.wishlist-header-content {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.wishlist-header-button {
  border: none !important;
  outline: none !important;
  padding: .25rem .5rem;
  border-radius: .5rem;
  background-color: #EB5B1F;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.wishlist-header-button:hover {
  opacity: .7;
}

.wishlist-header-icon {
  width: 2rem;
  height: 2rem;
}

.wishlist-header-title {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  max-width: 600px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.wishlist-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.wishlist-row-button {
  width: 100%;
  outline: none !important;
  background: #ffff;
  padding: .5rem;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  color: rgba(30, 30, 30, 0.50);
  border: 1px solid rgba(30, 30, 30, 0.50);
  transition: .5s ease-in-out;
  border-radius: .5rem;
}

.wishlist-row-button:hover {
  border: 1px solid #EB5B1F;
  color: #EB5B1F;
}

.wishlist-row-button.active {
  background: #EDF2F499;
  border: 1px solid #EB5B1F;
  color: #fff;
  background-color: #EB5B1F;
}


@media screen and (max-width:1000px) {
  .wishlist-container {
    padding: 1rem;
  }

  .wishlist-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .wishlist-header-button {
    width: 100%;
  }

  .wishlist-row-button {
    font-size: .875rem;
  }

  .wishlist-header-title {
    font-size: 1rem;
    margin: 0;
  }
}
