.black-friday-answers-main-container-closed {
  width: 100%;
  border-radius: 8px;
  border: 2px solid rgba(30, 30, 30, 0.80);
}

.black-friday-answers-main-container-open {
  border-radius: 8px;
  border: 2px solid #EB5B1E;
  width: 100%;
}

.black-friday-answers-main-container-open .black-friday-answers-answer-container {
  border-top: 2px solid rgba(30, 30, 30, 0.80);
}

.black-friday-answers-question-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: .5rem .5rem .5rem .5rem;
  justify-content: space-between;

  color: rgba(30, 30, 30, 0.80);
  font-family: "Open Sans", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.black-friday-answers-answer-container {
  padding: .5rem 4rem .5rem .5rem;
  color: #212121;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.black-friday-answers-answer-container p {
  margin-bottom: 0;
}

.black-friday-answers-question-text-open {
  color: #1E1E1E;
}

.black-friday-answers-question-container p {
  margin-bottom: 0;
}

.black-friday-answers-question-container button {
  background: none;
  color: none;
  border: none;
  padding: 0;
  font: none;
  cursor: pointer;
  outline: none !important;
  margin-right: 1rem;
}

.black-friday-answers-question-container .arrowIconUp {
  color: #EB5B1E;
}

@media only screen and (max-width: 767px) {

  .black-friday-answers-question-container {
    font-size: 20px;
  }

  .black-friday-answers-answer-container {
    font-size: 16px;
    font-weight: 400;
  }

  .black-friday-answers-answer-container {
    padding: .5rem .5rem .5rem .5rem;
  }
}
