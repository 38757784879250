html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  position: relative;
}

a {
  text-decoration: none !important;
}

h1 {
  font-family: 'Roboto', sans-serif;
}

h3 {
  font-family: 'Roboto', sans-serif;
}
