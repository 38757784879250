.past-order-main {
  border-radius: 1rem;
  background: rgba(237, 242, 244, 0.60);
  padding: 1rem;
  margin-bottom: 1rem;
}

.past-order-title {
  letter-spacing: 1px;
  font-size: 1.25rem;
  font-weight: 700;
  font-family: "Open sans", sans-serif;
}


.past-order-main .past-order-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(30, 30, 30, 0.20);
  padding-bottom: 1rem;
}

.past-order-header .past-order-header-actions {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.past-order-header-actions .past-order-header-action,
.past-order-header-action-alt {
  text-decoration: none !important;
  border: none !important;
  outline: none !important;
  padding: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  color: #eb5b1e;
  font-size: 1rem;
  border: 1px solid #eb5b1e !important;
  border-radius: .5rem;
  font-weight: bold;
  transition: .5s ease-in-out;

}

.past-order-header-actions .past-order-header-action:hover,
.past-order-header-action-alt:hover {
  opacity: .7;
}

.past-order-header-actions .past-order-header-action-alt {
  background-color: #eb5b1e;
  color: #fff;
}

.past-order-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
  padding: 1.5rem 0;
  border-bottom: 2px solid rgba(30, 30, 30, 0.20)
}

.past-order-info .past-order-info-item {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.past-order-info-divisor {
  width: 100%;
  height: 2px;
  background-color: #1E1E1E;
  height: 1px;
}

.past-order-info-item .past-order-info-item-text {
  margin: 0;
  padding: 0;
  color: rgba(30, 30, 30, 0.50);
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.past-order-info-item .past-order-info-item-title {
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: #1E1E1E;
  text-overflow: ellipsis;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}

.past-order-details {
  margin-top: 10px;
  font-size: 18px;
  letter-spacing: 1px;
  color: #1E1E1E;
  font-weight: 700;
  word-spacing: 2px;
}

.past-order-information {
  padding: 1rem 0;
}

.past-order-information-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
}

.past-order-information-container-right {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  width: 45%;
}

.past-order-information-sub-container {
  margin-bottom: 15px;
}

.past-order-information-sub-container>h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
}

.past-order-information-sub-container>p {
  font-size: 16px;
  color: #4f4f4f;
  font-weight: 400;
  margin: 0px;
  letter-spacing: 1px;
}

.past-order-information-payment-method>p {
  font-size: 16px;
  color: #4f4f4f;
  font-weight: 400;
  margin: 0px;
  letter-spacing: 1px;
}

.past-order-copy-button {
  cursor: pointer;
  background-color: #eb5b1e;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  border-radius: 3px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70%;
  height: 30px;
}

.past-order-copy-button:hover {
  background-color: #d6541c;
}

.past-order-copy-button:focus {
  outline: none;
}

.see-nfe-button {
  margin-top: 10px;
  cursor: pointer;
  background-color: #eb5b1e;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 3px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70%;
  height: 30px;
}

.see-nfe-button>i {
  margin-right: 5px;
  color: #ffffff;
}

.see-nfe-button:hover {
  background-color: #d6541c;
  text-decoration: none;
  color: #ffffff;
}

.see-nfe-button:focus {
  outline: none;
  text-decoration: none;
  color: #ffffff;
}


@media screen and (max-width: 1000px) {
  .past-order-main .past-order-header {
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  }

  .past-order-header .past-order-header-actions {
    width: 100%;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 500px) {
  .past-order-index-container {
    border-radius: 1rem !important;
    background: rgba(237, 242, 244, 0.60) !important;
  }

  .past-order-copy-button {
    width: 100%;
  }

  .see-nfe-button {
    width: 100%;
  }

  .past-order-info {
    flex-direction: column;
    gap: 1rem;
  }

}
