@font-face {
  font-family: QUARTZO-Light;
  src: url('../fonts/QUARTZO-light.ttf');
}

.product-hit-main {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 15px 10px;
}

.product-hit-content {
  display: flex;
  width: 100%;
  height: 60px;
  margin-bottom: 3px;
}

.product-hit-image {
  width: 80px;
  height: 80px;
  margin-right: 15px;
}

.product-hit-description {
  font-size: 14px;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 0px;
  margin-left: 1px;

  width: 100%;
}

.product-hit-description>div {
  margin-bottom: 2px;
}

.product-hit-info {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.product-hit-info>.price-with-unit-main>h5 {
  margin-left: auto;
}

.product-hit-info>.price-with-unit-main>h6 {
  margin-left: auto;
}

.product-hit-name {
  font-size: 14px;
  font-weight: 600;
}

.product-hit-manufacturer {
  width: 20%;
  margin: auto;
}

.product-hit-quantity {
  font-size: 14px;
  text-align: left;
}

.product-hit-selector {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 35%;
  height: 30px;
  margin-right: 5px;
}

.product-hit-selector>.added-button {
  font-family: QUARTZO-Light;
  height: 30px;
  border-radius: 2px;
  letter-spacing: 0.5px;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
}

.product-hit-add-to-blend-image {
  font-family: QUARTZO-Light;
  float: left;
  color: #ffffff;
  width: 100px;
  height: 30px;
  letter-spacing: 0.5px;
  padding: 8px 12px;
  background: #ce3c25;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-hit-add-to-blend-image:focus {
  outline: none;
}

.product-hit-add-to-blend-image:hover {
  cursor: pointer;
}

.product-hit-add-loader {
  border: 0px solid #fff;
  background-color: #eb5d34;
  color: #ffffff;
  width: 100px;
  height: 30px;
  text-align: center;
  height: 30px;
  border-radius: 2px;
}

.product-hit-add-loader>.fa {
  margin: 8px;
}

@media only screen and (max-width: 450px) {
  .product-hit-image {
    width: 60px;
    height: 60px;
    margin-right: 5px;
  }

  .product-hit-manufacturer {
    font-size: 12px;
    width: 35%;
  }

  .product-hit-name {
    font-size: 13px;
  }

  .product-hit-add-loader {
    width: 46.3%;
  }

  .product-hit-selector {
    width: 33%;
  }

  .product-hit-selector>.added-button {
    margin: 0 5px 0 15px;
    width: 78px;
    height: 25px;
    font-size: 10px;
  }

  .product-hit-add-to-blend-image {
    margin: 0 5px 0 15px;
    width: 78px;
    height: 25px;
    font-size: 10px;
  }

  .product-hit-add-loader {
    margin: 0 5px 0 15px;
    width: 78px;
    height: 25px;
    font-size: 10px;
  }
}
