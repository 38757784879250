.footer-content {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 104px;
}

.footer-content-alt {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

.footer-container {
  background-color: #212121;
  color: #fff;
  width: 100%;
  min-height: 220px;
}

.footer-container>.divisor {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  height: 0.05rem;
}

.footer-main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2.5rem;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.footer-gap {
  padding: 1.5rem 4.5rem;
}

.divisor {
  min-width: 0.05rem;
  background-color: rgba(255, 255, 255, 0.5);
}

.footer-divisor {
  height: auto;
  width: 0.2rem;
}

.footer-talk-with-us {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 19.063rem;
}

.footer-talk-with-us .footer-talk-with-us-title-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer-talk-with-us .footer-talk-with-us-title-wrapper>h4 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  color: #ffffff;
}

.footer-talk-with-us .footer-talk-with-us-title-wrapper>img {
  width: 1.25rem;
  height: 1.75rem;
}

.footer-talk-with-us-contact {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.footer-talk-with-us-contact>.footer-talk-with-us-contact-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
}

.footer-talk-with-us-contact>.footer-talk-with-us-contact-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.footer-talk-with-us .footer-talk-with-us-paragraph {
  width: 100%;
}

.footer-talk-with-us .footer-talk-with-us-paragraph>strong {
  margin-left: 0.2rem;
}

.footer-talk-with-us .footer-talk-with-us-paragraph a {
  text-decoration: none;
  color: #fff;
}

.footer-talk-with-us .footer-talk-with-us-paragraph a:hover {
  color: #fff;
}

.footer-socials {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-socials p {
  margin: 0;
  padding: 0;
}

.footer-socials .footer-socials-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.footer-socials .footer-socials-icons .footer-socials-icon-link {
  transition: all ease-in-out 0.5s;
  cursor: pointer;
}

.footer-socials .footer-socials-icons .footer-socials-icon-link:hover {
  opacity: 0.5;
}

.footer-socials .footer-socials-icons .footer-socials-icon-link>img {
  width: 1.25rem;
  height: 1.25rem;
}

.footer-sections {
  display: flex;
  gap: 2.5rem;
}

.footer-sections .footer-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-sections .footer-section .footer-section-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
}

.footer-sections .footer-section .footer-section-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-sections .footer-section .footer-section-links a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  transition: all ease-in-out 0.5s;
  white-space: nowrap;
}

.footer-sections .footer-section .footer-section-links a:hover {
  filter: opacity(0.6);
}

.footer-sections .footer-section-alt {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-sections .footer-section-alt .footer-section-alt-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
}

.footer-sections .footer-section-alt .footer-section-alt-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-sections .footer-section-alt .footer-section-alt-content img {
  width: 10rem;
  height: auto;
}

.footer-section-alt .footer-section-alt-content .footer-section-payments {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footer-section-alt .footer-section-alt-content .footer-section-payments img {
  height: 2.5rem;
  width: min-content;
}

.footer-resume {
  height: 100%;
  padding-top: 1rem;
  width: 100%;
  margin: 0 auto;
}

.footer-resume>.divisor {
  display: none;
}

.footer-resume h4 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.footer-resume p {
  margin-bottom: 0.8rem;
}

.footer-info {
  background-color: #fff;
  width: 100%;
}

.footer-info-wrapper {
  color: #000;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-info .footer-info-address {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-info .footer-info-address .footer-info-address-text-alt {
  font-weight: 600;
}

.footer-info-wrapper .footer-info-credits .footer-info-credits-title,
.footer-info-credits-title-alt {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.footer-info-wrapper .footer-info-credits .footer-info-credits-title-alt {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  justify-content: center;
}

.footer-info-wrapper .footer-info-credits .footer-info-credits-title-alt span {
  transform: translateY(0px);
  animation: float 5s ease-in-out infinite;
}

.footer-info-wrapper .footer-info-credits a {
  background: -webkit-linear-gradient(#21adda, #26aec8, #31b675);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s ease;
}

.footer-info-wrapper .footer-info-credits h5 a:hover {
  filter: opacity(0.8);
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-0.5rem);
  }

  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1280px) {
  .footer-container>.divisor {
    display: none;
  }

  .footer-gap {
    padding: 1rem;
  }

  .footer-main {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
  }

  .divisor {
    height: 0.05rem;
    width: 100%;
  }

  .footer-talk-with-us {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .footer-talk-with-us-contact>.footer-talk-with-us-contact-text {
    width: 100%;
  }

  .footer-talk-with-use-contact-icon {
    display: none;
  }

  .footer-talk-with-us .footer-talk-with-us-paragraph {
    width: 100%;
  }

  .footer-socials {
    flex-direction: column;
  }

  .footer-sections {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    justify-content: space-around;
    align-content: center;
  }

  .footer-info .footer-info-wrapper {
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
    width: 100%;
  }

  .footer-section-alt .footer-section-alt-content .footer-section-payments {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .footer-resume {
    width: 100%;
  }

  .footer-resume>.divisor {
    display: block;
  }

  .footer-resume h4 {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .footer-content {
    padding: 0 0;
  }
}
