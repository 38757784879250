.wishlist-page-items {
  width: 100%;
  gap: 1rem;
  width: 100%;
  margin-bottom: 4rem;
  display: grid;
  object-fit: cover;
  justify-content: space-between;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}

.wishlist-page-title {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}

.wishlist-page-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.wishlist-page-button {
  border: none !important;
  outline: none !important;
  border-radius: .5rem;
  background: #EDF2F499;
  padding: .7rem .5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  cursor: pointer;
  transition: .5s ease-in-out;
  min-width: fit-content;
}

.wishlist-page-button span {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  text-wrap: nowrap;
}

.wishlist-page-button img {
  width: 1rem;
  height: 1rem;
}

.wishlist-page-button:hover {
  opacity: .7;
}

.wishlist-page-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: 1rem;
}

.wishlist-page-pagination .wishlist-page-pagination-button {
  outline: none !important;
  background: none;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  color: rgba(30, 30, 30, 0.5);
}

.wishlist-page-pagination .wishlist-page-pagination-button.selected {
  color: #000;
}


@media screen and (max-width:800px) {
  .search-page-pagination {
    gap: 0;
  }

  .search-page-pagination .search-page-pagination-button {
    font-size: 1rem;
  }

  .search-page-header>.search-page-header-actions {
    display: none;
  }

  .wishlist-page-title {
    font-size: 1rem;
  }
}

@media screen and (max-width:512px) {
  .wishlist-page-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
