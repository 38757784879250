.my-account-address-add-new-button-main {
  color: #EB5B1E;
  font-weight: 400;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  height: 40px;
}

.my-account-address-add-new-button-return-button {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  background-color: #CE3C25;
  border-radius: 8px;
  border: 1px solid #CE3C25;
  gap: .5rem;
  height: 40px;
  padding: 3px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-account-address-add-new-button-create-new-address {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  background: none;
  border-radius: 8px;
  border: 1px solid #CE3C25;
  gap: .5rem;
  height: 40px;
  padding: 3px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-account-address-add-new-text,
.my-account-address-add-return-text {
  text-align: center;
  color: #EB5B1E;
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.14px;
  width: 150px;
}

.my-account-address-add-return-text {
  width: 50px;
  color: #fff;
}

.my-account-address-add-new-arrow-up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #EB5B1E;
}

.my-account-address-add-new-arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #EB5B1E;
  ;
}

@media only screen and (max-width: 500px
  /*SMALLER_MEDIA_SIZE*/
) {
  .my-account-address-add-new-button-content {
    font-size: 14px;
  }
}

@media only screen and (max-width: 370px
  /*SMALLER_MEDIA_SIZE*/
) {
  .my-account-address-add-new-button-content {
    font-size: 12px;
    margin: 0px 10px 0px 20px;
  }
}
