.landing-product-list-container {
  padding: 0 1rem;
}

.landing-product-list-categories {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.landing-product-categories-item {
  display: flex;
  gap: 4px;
  align-items: flex-end;
}

.landing-product-categories-item input {
  width: 1.2rem;
  height: 1.2rem;
  align-self: center;
  cursor: pointer;
  color: #e74e08;
}

.landing-product-categories-item img {
  width: 1.5rem;
  height: 1.5rem;
}

.landing-product-categories-item label {
  margin: 0;
  padding: 0;
}

.landing-product-list-title {
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: min(3rem, 6vw);
  text-align: center;
  color: #eb5b1e;
  margin-bottom: 2rem;
}

.landing-product-list-scroller-arrow {
  font-size: 3rem;
}

.landing-product-list-scroller-arrow-disabled {
  color: #e3e3e3 !important;
}

.landing-product-list-arrow-container-right {
  position: absolute;
  z-index: 1;
  top: 20%;
  right: -35px;
}

.landing-product-list-arrow-container-left {
  position: absolute;
  z-index: 1;
  top: 20%;
  left: -35px;
}

.landing-product-list-container .slick-dots {
  position: absolute;
  bottom: 5rem;
  left: auto;
  right: auto;
  top: auto;
  display: flex !important;
  gap: 0.3rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  list-style: none;
  left: -22px;
}

.landing-product-list-container .slick-dots li {
  flex-grow: 1;
  height: 0.7rem;
  border-radius: 3px;
  background: #aeaeae;
}
.landing-product-list-container .slick-dots .slick-active {
  background: #e74e08;
}
.landing-product-list-container .slick-dots .slick-active button {
  color: #e74e08;
}
.landing-product-list-container .slick-dots li button {
  background: none;
  border: none;
  outline: none;
  font-size: 0;
  color: #d9d9d9;
  width: 100%;
}

.landing-product-list-slider-wrapper {
  position: relative;
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.landing-product-list-slider-meal-card {
  background-color: #fff;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20rem;
  padding: 0 2rem;
  gap: 1rem;
  margin-bottom: 6rem;
}

.landing-product-list-slider-meal-card .progressive-image-loader-final-img {
  object-fit: contain;
  width: 100%;
  height: 12rem;
}

.landing-product-list-slider-meal-card img {
  pointer-events: none;
}
.landing-product-list-slider-meal-card-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
}
.landing-product-list-slider-meal-card-title {
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
  font-size: min(1.8rem, 6vw);
  font-weight: 700;
  width: 100%;
  inline-size: break-all;
  color: #eb5b1e;
}
.landing-product-list-slider-meal-card-text {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: min(1.4rem, 4vw);
}
.landing-product-list-slider-meal-card-actions {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
}

.landing-product-list-slider-meal-card-actions .product-price-main > h6 {
  width: 100%;
  text-align: center;
}
.landing-product-list-slider-meal-card-actions .container {
  margin: 0;
  padding: 0;
  width: min-content;
}
.landing-product-list-slider-meal-card-thumbnails {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.landing-product-list-slider-meal-card-thumbnail {
  width: min(4.375rem, 10vw);
  background: #d9d9d9;
}

@media screen and (min-width: 800px) {
  .landing-product-list-categories {
    gap: 8px;
    margin-bottom: 1rem;
  }
  .landing-product-categories-item {
    gap: 2px;
    align-items: center;
  }
  .landing-product-categories-item input {
    width: 0.8rem;
    height: 0.8rem;
  }
  .landing-product-categories-item img {
    width: 1rem;
    height: 1rem;
  }
  .landing-product-categories-item label {
    font-size: 1rem;
  }
  .landing-product-list-slider-meal-card {
    height: 16rem;
  }
  .landing-product-list-slider-meal-card .progressive-image-loader-final-img {
    height: 6rem;
  }
  .landing-product-list-slider-meal-card-title {
    font-size: 1.2rem;
  }
}
