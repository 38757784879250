.delivery-picker-option-main {
  color: #212121;
  letter-spacing: 1px;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  justify-content: space-between;
  transition: .5s ease-in-out;
  border: 1px solid rgba(0.3, 0.3, 0.3, 0.5);
}

.delivery-picker-option-main:hover {
  opacity: .6;
}

.delivery-picker-option-text {
  font-size: 12.5px;
  padding: 0;
  text-align: center;
  margin: 0;
}

.delivery-picker-option-container-disabled .delivery-picker-option-main div {
  opacity: 0.6;
}

.delivery-picker-option-container-disabled .delivery-picker-option-main div:nth-of-type(3) {
  opacity: 1;
}

.delivery-picker-option-container-disabled .delivery-picker-option-remaining-container {
  opacity: 1;
}

.delivery-picker-option-text-full {
  color: #CE3C25;
  font-weight: 700;
  opacity: 1;
}

.delivery-picker-option-date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delivery-picker-option-date-container>.delivery-picker-option-text {
  font-weight: 700;
  color: #646362;
}

.delivery-picker-option-time-container {
  display: flex;
  justify-content: center;
}

.delivery-picker-option-remaining-container {
  width: 20%;
  display: flex;
}

.delivery-picker-option-delivery-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.delivery-picker-option-selected {
  color: #EB5B1E !important;
  border: 1px solid #EB5B1E;
}

.delivery-picker-option-selected-text {
  color: #EB5B1E !important;
}

.delivery-picker-time-container {
  margin-left: 20px;
  text-align: center;
}

.delivery-picker-free {
  color: #41ab34;
  font-weight: 700;
}

@media screen and (max-width: 280px) {
  .delivery-picker-option-date-container {
    display: none;
  }

  .delivery-picker-option-time-container {
    width: 35%;
  }

  .delivery-picker-option-remaining-container {
    width: 25%;
  }
}
