.wishlist-cover-wrapper {
  width: 100%;
  background: #EDF2F499;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  justify-content: space-between;
  text-decoration: none !important;
  cursor: pointer;
  border-radius: 1rem;
  transition: .5s ease-in-out;
}


.wishlist-cover-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none !important;
}

.wishlist-cover-image {
  width: 100%;
  width: 225px;
  height: 200px;
}

.wishlist-cover {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.wishlist-cover-header {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.wishlist-cover-header-title {
  color: #1e1e1e;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wishlist-cover-header-button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  background: none !important;
  cursor: pointer;
  border: none !important;
  transition: .5s ease-in-out;
}


.wishlist-cover-header-button:hover {
  opacity: .6;
}

.wishlist-cover-header-button img {
  width: 1rem;
  height: 1rem;
}


.wishlist-cover-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.wishlist-cover-info-description {
  color: #1E1E1E;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

.wishlist-cover-info-badge {
  padding: 1rem;
  border-radius: 16px;
  background: #1C8E47;
  padding: 0.25rem .5rem;
  display: flex;
  align-items: center;
}

.wishlist-cover-info-badge-text {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.wishlist-resume {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.wishlist-resume-title {
  color: #1e1e1ecc;
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
}

.wishlist-resume-list {
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
  gap: .5rem;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: min-content;
  word-break: keep-all;
  overflow: hidden;
  max-width: 400px;
}

.wishlist-resume-list-item {
  color: #1e1e1e;
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  margin: 0;
}

.wishlist-resume-list-item-promotion {
  color: #1C8E47;
}

.wishlist-action {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  height: 100%;
  align-items: flex-start;
}

.wishlist-action-header {
  display: flex;
  align-items: center;
  gap: .5rem;
  justify-content: flex-end;
  width: 100%;
}

.wishlist-action-header-button,
.wishlist-action-header-button-alt {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  background: none !important;
  cursor: pointer;
  border: none !important;
  transition: .5s ease-in-out;
}

.wishlist-action-header-button:hover,
.wishlist-action-header-button-alt:hover {
  opacity: .6;
}

.wishlist-action-header-button-alt {
  display: none;
  align-self: flex-end;
}

.wishlist-action-header-button img {
  width: 1rem;
  height: 1rem;
}

.wishlist-action-info {
  display: flex;
  flex-direction: column;
}

.wishlist-action-info-small-text {
  color: #212121;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.wishlist-action-info-promotion-text {
  color: #1C8E47 !important;
}

.wishlist-action-info-promotion-text-price {
  color: rgba(30, 30, 30, 0.50);
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  position: relative;
  line-height: normal;
  text-decoration: line-through;
}


.wishlist-action-info-big-text {
  color: #212121;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-style: normal;
  line-height: normal;
  margin: 0;
  color: rgba(30, 30, 30, 0.80);
  font-weight: 700;
}

@media screen and (max-width:1096px) {

  .wishlist-cover-wrapper {
    flex-direction: column;
    padding: 1rem;
  }

  .wishlist-cover-row {
    flex-direction: column;
  }

  .wishlist-cover-header {
    align-items: center;
    justify-content: center;
  }

  .wishlist-cover-header-title {
    font-size: 1rem;
  }

  .wishlist-cover-info {
    flex-direction: column;
    align-items: center;
  }

  .wishlist-cover-info-description {
    font-size: .875rem;
  }

  .wishlist-cover-info-badge {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wishlist-cover-info-badge-text {
    font-size: .875rem;
  }

  .wishlist-action {
    align-items: center;
  }

  .wishlist-action-header-button {
    display: none;

  }

  .wishlist-action-header-button-alt {
    display: block;
    align-self: flex-end;
  }

  .wishlist-resume-list {
    flex-wrap: wrap;
  }
}
