.option-main {
  text-decoration: none;
}

.option-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.option-name {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}

.option-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.option-container > a:focus {
  text-decoration: none;
}

.option-container > a:hover {
  text-decoration: none;
}
