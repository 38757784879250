.register-box-main {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  border: 1px solid rgba(30, 30, 30, 0.5);
  border-radius: 8px;
  max-width: 449px;
}

.register-box-main>h3 {
  color: #ce3c25;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 10px 0px;
}

.register-box-referral-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: none;
  outline: none !important;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 1rem 0;
  color: #eb5b1e;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  transition: 0.5s ease-in-out;
}

.register-box-referral-button:hover {
  opacity: 0.5;
}

.register-box-referral-button:active {
  filter: brightness(1.1);
}

.register-box-checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
  padding: 0;
  margin-bottom: 1rem;
}

.register-box-checkbox-wrapper>input[type='checkbox'] {
  border: 1px solid rgba(30, 30, 30, 0.5);
  padding: 1rem;
  background-color: #2eb411;
}

.register-box-checkbox-wrapper input[type='checkbox']:checked {
  background-color: #2eb411;
  content: '\2713';
  color: #fff;
  font-size: 12px;
}

.register-box-checkbox-wrapper label {
  font-size: 0.875rem;
  margin: 0;
}

.register-box-checkbox-wrapper label a {
  color: #eb5b1e;
}

.register-box-fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.register-box-terms-of-use .checkbox-div {
  display: flex;
  font-size: 12px;
}

.register-box-terms-of-use .checkbox-div a {
  color: #eb5b1e;
}

.password-strength-text {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  float: right;
  line-height: 15px;
  color: #4a4a4a;
}

.password-strength-strong {
  color: #59b259;
  font-weight: bold;
}

.password-strength-medium {
  color: #e77600;
  font-weight: bold;
}

.password-strength-poor {
  color: #d0021b;
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .register-box-main {
    padding: 1rem;
  }
}
