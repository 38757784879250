.kits-product-card-main-container {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  background-image: url('../images/kits-product-card-background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
}

.kits-product-card-main-container .progressive-image-loader-final-img {
  height: 100%;
}

.kits-product-card-image-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.kits-product-card-discout-square {
  width: 50px;
  height: 60px;
  clip-path: polygon(0 0, 100% 0%, 100% 65%, 50% 100%, 0 65%);
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Open Sans' !important;
  font-style: normal;
  color: #fff !important;
  align-self: flex-end;
  position: absolute;
  margin-right: 10px;
  right: 0;
}

.kits-product-card-discout-square p {
  color: #fff;
  font-feature-settings: 'calt' off;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 150%;
}

.kits-product-card-discout-square>span {
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
}

.kits-product-card-individual-product-container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.kits-product-card-product-details {
  padding: 0 2rem;
  margin: auto 0;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.kits-product-card-product-details .kits-product-card-product-text {
  padding-bottom: 1rem;
}

.kits-product-card-product-details .kits-product-card-product-manufacturer,
.kits-product-card-product-details h4,
.kits-product-card-product-details h1,
.kits-product-card-icons-container p {
  color: #fff;
  font-feature-settings: 'calt' off;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.kits-product-card-product-details h4 {
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 32px;
  font-weight: 700;
}

.kits-product-card-product-details h1 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 1rem;
}

.kits-product-card-product-lower-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.kits-product-card-icons-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}

.kits-product-card-icons-container div {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: nowrap;
}

.kits-product-card-icons-content .cooking-recomedation-box-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

.kits-product-card-icons-content .cooking-recomedation-box-item {
  color: #fff !important;
  opacity: 1 !important;
}

.kits-product-card-icons-container .cooking-recomedation-box-item img {
  filter: invert(1) brightness(200%);

}

.kits-product-card-icons-container p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
}

.kits-product-card-buy-container {
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.kits-product-card-buy-container .product-price-main {
  gap: 16px;
}

.kits-product-card-buy-container .product-price-main h6 {
  align-self: flex-end;
  padding-left: 2px;
}

.kits-product-card-buy-container .product-price-main h6,
.kits-product-card-buy-container .product-price-main p {
  color: #fff;
  font-size: 20px;
}

.kits-product-card-buy-container .product-price-main h5 {
  font-size: 40px;
  font-weight: 700;
  color: #fff !important;
}

.kits-product-card-buy-container .remove-product-button-content-value {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  margin: 0;
  padding: 0;
  color: #ce3c25 !important;
}

.kits-product-card-buy-container .product-price-main {
  height: 80px;
}

.kits-product-card-buy-container .add-button-text,
.kits-product-card-buy-container .add-button-text i,
.kits-product-card-buy-container .remove-product-button-text {
  color: #CE3C25 !important;
}

.kits-product-card-buy-container .add-button,
.kits-product-card-buy-container .notify-me-,
.kits-product-card-buy-container .remove-product-button {
  background-color: #fff !important;
  border: none;
  margin-top: 30px;
  border: none !important;
}

.kits-product-card-buy-container .remove-product-button-main {
  background-color: #fff !important;
  margin-top: 30px;
}

.kits-product-card-buy-container .add-button,
.kits-product-card-buy-container .notify-me-button,
.kits-product-card-buy-container .remove-product-button {
  margin: 0;
  width: 40%;
  padding: 2rem 0;

}

.kits-product-card-buy-container .add-button-text>p,
.kits-product-card-buy-container .remove-product-button p {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 700;
}

.kits-product-card-buy-container .remove-product-button-main .remove-product-button-content span {
  font-size: 16px;
}

.kits-product-card-buy-container .remove-product-button-main i {
  color: #CE3C25;
}

@media only screen and (max-width: 767px) {
  .kits-product-card-main-container {
    flex-direction: column;
    gap: 2rem;
    background-image: url('../images/kits-product-card-mobile-background.png');
  }

  .kits-product-card-product-details {
    padding: 1rem;
  }

  .kits-product-card-product-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .kits-product-card-product-name {
    text-align: center;
  }

  .kits-product-card-product-lower-content {
    align-items: center;
    gap: 2rem;
  }

  .kits-product-card-product-details h4 {
    text-align: center;
  }

  .kits-product-card-buy-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .kits-product-card-buy-container {
    gap: 2rem;
  }

  .kits-product-card-buy-container .product-price-main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.3rem;
  }

  .kits-product-card-buy-container .product-price-main h6 {
    align-self: center;
  }

  .kits-product-card-buy-container .add-button,
  .kits-product-card-buy-container .notify-me-button,
  .kits-product-card-buy-container .remove-product-button {
    margin: 0;
    width: 100%;
    padding: 2rem;
  }

  .kits-product-card-buy-container .add-button-text>p,
  .kits-product-card-buy-container .remove-product-button-text>p {
    font-size: 24px;
    font-weight: 700;
  }

  .kits-product-card-buy-container .add-button-text>p,
  .kits-product-card-buy-container .remove-product-button-text>p {
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kits-product-card-main-container .progressive-image-loader-final-img {
    object-fit: cover;
  }

  .kits-product-card-buy-container .add-button-text>p,
  .kits-product-card-buy-container .remove-product-button-text>p {
    font-size: 20px;
  }

  .kits-product-card-buy-container .add-button,
  .kits-product-card-buy-container .remove-product-button {
    text-align: center;
  }
}
