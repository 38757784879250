.delivery-summary-main {
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0px !important;
}

.resume-info-wrapper .delivery-summary-main {
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0px !important;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.delivery-summary-details {
  display: flex;
}

.resume-info-wrapper .delivery-summary-details,
.resume-info-wrapper .delivery-summary-cost {
  display: flex;
  border: 1px solid #1C8E47;
  padding: 2px 4px;
  border-radius: 8px;
}


@media only screen and (max-width: 500px
  /*SMALL_MEDIA_SIZE*/
) {
  .delivery-summary-main>p {
    font-size: 12px;
  }
}
