.product-price-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Open Sans';
  font-style: normal;
  height: 51px;
  gap: -3px;
}

.product-price-main>p {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #212121;
  margin: 0;
  padding: 0;
}

.product-price-promotion>h6 {
  display: inline !important;
  margin: 0;
  padding: 0;
}

.product-price-promotion>h5 {
  font-weight: 700 !important;
  font-size: 20px;
  line-height: 20px;
  color: #ce3c25 !important;
  margin: 0;
  padding: 0;
}

.product-price-main>h6 {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-decoration: line-through;
  color: rgba(30, 30, 30, 0.5);
  margin: 0;
  padding: 0;
}

.product-price-main>h5 {
  color: #ce3c25 !important;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {

  .product-price-main>p {
    font-size: 8px;
    line-height: 11px;
  }

  .product-price-promotion>h5 {
    font-size: 14px;
    line-height: 19px;
  }

  .product-price-promotion>h6 {
    font-size: 8px;
    line-height: 11px;
  }

  .product-price-main>h5 {
    font-size: 14px;
    line-height: 19px;
  }
}
