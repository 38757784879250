.order-total-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.order-total-main-container {
  display: flex;
  color: #212529;
  align-items: center;
  justify-content: space-between;
}

.order-total-line {
  width: 100%;
  border-width: 2px;
}

.order-total-main-container>div {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.order-total-main-container>div>p {
  margin: 0px;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  color: rgba(30, 30, 30, 0.8);
}

.order-total-main-container>div>h3 {
  color: #1e1e1e;
  font-size: 1.125rem;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.order-total-final-price {
  font-weight: 800 !important;
  color: #CE3C25 !important;
}

.order-total-alert-weigh {
  text-align: left;
  color: rgba(30, 30, 30, 0.40);
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  margin-top: .5rem;
}

.order-total-green-text {
  color: #2eb411;
}

@media only screen and (max-width: 500px) {
  .order-total-not-weighed {
    text-align: right;
    font-size: 14px;
  }

  .order-total-main-container>div>p {
    font-size: 14px;
  }

  .order-total-main-container>div>h3 {
    font-size: 14px;
  }
}
