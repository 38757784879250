.kits-divisor-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../images/divisor-image.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: auto;
  border-radius: 1rem;
}

.kits-divisor-text {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  text-align: center;
  margin-bottom: 0;
  color: #ffffff;
  padding: 1rem 0 1rem 0;
}

@media only screen and (max-width: 767px) {
  .kits-divisor-container {
    background: url('../images/divisor-mobile-image.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
