.big-customer-main {
  font-family: 'Open sans', sans-serif;
}

.big-customer-gap {
  padding: 4rem 7.625rem;
}

.big-customer-banner-container {
  width: 100%;
  position: relative;
}

.big-customer-banner {
  height: 110vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-position: 0 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../images/bigCustomerBanner.jpg');
  padding: 2rem;
}

.big-customer-banner-image {
  width: 50%;
}

.big-customer-banner-text {
  font-size: min(3.5rem, 7vw);
  font-weight: 700;
  width: 50%;
  line-height: 3.9rem;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.big-customer-counter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  position: relative;
}

.big-customer-counter-container > .landing-increasing-user-counter {
  position: absolute;
  width: 50%;
  border-radius: 2rem;
  bottom: auto;
  top: -50px;
}

.big-customer-enjoy-container {
  background-color: #ff6c29;
  width: 100%;
  min-height: 80vh;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0 !important;
}

.big-customer-enjoy-description {
  font-weight: 600;
  font-size: min(2rem, 3vw);
  text-align: center;
  color: #fff;
  margin: 0;
  width: 60%;
}
.big-customer-enjoy-subtitle {
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  font-size: min(8rem, 16vw);
  text-align: center;
  color: #fdd532;
}

.big-customer-diff-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.big-customer-diff-title {
  font-size: min(4.5rem, 8vw);
  color: #ff5c0d;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'PT Sans', sans-serif;
}

.big-customer-diff-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  width: 40%;
  font-size: min(2rem, 5vw);
  text-align: center;
  color: #454546;
}

.big-customer-diff-container i {
  font-size: 5rem;
  color: #ff5c0d;
  margin-bottom: 1rem;
}

.big-customer-diff-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
}

.big-customer-diff-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.big-customer-diff-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: min(1.5rem, 5vw);
  text-align: center;
  color: #454546;
  margin-top: 2rem;
}

.big-customer-diff-item-img-versus {
  width: 6rem;
}

.big-customer-diff-item-img {
  width: 14rem;
}

.big-customer-comment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  min-height: 100vh;
}

.big-customer-comment-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}

.big-customer-comment-title {
  color: #eb5b1e;
  font-size: min(1.5rem, 10vw);
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}
.big-customer-comment-img {
  width: 12rem;
  height: 8rem;
}

.big-customer-comment-text {
  font-size: min(1.4rem, 7vw);
  text-align: center;
  width: 50%;
}

.big-customer-comment-author {
  font-size: 1rem;
}

.big-customer-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-direction: column;
  background-color: #fff3bb;
}

.big-customer-icon-item {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 0.5rem;
}

.big-customer-icon-item-img {
  width: 7rem;
  height: 100%;
}

.big-customer-icon-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: min(1.2rem, 6vw);
  text-align: center;
  color: #eb5b1e;
}

@media screen and (max-width: 1000px) {
  .big-customer-banner {
    height: 100vh;
  }

  .big-customer-banner-text {
    width: 70%;
    line-height: 12vw;
  }

  .big-customer-diff-item-img-versus {
    width: 4rem !important;
  }
  .big-customer-diff-item-img {
    width: 8rem !important;
    height: auto !important;
  }

  .big-customer-counter-container > .landing-increasing-user-counter {
    width: 90%;
  }

  .big-customer-banner-image {
    width: 80%;
  }

  .big-customer-enjoy-title {
    width: 100%;
  }

  .big-customer-diff-text {
    width: 100%;
  }

  .big-customer-diff-row {
    flex-direction: row;
    align-items: flex-start;
  }

  .big-customer-diff-item-img {
    width: 10rem;
    height: 9rem;
  }

  .big-customer-diff-item-img-versus {
    width: 6rem;
  }

  .big-customer-gap {
    padding: 1rem;
  }

  .big-customer-comment-text {
    width: 100%;
  }
}
