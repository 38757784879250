.christmas-product-list-main-container .slick-dots {
  display: none !important;
}

.christmas-product-list-content .scroller-arrow {
  color: #fff;
}

.christmas-product-list-main-container .slick-list {
  padding: 1rem;
}

.christmas-product-list-arrow-icon {
  display: none !important;
}

.christmas-product-list-main-container {
  position: relative;
}

.christmas-product-list-main-container>img:first-of-type {
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: 1;
}

.christmas-product-list-main-container>img:nth-of-type(2) {
  position: absolute;
  top: -13px;
  right: -20px;
  z-index: 1;
}

.christmas-product-list-main-container>img:nth-of-type(3) {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 1;
}

.christmas-product-list-main-container>img:nth-of-type(4) {
  position: absolute;
  bottom: -38px;
  left: -15px;
  z-index: 1;
}

.christmas-product-list-content {
  background-image: url('../images/christmas-page-slider-image.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 4rem 4rem;
  border-radius: 16px;
  width: 100%;
}

.christmas-product-list-image-container {
  border-radius: 8px;
  width: 280px;
  min-width: 280px;
  height: 280px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.christmas-product-discout-square {
  width: 50px;
  height: 60px;
  clip-path: polygon(0 0, 100% 0%, 100% 65%, 50% 100%, 0 65%);
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Open Sans' !important;
  font-style: normal;
  color: #fff !important;
  align-self: flex-end;
  justify-self: flex-end;
  margin-right: 10px;
}

.christmas-product-discout-square p {
  color: #fff;
  font-feature-settings: 'calt' off;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 150%;
}

.christmas-product-discout-square>span {
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
}

.christmas-product-list-individual-product-container {
  padding: 0 4rem;
  display: flex;
  flex-direction: row;
}

.christmas-product-list-product-details {
  padding: 1rem 2rem;
}

.christmas-product-list-product-details .christmas-product-list-product-text {
  padding-bottom: 1rem;
}

.christmas-product-list-product-details .christmas-product-list-product-manufacturer,
.christmas-product-list-product-details h4,
.christmas-product-list-product-details h1,
.christmas-product-list-icons-container p {
  color: #fff;
  font-feature-settings: 'calt' off;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.christmas-product-list-product-details h4 {
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 32px;
  font-weight: 700;
}

.christmas-product-list-product-details h1 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 1rem;
}

.christmas-product-list-icons-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 1rem;
}

.christmas-product-list-icons-container div {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: nowrap;
}

.christmas-product-list-icons-content .cooking-recomedation-box-item {
  color: #fff !important;
  opacity: 1 !important;
}

.christmas-product-list-icons-container .cooking-recomedation-box-item img {
  filter: invert(1) brightness(200%);

}

.christmas-product-list-icons-container p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
}

.christmas-product-list-buy-container {
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 1rem;
}

.christmas-product-list-buy-container .product-price-main {
  gap: 16px;
}

.christmas-product-list-buy-container .product-price-main h6 {
  align-self: flex-end;
  padding-left: 2px;
}

.christmas-product-list-buy-container .product-price-main h6,
.christmas-product-list-buy-container .product-price-main p {
  color: #fff;
  font-size: 20px;
}

.christmas-product-list-buy-container .product-price-main h5 {
  font-size: 40px;
  font-weight: 700;
  color: #fff !important;
}

.christmas-product-list-buy-container .add-after-exists-button-content-value {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  margin: 0;
  padding: 0;
  color: #ce3c25 !important;
}

.christmas-product-list-buy-container .product-price-main {
  height: 80px;
}

.christmas-product-list-product-lower-content {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
}

.christmas-product-list-icons-container {
  flex-direction: column;
}

.christmas-product-list-icons-content {
  display: flex;
  flex-wrap: wrap;
}

.christmas-product-list-icons-content div {
  margin-right: 1rem;
}

.christmas-product-list-main-container .landing-product-list-arrow-container-right,
.christmas-product-list-main-container .landing-product-list-arrow-container-left {
  top: 30% !important;
}


.christmas-product-list-main-container .add-to-cart-before-button-text,
.christmas-product-list-main-container .add-to-cart-before-button-text i {
  color: #CE3C25 !important;
}

.christmas-product-list-buy-container .add-to-cart-before-button,
.christmas-product-list-buy-container .notify-me-button {
  background-color: #fff !important;
  border: none;
  margin-top: 30px;
  border: none !important;
}

.christmas-product-list-buy-container .add-after-exists-button-main {
  background-color: #fff !important;
  margin-top: 30px;
}

.christmas-product-list-buy-container .add-after-exists-button-main button {
  color: #CE3C25 !important;
}

.christmas-product-list-buy-container .notify-me-button-text {
  color: #CE3C25 !important;
}

@media only screen and (max-width: 767px) {
  .christmas-product-list-main-container .slick-slide {
    padding: 1rem;
    padding-right: 2.8rem;
  }

  .christmas-product-list-icons-content div {
    margin-right: 0;
  }

  .christmas-product-list-main-container>img:nth-of-type(2),
  .christmas-product-list-main-container>img:nth-of-type(4) {
    display: none;
  }

  .christmas-product-list-content {
    padding: 1.5rem 1rem;
    padding-bottom: 0;
    height: 900px;
    background-image: url('../images/christmas-page-mobile-slider-image.png');
  }

  .christmas-product-list-individual-product-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }

  .christmas-product-list-image-container {
    width: 260px !important;
    height: 280px;
  }

  .christmas-product-list-product-details {
    padding: 1rem 0;
    width: 280px;
  }

  .christmas-product-list-product-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .christmas-product-list-product-details h1 {
    font-size: 24px;
    text-align: center;
  }

  .christmas-product-list-icons-container {
    flex-direction: column;
  }

  .christmas-product-list-buy-container {
    flex-direction: column;
    justify-content: center;
  }

  .christmas-product-list-icons-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: row;
  }

  .christmas-product-list-icons-container>div {
    width: 34%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .christmas-product-list-buy-container .product-price-main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .christmas-product-list-buy-container .product-price-main {
    gap: 14px;
    margin-bottom: 2rem;
  }

  .christmas-product-list-buy-container .product-price-main p,
  .christmas-product-list-buy-container .product-price-main h6 {
    font-size: 16px;
    font-weight: 400;
  }

  .christmas-product-list-buy-container .product-price-main h6 {
    align-self: center;
  }

  .christmas-product-list-buy-container .product-price-main h5 {
    font-size: 28px;
    font-weight: 700;
  }

  .christmas-product-list-buy-container .add-to-cart-before-button {
    width: 240px;
    height: 40px;
    gap: 8px;
    background-color: #fff !important;
    color: #ce3c25 !important;
    margin-top: 0;
  }

  .christmas-product-list-buy-container .add-to-cart-before-button-text p {
    color: #ce3c25 !important;
    font-feature-settings: 'calt' off;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.14px;
    margin-bottom: 2px;
  }

  .christmas-product-list-content .landing-product-list-arrow-container-right,
  .christmas-product-list-content .landing-product-list-arrow-container-left {
    display: none !important;
  }

  .christmas-product-list-arrow-icon {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    display: block !important;
  }

  .christmas-product-list-product-lower-content {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .christmas-product-list-icons-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .christmas-product-list-icons-content {
    width: 100% !important;
  }

  .christmas-product-list-icons-content .cooking-recomedation-box-container {
    display: flex;
    flex-direction: column;
  }

  .christmas-product-list-icons-content div {
    width: 30%;
  }

  .cooking-recomedation-box-wrapper {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .christmas-product-list-individual-product-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }

  .christmas-product-list-buy-container {
    flex-direction: column;
  }
}
