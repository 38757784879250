.page-title-with-go-back-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  margin-bottom: 2.5rem;
}

.page-title-with-go-back-wrapper > .page-title-with-go-back-arrow {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  justify-content: center;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.page-title-with-go-back-wrapper > .page-title-with-go-back-arrow img {
  width: 1.6rem;
  height: 1.6rem;
}
.page-title-with-go-back-wrapper > .page-title-with-go-back-arrow:hover {
  filter: opacity(0.7);
}
.page-title-with-go-back-wrapper > .page-title-with-go-back-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 120%;
  color: #ce3c25;
  margin: 0;
}
@media screen and (max-width: 1000px) {
  .page-title-with-go-back-wrapper > .page-title-with-go-back-title {
    font-size: 1.2rem;
  }
  .page-title-with-go-back-wrapper > .page-title-with-go-back-arrow img {
    width: 1.5rem;
    height: 1.5rem;
  }
}
