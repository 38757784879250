.my-account-address-item-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #474747;
  width: 100%;
  margin-bottom: 10px;
  padding: .5rem;
  cursor: pointer;
  transition: .5s ease-in-out;
}

.my-account-address-item-main:hover {
  opacity: .6s;
}

.my-account-address-item-main.selected {
  border: 2px solid #EB5B1E;
}

.my-account-address-item-main-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.my-account-address-item-main-image {
  width: 6rem;
  height: auto;
  border-radius: 5px;
}

.my-account-address-item-main-content-info {
  display: flex;
  flex-direction: column;
}

.my-account-address-item-main-content-info p {
  margin: 0;
  color: #1E1E1E;
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
}

.my-account-address-item-main-content-info p:nth-of-type(2) {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.my-account-address-item-main-content-info.selected p {
  margin: 0;
  font-weight: 500;
  font-size: .9rem;
  color: #1E1E1Ecc;
}

.my-account-address-item-main-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.my-account-address-item-main-action {
  border: none !important;
  outline: none !important;
  border-radius: 8px;
  border: 1px solid #CE3C25 !important;
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem .8rem .5rem .8rem;
  transition: .5s ease-in-out;
  gap: .5rem;
}

.my-account-address-item-main-action:nth-of-type(2) {
  border: 1px solid #EB5B1E !important;

}

.my-account-address-item-main-action:nth-of-type(2) h6 {
  color: #EB5B1E
}

.my-account-address-item-main-action h6 {
  color: #CE3C25;
  font-feature-settings: 'calt' off;

  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.14px;
  margin: 0;
}

.my-account-address-item-main-action:hover {
  opacity: .6;
}

.my-account-address-item-main-action i {
  color: #EB5B1E;
  font-size: 1.5rem;
  padding: 0 .5rem;
}

.my-account-address-item-main-action-img {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .my-account-address-item-main-actions {
    gap: 1rem;
  }
}
