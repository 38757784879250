.blog-recent-posts-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-recent-posts-main > h2 {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 160%;
  align-items: center;
  color: #ce3c25;
}

.blog-recent-posts-grid {
  display: grid;
  gap: 10px;
  width: 100%;
  grid-template-columns: 280px 280px 280px 280px;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.blog-recent-posts-anchor-card {
  text-decoration: none;
  width: 275px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-recent-posts-anchor-card > img {
  width: 275px;
  height: 123px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.16));
  border-radius: 16px;
  object-fit: cover;
}
.blog-recent-posts-anchor-card > h3 {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  align-items: center;
  color: #ce3c25;
  width: 95%;
}

.blog-recent-posts-anchor-card > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  padding: 0 16px;
  text-align: center;
  background: linear-gradient(180deg, #000000 41.15%, rgba(0, 0, 0, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.blog-recent-posts-load-more {
  background: transparent;
  border-radius: 6px;
  margin: 16px 0 40px 0;
  color: #ce3c25;
  cursor: pointer;
}
.blog-recent-posts-load-more:hover {
  color: #ce3c25;
  text-decoration: none;
}

@media only screen and (max-width: 1450px) {
  .blog-recent-posts-anchor-card {
    width: 260px;
  }

  .blog-recent-posts-anchor-card > img {
    width: 240px;
    height: 113px;
  }

  .blog-recent-posts-anchor-card > h3 {
    font-size: 16px;
  }

  .blog-recent-posts-anchor-card > p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1360px) {
  .blog-recent-posts-grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .blog-recent-posts-grid {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }
}
