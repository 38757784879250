.image-gallery-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 560px;
  width: 672px;
  position: relative;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid #1e1e1e44;
  margin-bottom: 18px;
}

.image-gallery-main-share {
  background: none;
  transition: all 0.5s ease-in-out;
  outline: none !important;
  position: absolute;
  top: 15px;
  right: 10px;
  border: 1px solid #1e1e1e78;
  padding: 6px 6px;
  border-radius: 50px;
}

.image-gallery-main-favorite {
  position: absolute;
  top: 15px;
  right: 60px;
}

.image-gallery-main-share-tooltip {
  position: absolute;
  top: -15px;
  right: 10px;
  background-color: #ffff;
  color: #1e1e1e44;
  border: 1px solid #1e1e1e44;
  border-radius: 10px;
  font-size: 10px;
  padding: 5px 10px;
}

.image-gallery-main-share:hover {
  opacity: 0.5;
}

/*TODO Make sure this actually belongs here... this
is for the large image gallery in the logged in version
of the home page */

.loading-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.image-gallery-thumbnails {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.image-gallery-thumb {
  margin: 0px;
  border: solid 1px #cccccc;
  margin: 0px 5px 5px 5px;
  cursor: pointer;
  cursor: hand;
  height: 40px;
  width: auto;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
}

.image-gallery-thumb>img {
  max-height: 35px;
  width: auto;
  object-fit: scale-down;
}

.image-gallery-thumb:hover {
  border: solid 1px #ce3c25;
}

.image-gallery-thumb-active {
  border: solid 1px #ce3c25;
}

.image-gallery-discount {
  position: absolute;
  width: 62px;
  height: 70px;
  left: 25px;
  top: 0;
  clip-path: polygon(0 0, 100% 0%, 100% 65%, 50% 100%, 0 65%);
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Open Sans' !important;
  font-style: normal;
  color: #fff !important;
}

.image-gallery-discount>p {
  margin: 0;
  padding: 0;
  font-weight: 500 !important;
  font-size: 22px;
  line-height: 150%;
}

.image-gallery-discount>span {
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
}

@media only screen and (max-width: 750px) {
  .image-gallery-main {
    height: 328px;
    width: 100%;
  }
}
