.homemade-gap,
.homemade-gap-alt {
  padding: 4rem 7.625rem;
}
.homemade-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9b3303;
  min-height: 80vh;
  background-position: center;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../images/bannerHomemade.jpg');
}
.homemade-banner-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  gap: 2rem;
}
.homemade-banner-content-img {
  width: 20rem;
  height: 20rem;
}
.homemade-banner-content-text {
  font-size: min(5rem, 8vw);
  font-weight: 700;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.homemade-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff6c29;
  height: 100%;
  width: 100%;
}
.homemade-section-text-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.homemade-section-text,
.homemade-section-text-alt {
  font-size: min(3rem, 8vw);
  font-weight: 700;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}
.homemade-section-text-alt {
  font-size: min(4.5rem, 9vw);
}
.homemade-section-text-yellow {
  color: #ffed88;
}
.homemade-section-diff {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}
.homemade-section-diff-title {
  font-size: min(4.5rem, 8vw);
  color: #ff5c0d;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'PT Sans', sans-serif;
}
.homemade-section-diff i {
  font-size: 5rem;
  color: #ff5c0d;
  margin-bottom: 1rem;
}
.homemade-section-diff-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  width: 40%;
  font-size: min(2rem, 5vw);
  text-align: center;
  color: #454546;
}
.homemade-section-diff-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
}
.homemade-section-diff-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.homemade-section-diff-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: min(1.5rem, 5vw);
  text-align: center;
  color: #454546;
}
.homemade-section-diff-item-img-versus {
  width: 10rem;
  height: 10rem;
}
.homemade-section-diff-item-img {
  width: 25rem;
  height: 22rem;
}
.homemade-section-column {
  display: flex;
  align-items: center;
  justify-self: center;
  gap: 1rem;
  min-height: 80vh;
  width: 100%;
  flex-direction: column;
  background-color: #ffed88;
}
.homemade-section-column-title {
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: min(3rem, 8vw);
  text-align: center;
  color: #eb5b1e;
  margin-bottom: 2rem;
}
.homemade-section-column-item {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 0.5rem;
}
.homemade-section-column-item-img {
  width: 7rem;
  height: 100%;
}
.homemade-section-column-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: min(1.5rem, 5vw);
  text-align: center;
  color: #eb5b1e;
}
@media screen and (max-width: 1000px) {
  .homemade-section-text,
  .homemade-section-text-alt {
    width: 100%;
  }
  .homemade-gap,
  .homemade-gap-alt {
    padding: 1rem;
  }
  .homemade-banner-text {
    width: 100%;
  }
  .homemade-section-diff-text {
    width: 100%;
  }
  .homemade-section-diff-row {
    flex-direction: column;
  }
  .homemade-section-diff-item-img {
    width: 10rem;
    height: 9rem;
  }
  .homemade-section-diff-item-img-versus {
    width: 6rem;
    height: 6rem;
  }
  .homemade-banner-content-text {
    width: 100%;
  }
  .homemade-banner-content-img {
    width: 15rem;
    height: 15rem;
  }
  .homemade-banner-content {
    align-items: center;
  }
}
