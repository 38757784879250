.option-checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  p {
    margin: 0;
    padding: 0;
  }
  .item-portioning-checkbox-item{
    margin-bottom: 0 !important;
    .checkbox-div{
      padding-bottom: 0 !important;
    }
  }
}

.option-checkbox-tooltip-container{
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.rc-tooltip{
  opacity: 1 !important;
}

.rc-tooltip-arrow{
  border: 0 !important;
}
.rc-tooltip-inner{
  background-color: white;
  opacity: 1 !important;
  border: 1px solid #b7b7b7;
  border-radius: 2px;
  width: 244px;
  padding: 5px;
}

.tooltip-container{
  background-color: white;
  color: #212121;
  padding: 0;
  margin: 0;
  p{
    font-size: 11px;
    font-weight: 400;
    margin: 0px;
    text-decoration: none;
    font-weight: normal;
  }
}

.option-checkbox-tooltip {
  width: auto;
  height: 17px;
  align-self: center;
}
