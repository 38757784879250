.empty-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  margin-bottom: 2rem;
}

.empty-list-wrapper .empty-list-image {
  width: 20rem;
  height: 17.5rem;
}


.empty-list-wrapper .empty-list-title {
  color: #000;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  max-width: 600px;
}


.empty-list-wrapper .empty-list-button {
  outline: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  gap: .5rem;
  border-radius: 8px;
  text-decoration: none;
  background: linear-gradient(90deg, #CE3C25 0%, #EB5B1E 57.81%);
  padding: .5rem;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  cursor: pointer;
  transition: .5s ease-in-out;
}

.empty-list-wrapper .empty-list-button:hover {
  filter: brightness(1.1);
}

.empty-list-wrapper .empty-list-button .empty-list-button-image {
  width: 1.5rem;
  height: auto;
}



@media screen and (max-width: 800px) {

  .empty-list-wrapper .empty-list-image {
    width: 16rem;
    height: 13rem;
  }

  .empty-list-wrapper .empty-list-title {
    font-size: 1.5rem;
  }

  .empty-list-wrapper .empty-list-button {
    font-size: 1rem;
  }
}
