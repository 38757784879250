.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown a {
  color: black;
  text-decoration: none !important;
}
.dropbtn {
  background-color: #fff;
  color: black;
  margin-right: 1rem;
  border: none;
  cursor: pointer;
  display: flex;
  white-space: nowrap;
  font-family: inherit;
  align-items: center;
  gap: 0.25rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  outline: none !important;
  transition: 0.5s ease;
}
.dropbtn:hover {
  filter: opacity(0.6);
}
.dropbtn:active {
  filter: brightness(1.1);
}
.dropbtn .dropbtn-text {
  text-align: left;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
}
.dropbtn .dropbtn-text strong {
  font-weight: bold;
}
.dropbtn .dropbtn-text p {
  margin-bottom: 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
}
.dropbtn .dropbtn-img {
  height: 2rem;
  width: 2.75rem;
}

.dropdown-arrow-container {
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.dropdown-line {
  color: #c7c7c7;
  margin: 0px 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 35px;
  right: -50px;
  background-color: #fff;
  border: 1px solid #c7c7c7;
  font-size: 14px;
  width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #fff;
}

@media only screen and (max-width: 750px) {
  .dropdown {
    display: none;
  }
}
