.cashback-card-container {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 16px;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.cashback-card-text-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.cashback-card-text-section > h1 {
  color: #ce3c25;
  font-size: 48px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  margin: 0;
}

.cashback-card-text-section-offline > h1 > span {
  display: none;
}

.cashback-card-text-section > p {
  color: #1e1e1e;
  font-size: 22px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: -0.22px;
  margin: 0;
}

.cashback-card-balance-section {
  display: flex;
  gap: 128px;
}

.cashback-card-balance-section > div {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.cashback-card-balance-section-description {
  color: #1e1e1e;
  font-size: 22px;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}

.cashback-card-balance-section h1 {
  font-size: 48px;
  font-family: 'Open Sans';
  font-weight: 700;
  line-height: 120%;
}

.cashback-card-balance-section-current > h1 {
  color: #1c8e47;
}

.cashback-card-balance-section-accumulated > h1 {
  color: #ce3c25;
}

.cashback-card-table-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.cashback-card-table-section-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.cashback-card-table-section-details-description {
  color: #1e1e1e;
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  margin: 0;
}

.cashback-card-table-section-details-action {
  color: #ce3c25;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-decoration: none;
  margin: 0;
  cursor: pointer;
}

.cashback-card-table-wrapper {
  width: 100%;
  max-height: 250px;
  overflow: hidden scroll;
  padding: 0 20px;
}

.cashback-card-table-wrapper::-webkit-scrollbar {
  width: 6px;
}

.cashback-card-table-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.cashback-card-table-wrapper::-webkit-scrollbar-thumb {
  background-color: #0000001f;
  border-radius: 6px;
}

.cashback-card-table-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.cashback-card-table-wrapper::-webkit-scrollbar-corner {
  background-color: #f5f5f5;
  opacity: 0.5;
}

.cashback-card-table-section-table {
  width: 100%;
}

.cashback-card-table-section-table-head {
  position: sticky;
  top: -2px;
  background-color: #fff;
  width: 100%;
}

.cashback-card-table-section-table-head th {
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  padding-bottom: 16px;
  width: 20%;
}

.cashback-card-table-section-table-body td {
  padding: 8px 2px;
  width: 20%;
}

.cashback-card-table-section-table-body td[status='pending'] {
  color: #eb5b1e;
}

.cashback-card-table-section-table-body td[status='completed'] {
  color: #1c8e47;
}

.cashback-card-table-section-table-body td:last-of-type {
  text-align: right;
}

.cashback-card-table-section-table-body td:last-of-type :only-child {
  color: #1e1e1e;
  text-decoration: none;
}

.cashback-card-table-section-table-body tr:hover {
  background: rgba(235, 91, 30, 0.1);
}

.cashback-card-table-section-table-body tr > td:last-of-type :only-child {
  color: #eb5b1e;
}

.cashback-card-table-section-table-body {
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
}

.cashback-card-info-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 8px 0;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 8px;
}

.cashback-card-info-section-offline {
  grid-template-columns: repeat(2, 1fr);
}

.cashback-card-info-section-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.cashback-card-info-section-item > img {
  width: 64px;
}

.cashback-card-read-rules {
  margin: 0;
}

.cashback-card-read-rules :only-child {
  color: rgba(30, 30, 30, 0.5);
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.14px;
  text-decoration-line: underline;
  transition: color 0.3s linear;
}

.cashback-card-read-rules :only-child:hover {
  color: #eb5b1e;
}

@media screen and (max-width: 1024px) {
  .cashback-card-info-section {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}

@media screen and (max-width: 768px) {
  .cashback-card-container {
    padding: 16px;
    gap: 16px;
  }

  .cashback-card-text-section > h1 {
    font-size: 24px;
  }

  .cashback-card-text-section > p {
    font-size: 14px;
  }

  .cashback-card-balance-section {
    gap: 8px;
  }
  .cashback-card-balance-section-description {
    font-size: 12px;
  }
  .cashback-card-balance-section-description > span {
    display: none;
  }
  .cashback-card-balance-section h1 {
    font-size: 24px;
  }
  .cashback-card-table-section {
    gap: 16px;
  }
  .cashback-card-table-section-details-description {
    font-size: 16px;
  }

  .cashback-card-table-section-details-action {
    font-size: 12px;
  }

  .cashback-card-table-section-table-head {
    display: none;
  }
  .cashback-card-table-section-table-body tr td {
    font-size: 12px;
  }

  .cashback-card-table-section-table-body tr td:nth-of-type(4) {
    display: none;
  }

  .cashback-card-table-section-table-body td:last-of-type span {
    display: none;
  }

  .cashback-card-info-section-item {
    gap: 0;
  }

  .cashback-card-info-section-item > img {
    width: 32px;
  }

  .cashback-card-info-section-item > div {
    font-size: 10px;
  }
  .cashback-card-info-section-item > div p {
    margin: 0;
  }
}
