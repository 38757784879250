.big-club-notice-text {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
  color: rgba(30, 30, 30, 0.50);
  width: 100%;
}

.big-club-notice-text-orange,
.big-club-notice-text-orange-bold {
  background: linear-gradient(90deg, #CE3C25 0%, #EB5B1E 57.81%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.big-club-notice-text-orange-bold {
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .big-club-notice-text {
    display: block;
    width: 95%;
  }
}
