.heart-button {
  font-size: 1.5rem;
  background: none;
  border: none !important;
  cursor: pointer;
  outline: none !important;
  transition: transform 0.2s ease-in-out;
}

.heart-button.is-favorite {
  transform: scale(1.2);
  color: #ce3c25;
}


.heart-button-loading {
  color: #ce3c25;
  font-size: .5rem;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.heart-button.is-favorite {
  animation: heartbeat 0.6s ease-in-out;
}
