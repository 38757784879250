.landing-increasing-user-counter {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  gap: 0.1rem;
  width: 100%;
  line-height: 1.5rem;
  background-color: #fff;
  padding: 1.5rem 0;
  text-transform: uppercase;
}

.landing-increasing-user-counter-contUp {
  text-transform: uppercase;
  font-size: min(1.3rem, 10vw);
  text-align: center;
  font-weight: 700;
  border: 3px solid;
  padding: 0px 4px;
  border-radius: 20px;
  letter-spacing: 2px;
}

.landing-increasing-user-counter-people-and-counting {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0 !important;
}
.landing-increasing-user-counter-people-and-counting > span {
  width: 100%;
  text-align: left;
}

.landing-increasing-user-counter-people-and-counting-text {
  font-size: min(1rem, 10vw);
  margin: 0;
  padding: 0;
  text-align: left;
}

.landing-increasing-user-counter-people-and-counting-text-sub {
  align-self: flex-end;
  text-transform: lowercase;
  font-weight: 500;
}

.landing-increasing-user-counter-people-and-counting-text-alt {
  color: #eb5b1e;
  text-align: left;
}

@media screen and (min-width: 800px) {
  .landing-increasing-user-counter {
    width: 24rem;
    position: absolute;
    right: 20%;
    border-end-end-radius: 25px;
    border-end-start-radius: 25px;
  }
}
