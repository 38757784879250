.my-account-overview-main-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.my-account-overview-top-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

}

.my-account-overview-user-information-container {
  background: rgba(237, 242, 244, 0.60);
  width: 630px;
  height: 100px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: .5rem;
}

.my-account-overview-main-container h4,
.my-account-overview-user-information-container p {
  margin: 0;
  color: #1E1E1E;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}



.my-account-overview-user-information-container p {
  font-feature-settings: 'calt' off;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.14px;
}

.my-account-overview-top-content .cart-big-club-preview-container {
  height: 100px !important;
  border-radius: .5rem !important;
}

.my-account-overview-top-content .cart-big-club-preview {
  max-height: 100px !important;
  height: 100px !important;
  background: rgba(237, 242, 244, 0.60) !important;
  border-radius: .5rem !important;
}

.my-account-overview-user-information-content {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.my-account-overview-user-email-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.my-account-overview-low-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  background: rgba(237, 242, 244, 0.60);
  border-radius: .5rem;
}

.my-account-overview-low-content h4 {
  margin-bottom: .5rem;
}

.my-account-overview-low-content hr {
  margin: 0;
  height: 2px;
  padding-bottom: 1rem;
  border-top: 2px solid rgba(30, 30, 30, 0.20);
  width: 100%;
}

.my-account-overview-order-resume-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.my-account-overview-order-resume-container p {
  margin-bottom: .5rem;
}

.my-account-overview-order-resume-container p,
.my-account-overview-order-resume-container h6 {
  margin: 0;

  color: rgba(30, 30, 30, 0.50);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.my-account-overview-order-resume-container h6 {
  color: #1E1E1E;
  overflow: hidden;
  font-size: 20px;
  font-weight: 700;
}

.my-account-overview-order-state h6 {
  color: #CE3C25;
}

.my-account-overview-order-address-container .my-account-address-card-main {
  pointer-events: none;
}

.my-account-overview-order-address-container .my-account-address-item-main {
  background: none;
  border: none;
  cursor: auto;
}

.my-account-overview-order-address-container .my-account-address-item-main-content-info p {
  color: #1E1E1E;
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
}

.my-account-overview-order-address-container .my-account-address-item-main-content-info p:nth-of-type(2) {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.my-account-overview-order-address-container .my-account-address-item-main-image {
  border-radius: 8px;

}

.my-account-overview-order-address-container .my-account-address-item-main-actions {
  display: none;
}

.my-account-overview-order-address-container h4 {
  color: rgba(30, 30, 30, 0.80);
}

.my-account-overview-order-payment-method .past-order-information-sub-container {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .my-account-overview-top-content {
    flex-direction: column;
  }

  .my-account-overview-user-information-container {
    width: 100%;
    margin-bottom: 1rem;
  }

  .my-account-overview-top-content .cart-big-club-preview,
  .my-account-overview-top-content .cart-big-club-preview-container {
    width: 100%;
  }

  .my-account-overview-top-content .cart-big-club-preview-row-image {
    width: 420px;
  }

  .my-account-overview-order-resume-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .my-account-overview-order-address-container .my-account-address-item-main {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }

  .my-account-address-item-main .my-account-address-item-main-content {
    flex-direction: row;
    gap: 1rem;
  }

  .my-account-overview-order-address-container .my-account-address-item-main-image {
    display: block;
  }
}

@media only screen and (min-device-width : 568px) and (max-device-width : 1040px) and (orientation : landscape) {
  .my-account-overview-top-content {
    flex-direction: column;
    display: flex;
    gap: .5rem;
  }

  .my-account-overview-top-content .cart-big-club-preview-container {
    order: 1;
  }

  .my-account-overview-user-information-container {
    order: 2;
  }

  .my-account-overview-order-resume-container {
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .my-account-overview-order-resume-container div {
    width: 45%;
  }

  .my-account-overview-order-resume-container h6 {
    width: 200px;
  }

  .my-account-overview-order-address-container .my-account-address-item-main {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }

  .my-account-address-item-main .my-account-address-item-main-content {
    flex-direction: row;
    gap: 1rem;
  }

  .my-account-overview-order-address-container .my-account-address-item-main-image {
    display: block;
  }
}
