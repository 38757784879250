.contact-us-main {
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 1.5rem;
  padding: 0 104px;
}

.contact-us-main-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 80px;
}
.contact-us-left-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 50%;
  height: 100%;
}
.contact-us-left-column .contact-us-left-column-item {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;
}
.contact-us-left-column .contact-us-left-column-item > h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #ce3c25;
}

.contact-us-left-column .contact-us-left-column-item > p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #1e1e1e;
}
.contact-us-left-column .contact-us-left-column-item > p > a {
  text-decoration: none;
  color: #ce3c25;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.contact-us-left-column .contact-us-left-column-item > p > a:hover {
  filter: opacity(0.7);
}

.contact-us-left-column-item .contact-us-lef-column-socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.contact-us-left-column-item .contact-us-lef-column-socials a {
  text-decoration: none;
  transition: 0.5s ease-in-out;
}
.contact-us-left-column-item .contact-us-lef-column-socials a:hover {
  filter: brightness(1.5);
}
.contact-us-left-column-item .contact-us-lef-column-socials a > img {
  width: 1.5rem;
  height: auto;
}

.contact-us-image {
  width: 31.25rem;
  height: auto;
  transition: 0.5s ease-in-out;
}
.contact-us-image:hover {
  filter: opacity(0.7);
}
@media screen and (max-width: 1000px) {
  .contact-us-left-column {
    width: 100%;
  }
  .contact-us-left-column .contact-us-left-column-item > h3 {
    font-size: 1rem;
  }
  .contact-us-left-column .contact-us-left-column-item > p {
    font-size: 0.9rem;
  }
  .contact-us-main-content {
    flex-direction: column;
  }
  .contact-us-main {
    padding: 0 16px;
  }

  .contact-us-image {
    width: 100%;
    height: auto;
  }
}
