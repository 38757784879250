.christmas-banner-main-container {
  max-width: 100%;
  background-color: #fff;
  border-radius: 4rem 4rem 1rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
}

.christmas-banner-top-content {
  border-radius: 4rem 4rem 1rem 1rem;
  padding: 0;
  max-width: 100%;
  height: 900px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.christmas-banner-top-content-text {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  z-index: 2;
}

.christmas-banner-top-content-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.christmas-banner-header-title,
.christmas-banner-header-text {
  color: #1E1E1E;
  font-family: 'Open Sans', sans-serif;
  font-size: 110px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}


.christmas-banner-bold-text {
  font-weight: 700;
  color: #EB5B1E;
}

.christmas-banner-header-text {
  font-size: 28px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: .5rem;
}

.christmas-banner-home-link {
  color: #FFF;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: .5rem;
  background: #CE3C25;
  padding: 16px 26px;
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 55%;
}

.christmas-banner-home-link:hover {
  text-decoration: none;
  color: #FFF;
}

.christmas-banner-low-container {
  position: relative;
}

.christmas-banner-low-container>img:first-of-type {
  position: absolute;
  top: -15px;
  right: -20px;
  z-index: 0;
}

.christmas-banner-low-container>img:last-of-type {
  position: absolute;
  top: -30px;
  left: 50px;
  z-index: 0;
}

.christmas-banner-low-container {
  background-image: url('../images/christmas-page-banner-low-container-image.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 2rem 2rem 3.75rem 2rem;
  width: 100%;
  margin-top: -250px;
  box-sizing: border-box;
}

.christmas-banner-low-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
}

.christmas-banner-low-content {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: auto;
  gap: 32px;
}

.christmas-banner-frames {
  width: 45%;
  display: flex;
  flex-direction: row;
  margin: 15px;
}

.christmas-banner-frames img {
  border-radius: 32px;
  width: 200px;
  height: 200px;
}

.christmas-banner-frames div {
  padding-left: 2rem;
}

.christmas-banner-frames h1,
.christmas-banner-frames p {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.christmas-banner-frames p {
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  letter-spacing: -0.16px;
}

@media only screen and (max-width: 767px) {
  .christmas-banner-top-content {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 1200px;
    flex-direction: column;
    position: static;
    justify-content: normal;
  }

  .christmas-banner-top-content-text {
    margin-top: -20px;
    position: static;
    z-index: auto;
    width: 100%;
    align-items: center;
    margin-bottom: 2rem;
  }

  .christmas-banner-top-content-image {
    position: static;
    z-index: auto;
  }

  .christmas-banner-header-title {
    font-size: 40px;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }

  .christmas-banner-header-text {
    font-size: 16px;
    text-align: center;
  }

  .christmas-banner-home-link {
    width: 60%;
    font-size: 12px;
    gap: .5rem;
    padding: .5rem;
  }

  .christmas-banner-low-container>img:first-of-type,
  .christmas-banner-low-container>img:last-of-type {
    display: none;
  }

  .christmas-banner-top-content-image img {
    width: 100%;
    height: 100%;
  }

  .christmas-banner-frames {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .christmas-banner-frames img {
    width: 200px;
    height: 200px;
  }

  .christmas-banner-low-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
  }

  .christmas-banner-frames div {
    padding-top: 1rem;
    padding-left: 0;
  }

  .christmas-banner-frames h1,
  .christmas-banner-frames p {
    text-align: center;
  }

  .christmas-banner-low-container {
    margin-top: -480px;
    padding: 2rem 1.5rem 3.75rem 0rem;
    margin-left: 1rem;
    border-radius: 2rem 2rem 1rem 1rem;
    background-image: url('../images/christmas-page-lower-container-mobile-image.png');
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .christmas-banner-top-content {
    padding: 2rem 1rem 0 1rem;
    height: 1520px;
    flex-direction: column;
    position: static;
    align-items: center;
    justify-content: normal;
  }

  .christmas-banner-top-content-text {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  .christmas-banner-header-title {
    text-align: center;
  }

  .christmas-banner-header-text {
    margin-bottom: 1rem;
    text-align: center;
  }

  .christmas-banner-home-link {
    width: 65%;
  }

  .christmas-banner-low-container>img:first-of-type,
  .christmas-banner-low-container>img:last-of-type {
    display: none;
  }

  .christmas-banner-top-content-image {
    position: static;
    z-index: auto;
  }

  .christmas-banner-low-container {
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    background-size: 100% 100%;
  }

  .christmas-banner-top-content-text {
    margin-top: -40px;
    width: 100%;
  }

  .christmas-banner-frames {
    width: 80%;
    margin: 5px;
  }

  .christmas-banner-frames div {
    padding-left: 1rem;
  }

  .christmas-banner-frames img {
    width: 180px;
    height: 170px;
  }
}
