.work-with-us-main {
  max-width: 1440px;
  padding: 0 104px;
  margin: 0 auto;
  margin-bottom: 80px;
  margin-top: 20px;
}

.work-with-us-wrapper {
  display: flex;
  align-items: center;
}

.work-with-us-text {
  padding-right: 15%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.work-with-us-text > h3 {
  font-size: 2.25rem;
  font-weight: 300;
  color: #ce3c25;
}

.work-with-us-text > h3 > b {
  font-weight: 600;
}

.work-with-us-text > a {
  font-weight: 700;
  background-color: #ce3c25;
  max-width: max-content;
  color: #fff;
  font-size: 16px;
  padding: 15px 25px;
  border-radius: 8px;
}

.work-with-us-image {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 900px) {
  .work-with-us-main {
    padding: 0 16px;
  }

  .work-with-us-wrapper {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .work-with-us-text {
    padding-right: 0;
    gap: 0.5rem;
  }

  .work-with-us-text > h3 {
    font-size: 1.75rem;
  }
}
