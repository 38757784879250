.free-shipping-notice-text {
  color: rgba(30, 30, 30, 0.50);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
}

.free-shipping-notice-main-green {
  color: #1C8E47;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .free-shipping-notice-text {
    display: block;
    width: 95%;
  }
}
