.checkout-card-brand-option-box-content {
  display: flex;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 8px;
  background: #fff;
  width: 200px;
  height: 50px;
  flex-wrap: wrap;
  align-items: center;
  font-size: 10px;
  cursor: pointer;
  border: solid 1px rgba(0.3, 0.3, 0.3, 0.5);
  transition: .5s ease-in-out;
}

.checkout-card-brand-option-box-content:hover {
  opacity: .6;
}

.checkout-card-brand-option-box-content.selected {
  border: 1px solid #EB5B1E;
}

.checkout-card-brand-option-box-text {
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
