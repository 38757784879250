.main-product-details-main {
  margin: 80px 0;
}

.main-product-details-tab-title-container {
  display: flex;
  gap: 32px;
  margin-bottom: 16px;
}

.main-product-details-tab-title-item {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: #1e1e1e;
  opacity: 0.5;
  cursor: pointer;
}

.main-product-details-tab-title-item-selected {
  color: #eb5b1e;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .main-product-details-tab-title-container {
    gap: 8px;
    flex-wrap: wrap;
  }
  .main-product-details-tab-title-item {
    font-size: 16px;
  }
}
