.search-page-container {
  display: flex;
}

.search-page-hidden {
  visibility: hidden;
}


.search-page-hits {
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  padding-right: 30px;
}

.search-page-grid .ais-Hits-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  gap: .5rem;
}

.search-page-grid .search-result-square-main {
  margin: 0 !important;
  width: 170px;
}

.search-page-hit-data {
  margin-top: 18px;
  font-size: 14px;
}

.search-page-hits-clear {
  display: flex;
  margin: 25px 0px;
}

.ais-Pagination {
  margin: 0px 0px 50px 0px !important;
}

.ais-Pagination-list {
  background-color: #ededed;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;

  height: 30px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.ais-Pagination-item {
  margin: 0px 10px;
}

.ais-Pagination-item:hover {
  cursor: pointer;
}

.ais-Pagination-item--disabled:hover {
  cursor: default;
}

.search-page-slider-container {
  width: 160px;
  margin: 5px 15px 5px 15px;
}

@media only screen and (max-width: 750px
  /*SMALL_MEDIA_SIZE*/
) {
  .search-page-hit-data {
    display: none;
  }

  .search-page-hits {
    padding-left: 10px;
    padding-right: 10px;
  }
}
