.newsletter-wrapper {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  min-height: 6.375rem;
  background: #ce3c25;
}
.newsletter-row {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.newsletter-wrapper .newsletter-icon {
  height: 3.125rem;
  width: 3.125rem;
}
.newsletter-wrapper .newsletter-input {
  width: 22.5rem;
  height: 2.5rem;
  border: 1px solid #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  padding: 0.5rem;
  background-color: #ce3c25;
  outline: #f2f2f2;
  color: #fff;
}
.newsletter-wrapper .newsletter-input::placeholder {
  color: #fff;
}
.newsletter-wrapper .newsletter-button {
  border: none !important;
  outline: none !important;
  border-radius: 0 0.5rem 0.5rem 0;
  height: 2.5rem;
  width: 2.8rem;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newsletter-wrapper .newsletter-button:hover {
  filter: opacity(0.7);
}

.newsletter-wrapper .newsletter-button:active {
  filter: brightness(1.1);
}
.newsletter-wrapper .newsletter-button-success {
  filter: opacity(0.7);
}
.newsletter-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.newsletter-content-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
}
.newsletter-content-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin: 0;
  padding: 0;
}
.news-letter-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-letter-input-wrapper .newsletter-button {
  position: absolute;
  right: 0;
}
@media screen and (max-width: 800px) {
  .newsletter-wrapper {
    gap: 1rem;
  }
  .newsletter-wrapper .news-letter-input {
    width: 100%;
  }
}
