.hamburger-menu {
  position: absolute;
  left: 0px;
  display: none;
  width: 100%;
  /* Minus the height of the hamburger and the footer since element is relative to body */
  z-index: 1000;
  box-shadow: 0px 7.19808292388916px 5.758466720581055px 0px rgba(0, 0, 0, 0.06),
    0px 24.176864624023438px 19.341487884521484px 0px rgba(0, 0, 0, 0.08),
    0px 108.24018859863281px 86.5921630859375px 0px rgba(0, 0, 0, 0.14);
  width: 100%;
}

.hamburger-menu-main {
  background-color: #ffffff;
  color: #212121;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;

  width: 100%;
  padding: 1rem;
  border-radius: 0 0 8px 0;
}

.hamburger-menu-link {
  color: #212121;
  font-size: 18px;
  font-weight: 700;

  display: flex;
  align-items: center;
  margin-left: 1rem;
  width: 100%;
  height: 35px;
}

.hamburger-menu-main > a:hover {
  text-decoration: none;
  color: #212121;
}

.hamburger-menu-main > a:focus {
  text-decoration: none;
}

.hamburger-menu-main p {
  margin: 0px;
}

.hamburger-bigclub-mobile-main {
  margin: 1rem;
  width: max-content;
  text-decoration: none !important;
}

.hamburger-bigclub-mobile-content {
  outline: #ce3c25 !important;
  padding: 12px 20px;
  border-radius: 8px;
  border: 1px solid #ce3c25;
  color: #ce3c25;
  transition: all 0.5s ease-in-out;
  font-family: 'Open Sans';
  cursor: pointer;
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  -webkit-font-smoothing: antialiased;
}

.hamburger-bigclub-mobile-content > p {
  font-size: 26px;
  margin: 0;
}

.hamburger-bigclub-mobile-content > div {
  width: 0.5px;
  height: 24px;
  background-color: #1e1e1e;
}
.hamburger-bigclub-mobile-content > span {
  font-size: 12px;
  color: #1e1e1e;
}

.hamburger-bigclub-mobile:hover {
  filter: opacity(0.7);
}
.hamburger-bigclub-mobile:active {
  filter: opacity(0.5);
}

@media screen and (max-width: 768px) {
  .hamburger-menu {
    display: block;
  }
}
