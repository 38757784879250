.landing-faq {
  width: 100%;
  height: 100%;
}

.landing-faq-active {
  color: #1E1E1E !important;

}

.landing-faq-collapse {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  gap: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 1rem;
  border: 3px solid var(--preto-20, rgba(30, 30, 30, 0.20));
  width: 100%;
  cursor: pointer;
}

.landing-faq-collapse-active {
  border: 3px solid #EB5B1E;
}

.landing-faq-collapse-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
}

.landing-faq-collapse-title {
  margin: 0;
  width: 100%;
  font-size: min(1.3rem, 4vw);
  color: rgba(30, 30, 30, 0.50);
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  line-height: normal;

}

.landing-faq-collapse-btn {
  padding: 0.5rem;
  border-radius: 100%;
  height: 60px;
  width: 70px;
  cursor: pointer;
  transition: ease-in-out 0.5s;
  outline: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none !important;
}

.landing-faq-collapse-btn i {
  font-size: min(1rem, 6vw);
  color: rgba(30, 30, 30, 0.50);

}

.landing-faq-collapse-btn-active i {
  color: #EB5B1E;
}

.landing-faq-collapse-btn:hover {
  opacity: 0.8;
}

.landing-faq-collapse-body {
  width: 100%;
  height: 100%;
  font-size: min(1.2rem, 5vw);
  font-style: normal;
  font-weight: 500;
  line-height: 2.25rem;
  color: rgba(30, 30, 30, 0.50);
}

.landing-faq-collapse-body-alt {
  visibility: none;
  display: none;
}

.landing-faq-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}

.landing-faq-fadeIn {
  -webkit-animation-name: landing-faq-fadeIn;
  animation-name: landing-faq-fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes landing-faq-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes landing-faq-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 800px) {
  .landing-faq-collapse-body {
    width: 100%;
  }

  .landing-faq-collapse {
    padding: 1rem;
    width: 100%;
  }

  .landing-faq-collapse-header {
    justify-content: baseline;
  }

  .landing-faq {
    padding: 0.5rem;
  }

  .landing-faq-collapse-title {
    font-size: min(1.2rem, 4vw);
  }

  .landing-faq-collapse-body {
    font-size: min(1.1rem, 4vw);
    line-height: 1.5rem;
  }

  .landing-faq-collapse-header {
    justify-content: center;
    width: 100%;
    gap: 0.2rem;
  }
}
