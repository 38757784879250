.my-account-evaluations-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: .5rem;
  margin: 1rem 0;
  border-top: 2px solid rgba(30, 30, 30, 0.20);
  padding: 1rem 0;
  width: 100%;
  flex-wrap: wrap;
}

.my-account-evaluations-item .progressive-image-loader-final-img {
  width: 7.25rem;
}


.my-account-evaluations-item .my-account-evaluations-item-row {
  display: flex;
  align-items: center;
  gap: .5rem;
  flex-wrap: wrap;
}

.my-account-evaluations-item-row .my-account-evaluations-item-row-info {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.my-account-evaluations-item-row .my-account-evaluations-item-row-info span {
  margin: 0 !important;
  color: rgba(30, 30, 30, 0.50);
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.my-account-evaluations-item-row .my-account-evaluations-item-row-info h5 {
  margin: 0 !important;
  color: #1E1E1E;
  font-family: "Open Sans", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-wrap: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.my-account-evaluations-item .my-account-evaluations-item-link {
  padding: .5rem .75rem;
  display: flex;
  align-items: center;
  text-wrap: nowrap !important;
  color: #EB5B1E;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  border: 1px solid #EB5B1E;
  border-radius: .5rem;
  text-decoration: none;
  min-width: fit-content;
  transition: .5s ease-in-out;
}

.my-account-evaluations-item .my-account-evaluations-item-link:hover {
  opacity: .6
}



@media screen and (max-width:1000px) {
  .my-account-evaluations-item {
    flex-direction: column;
  }

  .my-account-evaluations-item-row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }



  .my-account-evaluations-item-row .my-account-evaluations-item-row-info h5 {
    font-size: 1rem;
  }
}
