.cooking-recomedation-box-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cooking-recomedation-box-title {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}

.cooking-recomedation-box-wrapper {
  display: flex;
  gap: 16px;
}

.cooking-recomedation-box-item {
  display: flex;
  gap: 4px;
  opacity: 0.5;
}

.cooking-recomedation-box-item>img {
  width: auto;
  height: 24px;
}

.cooking-recomedation-box-item>p {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  color: #1e1e1e;
}
