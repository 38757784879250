.department-hit-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 32px;
}



.department-hit-image-container {
  width: 100%;

}

.department-hit-image {
  cursor: pointer;
  height: auto;
  width: 256px;
}

.department-placeholder {
  width: 100%;
  height: 80px;
  background-color: #777777;
}

.department-hit-text-group {
  width: 100%;
}

.department-hit-title>h3 {
  color: #1e1e1e;

  font-family: 'Open Sans';
  font-size: 22px;
  font-weight: 700;
  line-height: 140%;
}

.department-hit-title:hover {
  text-decoration: none;
}

.department-hit-title:hover>h3 {
  color: #535353;
  cursor: pointer;
}

.department-hit-divisor {
  width: 100%;
  margin: 8px 0;
  border-top: 1px solid rgba(30, 30, 30, 0.5);
}

.department-hit-text-group {
  display: inline-flex;
  flex-direction: column;
  padding: 10px;
}

.department-hit-entries {
  color: rgba(30, 30, 30, 0.5);
  margin-bottom: 8px;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;

  line-height: normal;
}

.department-hit-entries:hover {
  text-decoration: none;
  color: #e96d38;
}

.deparment-hit-see-more>a {
  font-size: 14px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #eb5b1e;
}

.deparment-hit-see-more>a:hover {
  color: #e96d38;
  text-decoration: none;
}

@media only screen and (max-width: 1250px) {

  .department-hit-title>h3 {
    font-size: 15px;
  }

  .department-hit-text-group {
    padding: 5px;
  }

  .department-hit-image {
    width: 160px;
  }

  .department-hit-divisor {
    margin: 4px 0;
  }

  .department-hit-entries {
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 500px) {
  .department-hit-title>h3 {
    font-size: 14px;
  }
}
