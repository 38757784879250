.portion-selector-container{
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  p{
    margin: 0;
  }
}

.quantity-input-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ce3c25;
  padding: 4px;
  gap: 4px;
  border-radius: 1rem;
  padding: 8px;
}

.quantity-input-inner-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 1rem;
  padding-right: 8px;
}

.quantity-input-unity{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: rgba(30, 30, 30, 0.8);
  margin: 0;
  padding: 0;
}

.quantity-input{
  width: 70px;
  padding: 4px;
  border: 0;
  border-radius: 1rem;
  padding: 8px;
}

.quantity-input:focus {
  outline: none;
}


.selector-container{
  padding: 4px;
}

.quantity-control-container{
  display: flex;
  flex-direction: column;
}

.quantity-control{
  height: fit-content;
  width: 24px;
  border: 0;
  font-family: monospace;
  background-color: transparent;
}
