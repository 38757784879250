.radio-button-container {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.radio-button-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #00000050;
}

.radio-button-text {
  margin: 0;
}

.radio-button-circle.selected {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 4px solid #2eb411;
}
