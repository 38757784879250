.logo-main {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0px 10px 0px 0px;
  width: 270px;
  position: relative;
}

.logo-image {
  height: 100px;
  width: 178px;
}

@media only screen and (max-width: 750px
  /*SMALL_MEDIA_SIZE*/
) {
  .logo-main {
    display: none;
  }

}
