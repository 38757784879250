.line-item-list-main {
  width: 100%;
}

.line-item-list-main .line-item-list-main-title {
  font-family: "Open Sans", sans-serif;
  color: #1e1e1e;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
}

.line-item-list-container {
  display: flex;

}

.line-item-list-item {
  display: flex;
  width: 100%;
  margin-bottom: .5rem;
  padding: 1rem 0;
  background: none;
  border-top: 2px solid rgba(0.3, 0.3, 0.3, 0.20);
}



.line-item-list-item-content span {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 0.625rem;
}

.line-item-list-item-content h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.line-item-list-column {
  display: flex;
  width: 100%;
  gap: .5rem;
  flex-direction: column;
}

.line-item-list-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 35px;
}

.line-item-list-row-info,
.line-item-list-row-text {
  font-size: 0.875rem;
  font-weight: 400;
}

.line-item-list-item .line-item-list-row-info {
  border-radius: .5rem;
  border: solid 1px rgba(30, 30, 30, 50);
  padding: 0.25rem .5rem;
  min-width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-item-list-item .line-item-list-row-info-price {
  border: none;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
  border-radius: .5rem;
  border: solid 1px rgba(30, 30, 30, 50);
  padding: 0.40rem .5rem;
  max-width: 95px;
  min-width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-item-list-observation {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.line-item-list-observation .line-item-list-observation-title {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
}

.line-item-list-observation .line-item-list-observation-text {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
}

.line-item-list-item .line-item-list-button {
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding: 0;
  gap: .5rem;
  border: none !important;
  outline: none !important;
  color: #1E1E1E;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
}

.line-item-list-item .line-item-list-observation {
  outline: none;
  border: none;
  padding: .25rem .5rem;
  border-radius: 8px;
  background: #FFF;
  resize: none;
}

@media screen and (max-width:1000px) {
  .line-item-list-item {
    flex-direction: column;
    align-items: center;
  }

  .line-item-list-item .image-list-main {
    width: 100%;
    max-width: 100%;
  }
}
