.total-available-store-credit-main {
    padding-bottom: 1vw;
    text-align: center;
    font-weight: 300;
    font-family: "Open Sans", sans-serif;
    font-size: 14pt;
}

.total-available-store-credit-main .description {
    font-size: 12pt;
    width: 70%;
    margin: auto;
}

@media only screen and (max-width: 540px) {
    .total-available-store-credit-main {
        padding-bottom: 5vw;
    }
}