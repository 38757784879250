.search-result-hit-horizontal-bottom-row-main {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.search-result-hit-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  max-width: 300px;
}

.search-result-hit-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.search-result-hit-content .product-price-main {
  height: auto;
}

.search-result-price-value {
  color: #757575;
  font-size: 20px;
  font-weight: 700;
  width: 130px;
  margin: 0px;
}

.search-result-hit-details {
  margin-bottom: 0px !important;
}

.search-result-hit-detail-list {
  display: flex;
  flex-wrap: wrap;
  min-width: 100px;
}

.search-result-hit-detail-list img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

/*To remove agolia styling from button*/
.ais-Hits button {
  border: 0px solid #fff !important;
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1) !important;
}

.search-result-hit-details-price {
  display: none;
}

@media only screen and (max-width: 750px) {
  .search-result-price-value>.product-price-main {
    width: 100px;
    margin-right: 5px;
  }

  .search-result-price-value>.product-price-main>h5 {
    font-size: 14px;
  }

  .search-result-price-value>.product-price-main>h6 {
    font-size: 12px;
  }

  /* Above CSS Touches styling of ProductPrice component */

  .search-result-hit-details-container {
    font-size: 12px;
  }

  .search-result-hit-detail-list {
    display: flex;
    flex-wrap: wrap;
    min-width: 50px;
  }
}

@media only screen and (max-width: 550px) {
  .search-result-hit-details {
    margin-right: 5px;
  }

  .search-result-hit-detail-list {
    display: none;
  }
}

@media only screen and (max-width: 380px) {
  .search-result-hit-horizontal-bottom-row-main {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }

  .search-result-hit-content {
    max-width: 130px;
    margin: 0px 0px 0px 5px;
  }

  .search-result-hit-details-price {
    display: block;
    width: 100px;
  }

  .search-result-price-value {
    display: none;
  }

  .search-result-hit-details {
    text-align: center;
    margin-right: 0px;
  }

  /* Above CSS Touches styling of ProductPrice component */
  .search-result-hit-details-price>.product-price-main {
    width: 100px;
    margin-right: 5px;
    margin-top: 5px;
  }

  .search-result-hit-details-price>.product-price-main>h5 {
    font-size: 14px;
    width: 105px;
  }

  .search-result-hit-details-price>.product-price-main>h6 {
    font-size: 12px;
  }
}
