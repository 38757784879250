.blend-calculator-banner-image {
  width: 100%;
}

.blend-calculator-page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 50px 150px 0 150px;
}

.blend-calculator-page-content {
  width: 100%;
}

.blend-total-question {
  font-size: 14px;
  text-align: center;
  line-height: 17px;
  color: #0066c0;
  margin-top: -7px;
}

.blend-question-mark {
  width: auto;
  height: 12px;
  align-self: center;
  margin: 5px 5px;
}

.blend-calculator-page-content>h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin-bottom: 30px;
}

.blend-calculator-page-header {
  display: flex;
}

.blend-calculator-page-header-info {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 114px;
}

.blend-calculator-page-sub-header-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.blend-calculator-page-sub-header-info>div {
  width: 100%;
  align-self: center;
}

.blend-calculator-page-fat-percentage {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.blend-calculator-page-fat-percentage>div {
  margin-left: 15px;
  align-self: flex-start;
  width: 90%;
}

.blend-calculator-page-fat-percentage-title {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.blend-calculator-page-fat-percentage>p {
  width: 10%;
  height: auto;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: end;
  align-self: center;
  color: #eb5b1e;
  margin-top: 12px !important;
}

.blend-box-image {
  width: 114px;
  height: 114px;
}

.blend-name {
  height: 37px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  text-indent: 15px;
  border: none;
  background: #ededed;
}

.blend-description {
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  text-indent: 15px;
  border: none;
  background: #ededed;
}

.blend-description,
textarea {
  overflow: hidden;
  resize: none;
  width: 100%;
}

.blend-calculator-page-subcontrainer {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.product-search-main {
  width: 50%;
  height: 100%;

  box-sizing: border-box;
}

.product-search-hits {
  padding-top: 1rem;
}

.blend-calculator-page-right {
  width: 49%;
  height: 918px;

  display: flex;
  flex-direction: column;
}

.blend-calculator-new-blend {
  width: 100%;
  height: 852px;

  border: 1px solid #e5e5e5;
  box-sizing: border-box;
}

.blend-calculator-new-blend-header {
  text-align: center;
  margin-top: 20px;
}

.blend-calculator-new-blend-header>h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.blend-calculator-no-items {
  width: 100%;
  text-align: center;
  padding: 42px 10px 0 10px;
  display: flex;
  flex-direction: column;
}

.blend-calculator-no-items>span {
  font-size: 18px;
}

.blend-calculator-no-items>img {
  width: 80px;
  margin-bottom: 10px;

  align-self: center;
}

.blend-calculator-new-blend-products {
  height: 655px;
  overflow-y: scroll;
}

.blend-calculator-cart-button {
  margin-top: 15px;
}

.separator-line {
  height: 2px;
  background: #ce3c25;
}

.blend-calculator-total-info {
  text-align: center;
}

.blend-calculator-total-info>p {
  margin: 0;
  font-size: 14px;
}

.blend-calculator-total-info-amout {
  line-height: normal;
  font-size: 20px !important;

  color: #ce3c25;
}

.blend-calculator-total-info-price {
  color: #2eb411;
}

@media only screen and (max-width: 1125px) {
  .blend-calculator-page-container {
    margin: 20px 50px 0 50px;
  }
}

@media only screen and (max-width: 770px) {
  .blend-calculator-page-subcontrainer {
    flex-direction: column;
  }

  .product-search-main {
    width: 100%;
    margin-bottom: 30px;
  }

  .blend-calculator-page-right {
    width: 100%;
  }

  .blend-calculator-page-sub-header-info {
    flex-direction: column;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 450px) {
  .blend-calculator-page-container {
    margin: 20px 10px 0 10px;
  }

  .blend-calculator-page-content>h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .blend-calculator-page-subcontrainer {
    flex-direction: column;
  }

  .blend-calculator-page-sub-header-info p {
    font-size: 14px;
  }

  .product-search-main {
    width: 100%;
    margin-bottom: 30px;
  }

  .blend-calculator-page-right {
    height: auto;
  }

  .blend-box-image {
    width: 85px;
    height: 85px;
  }

  .blend-calculator-page-header-info {
    margin-left: 10px;
    height: 85px;
  }

  .blend-name {
    height: 20px;
    font-size: 13px;
  }

  .blend-description {
    height: 35px;
    font-size: 10px;
  }

  .blend-calculator-new-blend-products {
    height: 100%;
    overflow-y: unset;
  }

  .blend-calculator-new-blend {
    height: 100%;
    padding-bottom: 10px;
  }

  .blend-calculator-page-fat-percentage>div {
    width: 85%;
  }

  .blend-calculator-page-fat-percentage>p {
    width: 15%;
  }
}
