.download-app-wrapper {
  width: 100%;
  height: 2.625rem;
  background-color: #ce3c25;
  color: #fff;
  padding: 0.5rem 0.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.download-app-close-button {
  border: none !important;
  outline: none !important;
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.download-app-close-button:hover {
  opacity: 0.7;
}
.download-app-close-button img {
  width: 1.5625rem;
  height: 1.5625rem;
}
.download-app-text h3 {
  margin-bottom: 0;
  font-size: 0.75rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
}
.download-app-text p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.5rem;
  margin-bottom: 0;
}
.download-app-content {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.download-app-content img {
  width: 1.25rem;
  height: 1.25rem;
}

.download-app-platforms {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.download-app-platforms .download-app-platforms-icon {
  width: 1.25rem;
  height: 1.25rem;
}
