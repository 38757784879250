.option-box-main {
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #b7b7b7;

  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  height: 40px;
  padding: 8px 12px;

  cursor: pointer;
}

.option-box-text {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.5;
}

.option-box-variant-value {
  color: #1e1e1e;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
}

.option-box-selected {
  border-color: #ce3c25;
}

.option-box-selected > .option-box-text {
  color: #ce3c25;
  opacity: 1;
}
