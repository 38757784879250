.delivery-pane-checkout-continue-button {
  display: flex;
  justify-content: center;
  width: 100%;
}

.delivery-pane-title {
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1.25rem;
}

.delivery-pane-intstructions {
  margin: 0px;
}

.delivery-pane-continue-container {

  margin-top: 1.25rem;
}

@media screen and (max-width: 800px) {
  .delivery-pane-continue-container {
    flex-direction: column;
    align-items: center;
  }
}
