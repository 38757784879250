.wishlist-content-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 40vh;

}

.wishlist-content-title {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}

.wishlist-content-list-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.wishlist-content-list-button {
  border: none !important;
  outline: none !important;
  border-radius: .5rem;
  background: #EDF2F499;
  padding: .7rem .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  cursor: pointer;
  transition: .5s ease-in-out;
  min-width: fit-content;
}

.wishlist-content-list-button span {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  text-wrap: nowrap;
}

.wishlist-content-list-button img {
  width: 1rem;
  height: 1rem;
}

.wishlist-content-list-button:hover {
  opacity: .7;
}


@media screen and (max-width:1000px) {
  .wishlist-content-list-row {
    flex-direction: column;
  }

  .wishlist-content-list-button {
    width: 100%;
  }
}
