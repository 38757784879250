.big-club-indicator-main {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-left: 20px;
}

.big-club-indicator-description {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  color: #1e1e1e;
  line-height: 120%;
  font-weight: 500;
}

.big-club-indicator-balance {
  display: flex;
  align-items: center;
  gap: 8px;
}

.big-club-indicator-balance-show {
  width: 24px;
  cursor: pointer;
}

.big-club-indicator-balance-value {
  color: #1c8e47;
  font-size: 20px;
  line-height: 110%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: 70px;
}

.big-club-indicator-balance-value-hidden {
  background: #efeeee;
  border-radius: 4px;
  color: transparent;
}

@media only screen and (max-width: 750px) {
  .big-club-indicator-main {
    display: none;
  }
}
