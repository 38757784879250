.takeout__paragraph,
.takeout__paragraph-bold {
  font-size: 1rem;
  text-align: left;
  margin-bottom: 0.5rem;
}
.takeout__paragraph-bold {
  font-weight: bold;
}
.takeout__map {
  width: 19.5rem;
  height: 14.5625rem;
  border: 2px solid #ce3c25;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}
.takeout__container {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 800px) {
  .takeout__paragraph {
    text-align: center;
  }
  .takeout__container {
    align-items: center;
  }
}
