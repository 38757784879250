.repeat-last-purchase-popup {
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 24px 0px 24px 24px;
  border: 1px solid rgba(30, 30, 30, 0.20);
  background: #FFF;
  box-shadow: 0px 0.33885px 0.85709px 0px;

  width: 70%;
  max-width: 400px;
  margin-left: 13.2%;
  height: 350px;

  position: absolute;
  top: 80px;
  z-index: 500;
  left: 52.5%;
  transform: translateX(-50%);
}

.repeat-last-purchase-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 90%;
  margin: auto;
  height: 10px;
  color: #000;
  text-align: right;
  margin-top: 15px;
}

.repeat-last-purchase-popup-header h2 {
  font-feature-settings: 'calt' off;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: -0.16px;
  font-size: 1em;
  max-width: 340px;
  margin-bottom: 0px;
}

.repeat-last-purchase-close-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: #CE3C25;
  height: 24px;
}

.repeat-last-purchase-main {
  width: 95%;
  display: flex;
  flex-direction: column;
  height: 370px;
  overflow-y: auto;
  margin: auto;
  margin-left: 12px;
}

.repeat-last-purchase-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.repeat-last-purchase-container img {
  width: 100px;
  height: 100px;
}

.repeat-last-purchase-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.repeat-last-purchase-content {
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.repeat-last-purchase-content-subcontainer {
  display: flex;
  flex-direction: column;
}

.repeat-last-purchase-content-subcontainer a:hover {
  text-decoration: none;
}

.repeat-last-purchase-content-subcontainer>p {
  margin-bottom: 1px;
  font-size: 14px;
}

.repeat-last-purchase-content-estimated {
  text-align: center;
  display: flex;
  width: 80px;
}

.repeat-last-purchase-content-estimated h3 {
  font-size: 14px;
  margin: 0px;
}

.repeat-last-purchase-content-subcontainer>a>h3 {
  align-self: stretch;
  color: #212121;
  font-feature-settings: 'calt' off;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.16px;
  margin: auto;
  max-width: 180px;
}

.repeat-last-purchase-buttons-container {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-around;
}

.repeat-last-purchase-add-to-cart-button {
  border-radius: 8px;
  border: 1px solid #1E1E1E !important;
  color: #1E1E1E;
  background: white;
}

.repeat-last-purchase-repeat-last-purchase-button {
  border: none;
  border-radius: 8px;
  background: #CE3C25;
  color: #FFF;
}

.repeat-last-purchase-add-to-cart-button,
.repeat-last-purchase-repeat-last-purchase-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px !important;
  padding: 0px !important;
  height: 40px !important;
  gap: 8px;
  font-feature-settings: 'calt' off;
  font-family: Open Sans, sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.repeat-last-purchase-add-to-cart-button span,
.repeat-last-purchase-repeat-last-purchase-button span {
  padding: 0px !important;
}

.repeat-last-purchase-main::-webkit-scrollbar {
  width: 12px;
}

.repeat-last-purchase-main::-webkit-scrollbar-thumb {
  background-color: #CE3C25;
  border-radius: 6px;
}

.repeat-last-purchase-main::-webkit-scrollbar-track {
  background-color: #E3E3E3;
  border-radius: 6px;
}

.confirmation-modal-actions button {
  width: 120px !important;
}

@media (min-width: 601px) and (max-width: 1024px) {
  .repeat-last-purchase-popup {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .repeat-last-purchase-popup {
    width: 258px;
    height: 690px;
    margin: auto;
    padding: 16px 0px;
    padding-right: 10px;
    max-width: none;
    margin-right: 10px;
    top: 110px;
    left: 50%;
    transform: translateX(-25%);
    padding-bottom: 0px;
  }

  .repeat-last-purchase-popup-header {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .repeat-last-purchase-popup-header h2 {
    text-align: left;
  }

  .repeat-last-purchase-main {
    margin-top: 0px;
    margin-bottom: 20px;
    height: 440px;
  }

  .repeat-last-purchase-container {
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0px;
  }

  .repeat-last-purchase-container img {
    width: 150px;
    height: 120px;
  }

  .repeat-last-purchase-subcontainer {
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
  }

  .repeat-last-purchase-content-subcontainer>p {
    font-size: 14px;
  }

  .repeat-last-purchase-content-subcontainer>a>h3 {
    font-size: 14px;
    margin: 0px;
    max-width: 150px;
  }

  .repeat-last-purchase-subcontainer .image-list-control {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: -30px;
  }

  .repeat-last-purchase-content {
    flex-direction: column;
    margin-left: 15px;
    width: calc(100% - 45px)
  }

  .repeat-last-purchase-content>p {
    font-size: 10px;
  }

  .repeat-last-purchase-content-estimated {
    margin-top: 30px;
    margin-left: 10px;
    text-align: center;
    display: flex;
    width: 60px;
    margin: 0px;
  }

  .repeat-last-purchase-content-estimated {
    order: 2;
    margin-top: 5px;
    width: 100%;
  }

  .repeat-last-purchase-content-estimated h3 {
    font-size: 14px;
  }

  .repeat-last-purchase-add-to-cart-button {
    gap: 8px;
    margin-bottom: 15px;
  }

  .repeat-last-purchase-repeat-last-purchase-button {
    padding: 8px 12px;
  }

  .repeat-last-purchase-add-to-cart-button,
  .repeat-last-purchase-repeat-last-purchase-button {
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding: 6px 8px;
    width: 170px;
    height: 40px;
  }

  .repeat-last-purchase-buttons-container {
    flex-direction: column;
    margin: auto;
    align-items: flex-start;
    margin-left: 15px;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .repeat-last-purchase-popup {
    width: 258px;
    height: 690px;
    margin: auto;
    padding: 16px 0px;
    padding-right: 10px;
    max-width: none;
    margin-right: 10px;
    top: 110px;
    left: 50%;
    transform: translateX(-32%);
    padding-bottom: 0px;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .repeat-last-purchase-popup {
    width: 60%;
    left: 50%;
    top: 140px;
    transform: translateX(-35%);
  }
}
