.whatsapp-button-wrapper {
  text-decoration: none !important;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.whatsapp-button-wrapper button {
  border: none;
  background: none;
  outline: none !important;
  width: 368px;
  padding: 1rem;
  background: #ce3c25;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #fff;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}
.whatsapp-button-wrapper button img {
  height: auto;
  width: 1.5rem;
}
.whatsapp-button-wrapper:hover {
  filter: brightness(1.1);
}

@media screen and (max-width: 1000px) {
  .whatsapp-button-wrapper button {
    width: 100%;
    padding: 0.2rem;
  }
}
