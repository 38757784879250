.page-loader-image {
  position: relative;
  height: 50px;
  /* This container must have a set height and width for proper alignment*/
  width: 50px;
  margin-left: 10px;

}

.page-loader-text {
  font-size: 15px;
  margin-top: -15px !important;
}

.scroller-main {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-bottom: 20px;
}

.scroller-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  margin-top: 30px;
}

.scroller-subloader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroller-subloader>.fa-spinner {
  margin-bottom: 5px;
}

.scroller-header {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.scroller-header>h3 {
  color: black;
  font-size: 18px;
  text-align: center;

  display: inline;
  white-space: nowrap;
  margin: 0px 15px;
}

.scroller-header>h3>a {
  color: black;
}

.scroller-header>h3>a:hover {
  text-decoration: none !important;
}

.scroller-header>h3>a:focus {
  text-decoration: none !important;
}

.scroller-small-line {
  border-color: #eb5b1e;
  border-width: 2px;
  width: 150px;
}

.scroller-medium-line {
  border-color: #eb5b1e;
  border-width: 2px;
  width: 100%;
}

.scroller-full-line {
  border-color: #eb5b1e;
  border-width: 2px;
  width: 100%;
  margin-bottom: 0px;
}

.scroller-arrow-container {
  visibility: hidden;
}

.scroller-arrow {
  visibility: visible;
  margin: 5px 20px;
  color: #969898;
}

.slick-slider {
  display: flex !important;
  align-items: center;
}

.scroller-arrow:hover {
  cursor: pointer;
}

.slick-arrow.slick-prev.slick-disabled>.scroller-arrow-container>.scroller-arrow {
  color: #dfe0e0;
}

.slick-arrow.slick-prev.slick-disabled>.scroller-arrow-container>.scroller-arrow:hover {
  cursor: default;
}

.slick-arrow.slick-next.slick-disabled>.scroller-arrow-container>.scroller-arrow {
  color: #dfe0e0;
}

.slick-arrow.slick-next.slick-disabled>.scroller-arrow-container>.scroller-arrow:hover {
  cursor: default;
}

.scroller-mobile {
  display: none;
}

@media only screen and (max-width: 750px) {
  .scroller {
    display: none;
  }

  .scroller-mobile {
    display: block;
  }

  .scroller-mobile-products {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .scroller-mobile-see-more {
    text-align: center;
    font-size: 18px;
    margin-top: 15px;
    width: 100%;
  }
}