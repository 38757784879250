.nav-header-menu {
  list-style: none;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}
.nav-header-navbar {
  display: flex;
  height: 100%;
}
.nav-header-menu a {
  color: #fff;
  text-decoration: none;
  font-family: 'Open sans', sans-serif;
  padding: 0.2rem;
  display: block;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-decoration: none !important;
}

.nav-header-menu a:hover {
  display: block;
  color: rgba(255, 255, 255, 0.7);
}
.nav-header-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-header-logo {
  width: 10rem;
  height: 1.5rem;
}
.nav-header-menu li .cart-indicator-button-container {
  margin-left: 1rem;
  margin-right: 0;
  outline: none;
  border: none;
  color: #fff;
}
.nav-header li .cart-indicator-button-container .cart-indicator-button-icon {
  background-image: url('../images/shopping_cart_white.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.nav-header-menu .cart-indicator-main {
  height: 100%;
}
.nav-header-menu
  .cart-indicator-item
  .cart-indicator-item-text-alt
  .cart-indicator-item-text-alt-quantity {
  color: #fff;
}
.nav-header-menu .cart-indicator-link {
  margin: 0;
  padding: 0;
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ce3c25;
  width: 100%;
  height: 5rem;
  padding: 1rem;
  padding: 0 7.625rem;
}
.nav-header-btn-mobile {
  display: none;
}

.nav-header-hamburger-bar1,
.nav-header-hamburger-bar2,
.nav-header-hamburger-bar3 {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}
.nav-header-navbar .cart-change-notice-container {
  right: 121px;
}
.nav-header-navbar .arrow-up {
  right: 121px;
}

@media screen and (max-width: 800px) {
  .nav-header-navbar {
    display: flex;
    height: auto;
  }
  .nav-header {
    padding: 1rem;
  }
  .nav-header .cart-indicator-item {
    display: block;
  }
  .nav-header-menu .cart-indicator-main {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .nav-header-menu {
    margin: 0;
    padding: 0 0.7rem;
    position: absolute;
    background-color: #ce3c25;
    width: 100%;
    top: 5rem;
    right: 0;
    height: 0px;
    display: block;
    z-index: 1000;
    transition: 0.6s;
    overflow-y: hidden;
  }
  .nav-header-navbar .cart-change-notice-container {
    top: 77px;
    right: 5px;
  }
  .nav-header-navbar .arrow-up {
    top: 67px;
    right: 20px;
  }
  .nav-header-navbar.active .arrow-up {
    top: 225px;
    left: 21px;
  }
  .nav-header-navbar.active .cart-change-notice-container {
    top: 235px;
    left: 13px;
  }
  .nav-header-navbar.active .nav-header-menu {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 11.5rem;
    visibility: visible;
    overflow-y: hidden;
    border-radius: 0 0 0.5rem 0.5rem;
  }
  .nav-header-btn-mobile {
    display: block;
    font-size: 1rem;

    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    gap: 0.5rem;
  }
  .nav-header-hamburger-icon {
    width: 20px;
    border-top: 3px solid;
    height: 2px;
    position: relative;
    color: #fff;
  }
  .nav-header-hamburger-icon::after,
  .nav-header-hamburger-icon::before {
    display: block;
    content: '';
    width: 20px;
    height: 2px;
    background: currentColor;
    margin-top: 5px;
    transition: 0.3s;
    position: relative;
  }

  .nav-header-navbar.active .nav-header-hamburger-bar1 {
    transform: translate(0, 11px) rotate(-45deg);
  }
  .nav-header-navbar.active .nav-header-hamburger-bar2 {
    opacity: 0;
  }
  .nav-header-navbar.active .nav-header-hamburger-bar3 {
    transform: translate(0, -11px) rotate(45deg);
  }

  .nav-header-menu li .cart-indicator-button-container {
    margin-top: 1rem;
    margin-left: 0 !important;
  }
}
