.faq-page-main {
  max-width: 1440px;
  padding: 0 104px;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.faq-page-hits {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .faq-page-main {
    padding: 1rem;
  }
}
