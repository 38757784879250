.notify-me-button {
  padding: 8px 12px;
  width: 127px;
  height: 40px;
  border: 1px solid #1e1e1e !important;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notify-me-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.notify-me-button-text {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: #1e1e1e;
}

@media screen and (max-width: 768px) {
  .notify-me-button {
    gap: 2px;
    width: 91px;
    height: 32px;
  }

  .notify-me-button-text {
    font-size: 10px;
    line-height: 24px;
  }
}
