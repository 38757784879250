.kits-banner-main-container {
  max-width: 100%;
  background-color: #fff;
  border-radius: 4rem 4rem 1rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  gap: 4rem;
}

.kits-banner-top-content {
  border-radius: 4rem 4rem 1rem 1rem;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.kits-banner-top-content-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 65%;
}

.kits-banner-header-title,
.kits-banner-header-text {
  color: #1e1e1e;
  font-family: 'Open Sans', sans-serif;
  font-size: 110px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.kits-banner-bold-text {
  font-weight: 700;
  color: #eb5b1e;
}

.kits-banner-header-text {
  font-size: 28px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.kits-banner-home-link {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 0.5rem;
  background: #ce3c25;
  padding: 16px 26px;
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 35%;
}

.kits-banner-home-link:hover {
  text-decoration: none;
  color: #fff;
}

.kits-banner-top-content-image {
  margin-right: -100px;
}

.kits-banner-top-content-image img {
  width: 95%;
}

.kits-banner-low-container {
  background-image: url('../images/black-friday-banner2-image.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 2rem 2rem 3.75rem 2rem;
  border-radius: 4rem 4rem 4rem 4rem;
  width: 100%;
  box-sizing: border-box;
}

.kits-banner-low-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
}

.kits-banner-low-content {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: auto;
  gap: 32px;
}

.kits-banner-frames {
  width: 45%;
  display: flex;
  flex-direction: row;
  margin: 15px;
}

.kits-banner-frames img {
  border-radius: 32px;
  width: 200px;
  height: 200px;
}

.kits-banner-frames div {
  padding-left: 2rem;
}

.kits-banner-frames h1,
.kits-banner-frames p {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.kits-banner-frames p {
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  letter-spacing: -0.16px;
}

@media only screen and (max-width: 767px) {
  .kits-banner-main-container {
    gap: 2rem;
  }

  .kits-banner-top-content {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    height: auto;
    position: static;
    justify-content: normal;
    align-items: center;
    justify-content: center;
  }

  .kits-banner-top-content-text {
    margin-top: -20px;
    position: static;
    z-index: auto;
    width: 100%;
    align-items: center;
    margin-bottom: 2rem;
  }

  .kits-banner-header-title {
    font-size: 40px;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }

  .kits-banner-header-text {
    font-size: 20px;
    text-align: center;
    font-weight: 400;
  }

  .kits-banner-home-link {
    width: 100%;
    font-size: 24px;
    gap: 0.5rem;
    padding: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kits-banner-arrow-icon {
    display: none;
  }

  .kits-banner-top-content-image {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kits-banner-top-content-image img {
    width: 100%;
    height: 100%;
  }

  .kits-banner-low-container {
    padding: 2rem 1.5rem 3.75rem 1.5rem;
    border-radius: 1.5rem;
    background-image: url('../images/black-friday-banner2-mobile-image.png');
  }

  .kits-banner-low-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
  }

  .kits-banner-frames {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .kits-banner-frames img {
    width: 200px;
    height: 200px;
  }

  .kits-banner-frames div {
    padding-top: 1rem;
    padding-left: 0;
  }

  .kits-banner-frames h1,
  .kits-banner-frames p {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1470px) {
  .kits-banner-low-container {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
  }

  .kits-banner-header-title {
    font-size: 72px;
  }

  .kits-banner-home-link {
    width: 100%;
  }

  .kits-banner-top-content-image {
    position: static;
    z-index: auto;
  }

  .kits-banner-frames {
    width: 80%;
    margin: 5px;
  }

  .kits-banner-frames div {
    padding-left: 1rem;
  }

  .kits-banner-frames img {
    width: 180px;
    height: 170px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .kits-banner-top-content-image {
    position: static;
    z-index: auto;
  }

  .kits-banner-top-content {
    flex-direction: column;
    /* height: 1200px; */
    justify-content: space-around;
  }

  .kits-banner-top-content-text {
    width: 100%;
  }

  .kits-banner-header-title {
    text-align: center;
  }

  .kits-banner-home-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kits-banner-top-content-image {
    width: 100%;
  }

  .kits-banner-top-content-image img {
    width: 100%;
  }
}
