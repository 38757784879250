.search-bar-main {
  flex-grow: 1;
  padding-right: 15pt;
}
.ais-SearchBox-input {
  border-radius: 1rem 0 0 1rem;
  outline: #ce3c25 !important;
  background: none;
  border: 1px solid #ce3c25;
  height: 3.5rem;
}
.ais-SearchBox-submit {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1rem 0 0 1rem;
}
@media screen and (max-width: 800px) {
  .search-bar-main {
    display: none;
  }
}
