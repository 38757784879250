.checkout-option-box-content {
  display: inline-flex;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  background: #ffff;
  align-items: center;
  cursor: pointer;
  transition: all .5s ease-in-out;
  border: 1px solid #00000080;
  font-weight: 700;
  color: #00000080;
}

.checkout-option-box-content:hover {
  opacity: .7;
}

.checkout-option-box-content.selected {
  color: #EB5B1E;
  border: 1px solid #EB5B1E;
  padding: 9px 19px;
}

.checkout-option-box-text {
  margin: 0;
}

.checkout-option-box-image-small {
  height: 20px;
  width: auto;
}
