.my-account-address-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-wrap: wrap !important;
}

.my-account-address-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}

.my-account-address-main hr {
  border-bottom: 2px solid rgba(30, 30, 30, 0.20);
  width: 100%;
}

.my-account-address-header h3 {
  color: #1E1E1E;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  margin-bottom: 0 !important;
}

.my-account-address-content,
.my-account-address-content-container,
.my-account-address-edit-item {
  width: 100%;
}

.my-account-address-content hr {
  border-bottom: 2px solid rgba(30, 30, 30, 0.20);
  width: 100%;
}

.my-account-address-item-main {
  width: 100%;
  border-radius: .5rem;
  border: 2px solid #1E1E1E33;
}

.my-account-address-add-new-button {
  width: 100%;
  max-width: 600px;
}


@media screen and (max-width: 1000px) {
  .my-account-address-item-main {
    flex-direction: column;
    align-items: flex-end !important;
  }

  .my-account-address-header .my-account-address-add-new-text,
  .my-account-address-header .my-account-address-add-return-text {
    display: none;
  }
}
