.product-search-container {
  width: 100%;
}

.product-search-hits {
  height: 881px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
}

.no-results {
  text-align: center;
  margin: 35px auto;
}

.search-header {
  display: flex;
}

.ais-SearchBox__root {
  width: 80%;
}

.ais-Toggle__root {
  margin: auto 0px;
}

.ais-Toggle__label {
  font-size: 17px;
  vertical-align: top;
}

.ais-Toggle__checkbox {
  height: 16px;
  width: 16px;
}

.ais-SearchBox__submit {
  background-color: #fff;
  border: 1px solid #a5a5a5;
  width: 25px;
  height: 26px;
  margin-left: 2px;
  margin-right: 2px;
}

.ais-SearchBox__submit:hover {
  cursor: pointer;
}

.ais-SearchBox__reset {
  background-color: #fff;
  border: 1px solid #a5a5a5;
  width: 25px;
  height: 26px;
}

.ais-SearchBox__reset:hover {
  cursor: pointer;
}

.ais-SearchBox__reset>svg {
  height: 9px;
  width: 9px;
}

.ais-SearchBox__wrapper {
  height: 100% !important;
  margin-bottom: 5px;
}

.ais-SearchBox__input {
  background-color: #fff;
  border: 1px solid #a5a5a5;
  width: 80% !important;
  height: 100% !important;
  padding: 5px 4px 6px 4px;
}

.search-header>.ais-SearchBox-input {
  border-radius: 0;
}

.search-header>.ais-SearchBox-submit {
  border-radius: 0;
}

@media only screen and (max-width: 770px) {
  .product-search-hits {
    height: auto;
  }
}