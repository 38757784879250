

.pix-page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-direction: column;
}
.pix-page-payment-not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 55vh;
}
.pix-page-payment-not-found-text {
  font-weight: bolder;
  font-size: 2em;
  padding: 0 20px;
  text-align: center;
  color: #eb5b1c;
}
.pix-page-payment-not-found-image {
  width: 25vw;
  max-width: 300px;
  min-width: 200px;
  height: auto;
}
.pix-page-loading > p {
  margin-top: 15px;
}
.pix-page-loading > i {
  color: #eb5b1c;
}

/* New Style*/

.pix-page-main-container {
  padding: 0px 104px;
  margin: auto;
  max-width: 1440px;
  margin-bottom: 80px;
}

.pix-page-text-orderNumber {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: rgba(227, 227, 227, 0.40);
  text-align: center;
  margin-bottom: 32px;
}

.pix-page-text-orderNumber h3 {
  color: rgba(30, 30, 30, 0.80);
  font-family: Open Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; 
}

.pix-page-text-orderNumber h1 {
  color: #1E1E1E;
  font-family: Open Sans, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; 
}

.pix-page-qrCode-payment {
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;
}

.pix-page-image-container, .pix-page-payment-instructions-container {
  padding: 10px;
  text-align: center;
  margin-right: 32px; 
  height: 300px;
}

.pix-page-image-container {
  width: 320px;
  height: 320px;
  padding-bottom: 30px;
}

.pix-page-payment-instructions-container {
  flex: 1;
}

.pix-page-warnings {
  width: 100%;
  margin-right: 0;
}

.pix-page-image-container img{
  width: 110%;
  height: 110%;
}

.pix-page-payment-instructions-container {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 64px;
  background: rgba(227, 227, 227, 0.40);
  justify-content: space-between;
}

.pix-page-payment-instructions {
  display: flex;
  flex-direction: column;
}

.pix-page-payment-instructions h2 {
  margin-bottom: 24px;
  color: #000;
  font-family: Open Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 20px; 
}

.pix-page-payment-instructions div{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  justify-content: center;
}

.pix-page-payment-instructions p {
  margin: 0px;
  color: #000;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; 
}

.pix-page-warnings {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 16px;
}

.pix-page-warnings-expiration-time {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: rgba(227, 227, 227, 0.40);
  padding: 16px 32px;
  width: 600px;
  height: 134px;
}

.pix-page-warnings-expiration-time p, .pix-page-warnings-expiration-time h2 {
  margin: 0px;
  max-width: 500px;
}

.pix-page-warnings-expiration-time p {
  max-width: 300px;
}

.pix-page-warnings-expiration-time div {
  margin-left: 16px;
}

.pix-page-warnings-copy-code {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 32px;
  width: 600px;
  height: 134px;
  border-radius: 8px;
  background: rgba(235, 91, 30, 0.15);
  cursor: pointer;
}

.pix-page-warnings-copy-code h6, .pix-page-warnings-copy-code h2 {
  margin: 0px;
  max-width: 500px;
  align-self: stretch;
  color: #EB5B1E;
  font-family: Open Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; 
}

.pix-page-warnings-copy-code h2 {
  margin-bottom: 16px;
}

.pix-page-warnings-copy-code h6 {
  font-weight: 600;
}

.pix-page-copy-past-code {
  margin-left: 16px;
}

.pix-page-text.pix-code-copied {
  color: #eb5b1c;
}

.pix-page-description {
  color: rgba(30, 30, 30, 0.50);
  font-family: Open Sans sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-align: justify;
  padding: 10px;
  margin-bottom: 32px;
}

.pix-page-black-text, .pix-page-green-text  {
  color: #1E1E1E;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.pix-page-green-text {
  color: #1C8E47;
}

.pix-page-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.pix-page-buttons a {
  color:  #FFF;
  text-align: center;
  font-family: Open Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.pix-page-go-to-navigation-button {
  margin-right: 16px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: #CE3C25;
  background: linear-gradient(90deg, #CE3C25 0%, #EB5B1E 57.81%);
}

.pix-page-go-to-orders-button {
  margin-right: 16px;
  display: flex;
  width: 340px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #1C8E47;
    border: none;
}

.pix-page-buttons a:not([href]):not([tabindex]) {
  color: #FFF;
  text-decoration: none;
}

.pix-page-buttons a:hover {
  text-decoration: none;
  color: white;
}

.pix-page-main-container {
  padding: 15px;
}

.pix-page-qrCode-payment {
  flex-direction: column;
  align-items: center;
}

.pix-page-payment-instructions-container {
  flex-direction: column;
  align-items: center;
  padding: 15px;

}

.pix-page-payment-instructions {
  align-items: center;
  text-align: center;
}

.pix-page-payment-instructions h2 {
  margin-bottom: 20px;
}

.pix-page-payment-instructions div {
  justify-content: center;
}

.pix-page-warnings {
  flex-direction: column;
}

.pix-page-warnings-expiration-time, .pix-page-warnings-copy-code {
  justify-content: center;
}

.pix-page-buttons {
  flex-direction: column;
  gap: 15px;
}

.pix-page-go-to-navigation-button, .pix-page-go-to-orders-button {
  width: 100%;
  max-width: 340px;
}

@media (min-width: 768px) {
  .pix-page-main-container {
    padding: 0px 50px;
  }

  .pix-page-qrCode-payment {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  
  }

  .pix-page-payment-instructions-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .pix-page-payment-instructions {
    align-items: flex-start;
    text-align: left;
  }

  .pix-page-warnings {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .pix-page-buttons {
    flex-direction: row;
    gap: 16px;
  }
}

@media (min-width: 1024px) {
  .pix-page-main-container {
    padding: 0px 104px;
  }
}

@media (max-width: 600px) {
  .pix-page-main-container {
    margin: 10px;
  }

  .pix-page-qrCode-payment {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 15px;
  }

  .pix-page-warnings-expiration-time, .pix-page-warnings-copy-code{
    max-width: 99%;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 200px;
  }

  .pix-page-warnings-expiration-time h2, .pix-page-warnings-copy-code h2{
    font-size: 16px;
    font-weight: 700;
    max-width: 300px;
  }

  .pix-page-warnings-expiration-time img, .pix-page-warnings-copy-code img{
    width: 70px;
    height: 70px;
    margin-bottom: 16px;
  }

  .pix-page-warnings-copy-code h6 {
    font-size: 16px;
  }

  .pix-page-payment-instructions-container {
    height: auto;
    max-width: 100%;
    
  }

  .pix-page-payment-instructions-container div {
    flex-direction: column;
  }

  .pix-page-buttons {
    font-size: 12px;
    margin-left: 10px;
  }

  .pix-page-buttons a {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .pix-page-image-container {
    order: 1;
  }

  .pix-page-qrCode-payment {
    margin: auto;
    padding: 0px 20px;
    margin-bottom: 32px;
  } 

  .pix-page-image-container  {
    height: auto;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .pix-page-image-container img {
    margin: 0px;
    height: auto;
    width: auto;
  }

  .pix-page-warnings  {
    order: 2;
    margin-right: 0;
    margin-bottom: 0;
    margin-right: 35px;
  }
  .pix-page-character-icon img{
    width: 250px;
    height: 250px;
  }
  
  .pix-page-payment-instructions-container {
    order: 3;
  }

  .pix-page-description {
    padding: 20px;
    text-align: start;
  }

  .pix-page-buttons {
    gap: 32px;
  }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
  .pix-page-main-container {
    padding: 20px 70px;
  }

  .pix-page-qrCode-payment {
    flex-direction: row;
    gap: 24px;
    margin: auto;
  }

  .pix-page-image-container {
    margin: auto;
  }

  .pix-page-payment-instructions-container {
    height: auto;
    flex-direction: column;
    margin: auto;
  }
  
  .pix-page-payment-instructions h2 {
    margin-bottom: 18px;
  }

  .pix-page-warnings {
    flex-direction: column;
    gap: 20px;
  }

  .pix-page-warnings-expiration-time, .pix-page-warnings-copy-code {
    flex-direction: row;
  }

  .pix-page-buttons {
    gap: 20px;
  }

  .pix-page-buttons a {
    font-size: 18px;
  }

  .pix-page-description {
    margin-bottom: 28px;
    font-size: 18px;
  }

  .pix-page-image-container img {
    width: 280px;
    height: 280px;
  }
}

@media (max-width: 767px) {
  .pix-page-qrCode-payment {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .pix-page-image-container, .pix-page-payment-instructions-container {
    width: 100%; 
  }
}


