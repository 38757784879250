.black-friday-landing-page-main-container {
  display: flex;
  gap: 16px;
  margin: 0 auto;
  padding: 40px 104px;
  padding-bottom: 0;
  max-width: 1440px;
  padding-top: 16px;
  flex-direction: column;
  box-sizing: border-box;
}

@media only screen and (max-width: 767px) {
  .black-friday-landing-page-main-container {
    padding: 20px 20px;
    padding-bottom: 0;
  }
}
