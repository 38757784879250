.resume-item-wrapper {
  color: #212121;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: .5rem;
  margin: 0;
}

.resume-item-image-section {
  width: 224px;
  height: auto;
  cursor: pointer;
  position: relative;
}

.resume-item-image-discount {
  position: absolute;
  width: 36px;
  height: 46px;
  right: 0;
  top: 0;
  clip-path: polygon(0 0, 100% 0%, 100% 65%, 50% 100%, 0 65%);
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Open Sans' !important;
  font-style: normal;
  color: #fff !important;
}

.resume-item-image-discount>p {
  margin: 0;
  padding: 0;
  font-weight: 600 !important;
  font-size: .875rem;
  line-height: 150%;
}

.resume-item-image-discount>span {
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
}

.resume-item-info-section {
  display: flex;
  align-items: flex-start;
  min-height: 100%;
  flex-direction: column;
}

.resume-item-variant {
  color: #212121;
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 !important;
}

.resume-item-link {
  transition: .5s ease-in-out;
  text-decoration: none !important;
}

.resume-item-link:hover {
  opacity: .6;
}

.resume-item-title {
  color: #212121;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resume-item-info-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.75rem 0;
}

.resume-item-info-row span {
  font-family: " Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  color: #1e1e1e;
}

.resume-item-info-row-quantity {
  padding: 0.25rem 1rem;
  border-radius: .5rem;
  border: 1px solid rgb(30, 30, 30);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1000px) {
  .resume-item-image-section {
    width: 150px;
    height: auto;
    cursor: pointer;
    position: relative;
  }

  .resume-item-title {
    font-size: .8rem;
  }

  .resume-item-info-row span {
    font-size: .7rem;
  }
}
