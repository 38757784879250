.button {
  border: none;
  width: 20vw;
  height: 51px;
  border-radius: 2px;
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.button-icon {
  height: 20px;
  width: 19px;
  padding-top: 0.2vh;
}

.button.white {
  background-color: #ce3c25;
  color: white;
}

.button.orange {
  background-color: #eb5b1c;
}

.button.white:hover {
  background-color: #e2e2e2;
  transition: 0.3s;
}

.button.orange:hover {
  background-color: #dd541a;
  transition: 0.3s;
}

.button:focus {
  outline: none;
  box-shadow: none;
}

@media screen and (max-width: 1100px) {
  .button {
    font-size: 16px;
  }
}

@media screen and (max-width: 800px) {
  .button {
    font-size: 14px;
    width: 40vw;
    height: 45px;
  }
}

@media screen and (max-width: 600px) {
  .button {
    font-size: 12px;
  }
}