.blog-post-page-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-post-page-hero {
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.blog-post-page-content {
  display: flex;
  flex-direction: column;
  width: 1200px;
  padding: 32px 122px;
  justify-content: center;
  flex-direction: column;
}

.blog-post-page-content > h2 {
  font-weight: 900;
  font-size: 26px;
  line-height: 160%;
  color: #ce3c25;
  margin-bottom: 46px;
}

.blog-post-page-Innerhtml > *,
.blog-post-page-Innerhtml > * > *,
.blog-post-page-Innerhtml > * > * > * {
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #000;
}

.blog-post-page-Innerhtml > h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 160%;
  color: #000;
}

.blog-post-page-Innerhtml > div > h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  color: #ce3c25;
}

.blog-post-page-Innerhtml > div > strong {
  font-weight: 600;
  color: #ce3c25;
}

.rich-text-embedded-image {
  margin: 8px 0px;
  object-fit: cover;
  max-width: 100%;
}

.attachment__caption {
  display: none;
}

.attachment--preview {
  display: flex;
  justify-content: center;
  text-align: center;
}

.blog-post-page-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  padding: 30px;
}

.blog-post-page-error > h3 {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 160%;
  margin-bottom: 16px;
}

.blog-post-page-error-button {
  background: #ce3c25;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  border: none;
  padding: 16px;
  color: #fff;
}

@media only screen and (max-width: 1440px) {
  .blog-post-page-content {
    width: 900px;
    padding: 32px 70px;
  }
}

@media only screen and (max-width: 1050px) {
  .blog-post-page-content {
    width: 700px;
    padding: 32px 70px;
  }
}

@media only screen and (max-width: 750px) {
  .blog-post-page-hero {
    height: 360px;
  }

  .blog-post-page-content {
    width: 100%;
    padding: 8px 51px;
    display: flex;
    flex-direction: column;
  }

  .blog-post-page-content > h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    color: #ce3c25;
    margin-bottom: 16px;
  }

  .blog-post-page-Innerhtml > div > h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    color: #ce3c25;
  }

  .blog-post-page-Innerhtml > h1 {
    font-weight: 500;
    color: #000;
    font-size: 32px;
    line-height: 150%;
  }

  .blog-post-page-Innerhtml > div {
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #000;
    width: 100%;
  }

  .blog-post-page-Innerhtml > div > strong {
    font-weight: 600;
    color: #ce3c25;
  }
}
