.my-account-info-page-main {
  padding-bottom: 5rem;
}


.my-account-page-container-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 104px;
  max-width: 1440px;
  margin-bottom: 4rem;
  gap: 1rem;
}

.my-account-page-container {
  width: 100%;
}

.my-account-page-pagination {
  background-color: #ededed;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  height: 30px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

@media only screen and (max-width: 750px) {
  .my-account-page-container-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 1440px;
  }

  .my-account-page-container {
    margin: 0;
  }
}
