.black-friday-questions-main-container {
  display: flex;
  gap: 16px;
  padding: 40px 0px;
  max-width: 1440px;
  padding-top: 16px;
  flex-direction: column;
  width: 100%;
}

.black-friday-quetions-text,
.black-friday-questions-orange-text {
  color: #1E1E1E;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 121.182%;
}

.black-friday-questions-orange-text {
  color: #CE3C25;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {

  .black-friday-quetions-text,
  .black-friday-questions-orange-text {
    display: none;
  }

  .black-friday-questions-main-container {
    padding-top: 0;
  }

}
