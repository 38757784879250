.restaurant-gap,
.restaurant-gap-alt {
  padding: 4rem 7.625rem;
}
.restaurant-main {
  font-family: 'Open sans', sans-serif;
}

.restaurant-banner-container,
.restaurant-banner-container-alt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-image: url('../images/restaurantBannerAlt.jpg');
  background-repeat: no-repeat;
}
.restaurant-banner-container {
  align-items: flex-start;
  background-image: url('../images/restaurantBanner.jpg');
}
.restaurant-banner-container-alt {
  min-height: 80vh;
}
.restaurant-banner,
.restaurant-banner-alt {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.restaurant-banner-img,
.restaurant-banner-img-alt {
  width: 20rem;
  height: 20rem;
}
.restaurant-banner-img {
  width: 50rem;
  height: auto;
}
.restaurant-banner-text,
.restaurant-banner-text-alt {
  font-size: min(4rem, 8vw);
  font-weight: 700;
  width: 50%;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.restaurant-banner-text {
  font-weight: 500;
  font-size: min(3.5rem, 8vw);
  width: 70%;

  font-family: 'Open sans', sans-serif;
}
.restaurant-banner-text-alt {
  width: 70%;
}
.restaurant-section-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  min-height: 70vh;
  background-color: #ff6c29;
}
.restaurant-section-time-img {
  width: 20rem;
  height: 20rem;
}
.restaurant-section-time-title {
  font-size: min(4rem, 8vw);
  font-weight: 700;
  width: 50%;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.restaurant-section-time-text {
  font-size: min(1.8rem, 7vw);
  text-align: center;
  width: 60%;
  color: #fff;
  font-weight: 500;
}

.restaurant-section-column {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 0.5rem;
}
.restaurant-section-column-item,
.restaurant-diff-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.restaurant-section-column-item-img {
  width: 7rem;
  height: 100%;
}
.restaurant-section-column-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: min(1.8rem, 7vw);
  text-align: center;
  color: #eb5b1e;
}
.restaurant-diff-section {
  gap: 4rem;
  justify-content: space-between;
  min-height: 100vh;
}
.restaurant-section-diff-title {
  font-size: min(3rem, 8vw);
  color: #4a4a4a;
  font-weight: 900;
  margin-bottom: 2rem;
  text-align: center;
}
.restaurant-section-diff-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
}
.restaurant-section-diff-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.restaurant-section-diff-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: min(1.5rem, 5vw);
  text-align: center;
  color: #454546;
}
.restaurant-section-diff-item-img-versus {
  width: 10rem;
  height: 10rem;
}
.restaurant-section-diff-item-img {
  width: 25rem;
  height: 22rem;
}
.restaurant-who-buys {
  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.restaurant-who-buys-img {
  width: 10rem;
  height: 10rem;
}

.restaurant-comment {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  flex-direction: column;
}
.restaurant-comment-title {
  color: #eb5b1e;
  font-size: min(1.8rem, 10vw);
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}
.restaurant-comment-img {
  width: 12rem;
  height: 8rem;
}
.restaurant-comment-text {
  font-size: min(1.5rem, 8vw);
  text-align: center;
  width: 55%;
}
.restaurant-comment-author {
  font-size: 1.5rem;
}
.restaurant-counter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  position: relative;
}
.restaurant-counter-container > .landing-increasing-user-counter {
  position: absolute;
  width: 50%;
  border-radius: 2rem;
  bottom: auto;
  top: -50px;
}
@media screen and (max-width: 1000px) {
  .restaurant-gap,
  .restaurant-gap-alt {
    padding: 1rem;
  }
  .restaurant-banner-img,
  .restaurant-banner-img-alt,
  .restaurant-section-time-img {
    width: 15rem;
    height: 15rem;
  }
  .restaurant-banner-container {
    min-height: 65vh;
  }
  .restaurant-section-time-title,
  .restaurant-section-time-text {
    width: 100%;
  }

  .restaurant-section-diff-text {
    width: 100%;
  }
  .restaurant-section-diff-row {
    flex-direction: column;
  }
  .restaurant-section-diff-item-img {
    width: 10rem;
    height: 9rem;
  }
  .restaurant-who-buys {
    flex-direction: column;
  }
  .restaurant-who-buys-img {
    width: 10rem;
    height: 9rem;
  }
  .restaurant-section-diff-item-img-versus {
    width: 6rem;
    height: 6rem;
  }
  .restaurant-comment-text {
    width: 100%;
  }
  .restaurant-section-time-text {
    width: 100%;
  }
  .restaurant-banner-text-alt,
  .restaurant-banner-text {
    width: 100%;
  }
  .restaurant-banner-img {
    width: 20rem;
    height: auto;
  }
  .restaurant-counter-container > .landing-increasing-user-counter {
    width: 90%;
  }
}
