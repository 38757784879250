.address-summary-main>p {
  font-size: 16px;
  margin: 0px !important;
  text-wrap: wrap;
  font-family: 'Open Sans', sans-serif;

}



@media only screen and (max-width: 500px
  /*SMALL_MEDIA_SIZE*/
) {
  .address-summary-main>p {
    font-size: 12px;
  }
}
