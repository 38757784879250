.departments-index-main {
  max-width: 1440px;
  padding: 0 104px 40px;
  margin: 0 auto;
}

.departments-index-flex-container {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  gap: 20px;
  padding: 10px;
  justify-content: space-between;
}

@media screen and (max-width: 1000px) {
  .departments-index-flex-container {
    grid-template-columns: repeat(3, max-content);
  }
}

@media screen and (max-width: 739px) {
  .departments-index-flex-container {
    grid-template-columns: repeat(2, max-content);
    gap: 10px;
  }
  .departments-index-main {
    padding: 0 16px;
  }
}
