@font-face {
    font-family: QUARTZO-Light;
    src: url('../../common/fonts/QUARTZO-light.ttf');
}

.referral-banner {
    background-image: url('../images/referral_banner.jpg');
    height: 0;
    padding: 0;
    padding-bottom: 25%;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.referral-description {
    max-height: 350px;
    width: 100%;
    background-image: url('../images/big_background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0 10%;
    margin: auto;
    align-items: center;
    justify-content: space-evenly;
    line-height: 36px;
}

.referral-description .phrase {
    padding: 4vw 0;
    width: 40%;
    margin: auto;
    height: 100%;
    font-family: QUARTZO-Light;
    color: #fff;
    display: flex;
    font-weight: 400;
    font-size: 36px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.referral-code-content {
    padding-top: 1.5vw;
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    font-size: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.referral-code-content .title {
    color: #ce3c25;
}

.referral-code-content .value {
    color: black;
    display: flex;
    justify-content: center;
}

.share-button {
    margin: auto;
    padding-top: 1vh;
}

.referral-share-content {
    padding-top: 15px;
    margin: auto;
    width: 44%;
    font-weight: 300;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
}

.referral-share-content .prize {
    color: #ce3c25;
    font-weight: 800;
    font-size: 30px;
}

.referral-total-earnings {
    color: #ce3c25;
    padding-top: 5pt;
    font-weight: 300;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
}

.referral-prize-explanation {
    padding-top: 1.5vw;
    padding-bottom: 3vw;
    margin: auto;
    width: 40%;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
}

.referral-tooltip {
    position: relative;
    display: inline-block;
    outline: 1px solid transparent;
}

.referral-tooltip a {
    color: black;
}

.referral-tooltip a:hover svg,
.referral-tooltip a:hover {
    fill: #ce3c25 !important;
    color: #ce3c25;
}

.referral-tooltip a:visited svg,
.referral-tooltip a:visited {
    fill: #ce3c25 !important;
}

.referral-tooltip .tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    bottom: 100%;
    left: 48%;
    margin-left: -70px;
    opacity: 0;
    transition: opacity 0.3s;
}

.referral-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.referral-tooltip:hover .tooltiptext {
    visibility: visible !important;
    opacity: 1 !important;
}

.tooltiptext {
    font-size: 12pt;
    font-weight: 400;
}

#share-tooltiptext {
    display: none;
}

@media only screen and (max-width: 912px) {
    .referral-description .phrase {
        width: 90%;
    }

    .referral-share-content {
        width: 80%;
    }

    .referral-prize-explanation {
        width: 80%;
    }
}

@media only screen and (max-width: 740px) {
    .referral-description {
        line-height: 24px;
    }

    .referral-description .phrase {
        width: 90%;
        font-size: 16px;
    }

    .referral-code-content {
        padding-top: 19px;
        font-size: 22px;
    }

    .referral-code-content .title {
        line-height: 1.2;
    }

    .referral-share-content {
        padding-top: 14px;
        width: 80%;
        font-size: 14px;
    }

    .referral-share-content .prize {
        font-size: 20px;
    }

    .referral-total-earnings {
        font-size: 14px;
    }

    .referral-prize-explanation {
        width: 80%;
        font-size: 14px;
    }
}