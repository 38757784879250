.big-club-preview {
  min-width: 310px;
  width: 100%;
  border-radius: 8px;
  position: relative;
  background-color: #F0F0F0;
}

.big-club-preview-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0.31rem 0.5rem;
  border-bottom: 2px solid rgba(30, 30, 30, 0.2);
}

.big-club-preview-header-border-none {
  border-bottom: none;
}

.big-club-preview-header-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
}

.big-club-preview-header-title span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
}

.big-club-preview-header-switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
  margin: 0;
}

.big-club-preview-header-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.big-club-preview-header-switch-slider-round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 1rem;
}

.big-club-preview-header-switch-slider-round:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 8px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.big-club-preview-header-switch-slider-round {
  background: #1c8e47;
}

input:focus+.big-club-preview-header-switch-slider-round {
  box-shadow: 0 0 1px #1c8e47;
}

input:checked+.big-club-preview-header-switch-slider-round:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.big-club-preview-row {
  padding: 0.31rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 2px solid rgba(30, 30, 30, 0.2);
}

.big-club-preview-row::after {
  position: absolute;
  width: 2px;
  height: 100%;
  background: rgba(30, 30, 30, 0.2);
  content: '';
  right: 38%;
}

.big-club-preview-row-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.big-club-preview-row-content-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(30, 30, 30, 0.8);
  margin-bottom: 0.125rem;
}

.big-club-preview-row-content-text-flex {
  display: flex;
  align-items: center;
  justify-self: center;
  min-width: fit-content;
}

.big-club-preview-row-content-wallet {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 19px;
  margin: 0;
  color: rgba(30, 30, 30, 0.8);
}

.big-club-preview-row-image {
  max-width: 11rem;
  width: 100%;
  height: auto;
}

.big-club-slider-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  gap: 2rem;
}

.big-club-slider-row .big-club-slider-row-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
  line-height: 19px;
  color: #1c8e47;
}

.big-club-preview-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  padding: 0 10px;
}

.big-club-preview-info-text {
  color: rgba(30, 30, 30, 0.8);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.big-club-preview-info-rules {
  color: rgba(30, 30, 30, 0.5);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.13px;
  text-decoration-line: underline;
}

.big-club-preview-info-rules:hover {
  color: rgba(30, 30, 30, 0.8);
}
