.referral-card-container {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 16px;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.referral-card-container-offline {
  justify-content: space-between;
}

.referral-card-text-section {
  display: flex;
  gap: 30px;
}

.referral-card-text-section h1 {
  color: #ce3c25;
  font-size: 48px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  margin: 0;
}

.referral-card-text-section p {
  color: #1e1e1e;
  font-size: 22px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: -0.22px;
  margin: 0;
}
.referral-card-text-section-title {
  width: 60%;
}

.referral-card-text-section-title-offline {
  width: 100%;
}

.referral-card-text-section-clipboard {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 2px solid rgba(30, 30, 30, 0.2);
  flex: 1 0 0;
  height: 100px;
  cursor: pointer;
}

.referral-card-text-section-clipboard h1 {
  font-size: 40px;
}
.referral-card-text-section-clipboard span {
  font-size: 18px;
}

.referral-card-balance-section {
  display: flex;
  gap: 128px;
}

.referral-card-balance-section > div {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.referral-card-balance-section-description {
  color: #1e1e1e;
  font-size: 22px;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}

.referral-card-balance-section h1 {
  font-size: 48px;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 120%;
}

.referral-card-balance-section-current > h1 {
  color: #1c8e47;
}

.referral-card-balance-section-accumulated > h1 {
  color: #ce3c25;
}

.referral-card-list-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.referral-card-list-section-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.referral-card-list-section-details-description {
  color: #1e1e1e;
  font-size: 24px;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.referral-card-list-section-details-action {
  color: #ce3c25;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-decoration: none;
}

.referral-card-list-section-content {
  display: flex;
  padding: 8px 0px;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow: hidden scroll;
  max-height: 250px;
}

.referral-card-list-section-content::-webkit-scrollbar {
  width: 6px;
}

.referral-card-list-section-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.referral-card-list-section-content::-webkit-scrollbar-thumb {
  background-color: #0000001f;
  border-radius: 6px;
}

.referral-card-list-section-content::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.referral-card-list-section-content::-webkit-scrollbar-corner {
  background-color: #f5f5f5;
  opacity: 0.5;
}

.referral-card-list-section-content-item {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 32px;
  justify-content: space-between;
  border-radius: 8px;
  border: 2px solid rgba(30, 30, 30, 0.2);
  min-width: 270px;
  color: #1e1e1e;
}

.referral-card-list-section-content-item-completed {
  background-color: #1c8e47;
  color: #fff;
  border-color: #1c8e47;
}

.referral-card-list-section-content-item-awaiting-delivery {
  background-color: #ccc;
  border-color: #ccc;
}

.referral-card-list-section-content-item-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  white-space: nowrap;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.referral-card-list-section-content-item-description :first-child {
  font-weight: 600;
}

.referral-card-list-section-content-item-status {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: -0.16px;
  text-align: center;
  font-weight: 600;
}

.referral-card-read-rules {
  margin: 0;
}

.referral-card-read-rules :only-child {
  color: rgba(30, 30, 30, 0.5);
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.14px;
  text-decoration-line: underline;
  transition: color 0.3s linear;
}

.referral-card-read-rules :only-child:hover {
  color: #eb5b1e;
}

@media screen and (max-width: 768px) {
  .referral-card-container {
    padding: 16px;
    gap: 16px;
  }
  .referral-card-text-section {
    flex-direction: column;
    gap: 16px;
  }
  .referral-card-text-section-title {
    width: 100%;
  }

  .referral-card-text-section h1 {
    font-size: 24px;
  }

  .referral-card-text-section p {
    font-size: 14px;
  }

  .referral-card-balance-section {
    gap: 8px;
  }
  .referral-card-balance-section > div {
    gap: 0;
  }
  .referral-card-balance-section-description {
    font-size: 16px;
  }
  .referral-card-balance-section h1 {
    font-size: 32px;
  }

  .referral-card-list-section-details-description {
    font-size: 16px;
  }

  .referral-card-list-section {
    gap: 16px;
  }

  .referral-card-list-section-content-item {
    min-width: 100%;
  }
  .referral-card-list-section-details-action {
    font-size: 12px;
  }

  .referral-card-list-section-content {
    flex-direction: column;

    align-self: center;
  }
}
