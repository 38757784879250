.barbecue-button {
  border: none;
  width: 25vw;
  height: 51px;

  border-radius: 2px;
  text-decoration: none;
  color: white;
  font-size: 16px;
}
.barbecue-button.white {
  background-color: #fff;
  color: #eb5b1c;
}
.barbecue-button.orange {
  background-color: #eb5b1c;
}
.barbecue-button.white:hover {
  background-color: #e2e2e2;
  transition: 0.3s;
}
.barbecue-button.orange:hover {
  background-color: #dd541a;
  transition: 0.3s;
}
.barbecue-button:focus {
  outline: none;
  box-shadow: none;
}
@media screen and (max-width: 1100px) {
  .barbecue-button {
    font-size: 20px;
  }
}

@media screen and (max-width: 800px) {
  .barbecue-button {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .barbecue-button {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .barbecue-button {
    font-size: 8px;
  }
}
@media screen and (max-width: 300px) {
  .barbecue-button {
    font-size: 6px;
  }
}
