.shipping-and-exchange-container {
  max-width: 1440px;
  padding: 0 104px;
  margin: 0 auto;
  margin-bottom: 80px;
  margin-top: 20px;
}

.shipping-and-exchange-article {
  margin-bottom: 1.5rem;
}

.shipping-and-exchange-article-title {
  font-weight: 700;
  color: #ce3c25;
}

.shipping-and-exchange-article-text {
  line-height: 1.6rem;
}

@media screen and (max-width: 768px) {
  .shipping-and-exchange-container {
    padding: 0 16px;
  }
}
