.big-club-container {
  max-width: 1440px;
  padding: 0 104px;
  padding-bottom: 40px;
  margin: 0 auto;
  display: flex;
  padding-bottom: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.big-club-offline {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 34.637px;
}

.big-club-offline-title {
  color: #1e1e1e;
  font-size: 34px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.big-club-offline-signin {
  border-radius: 8px;
  background-color: #ce3c25;
  padding: 17px 26px;
  cursor: pointer;
  color: #fff !important;
  border: none;
  outline: none;
  text-decoration: none !important;
}

.big-club-cards {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.big-club-cards-offline {
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .big-club-container {
    padding: 0 16px;
    padding-bottom: 40px;
  }
  .big-club-offline {
    flex-direction: column;
  }
  .big-club-offline-title {
    font-size: 28px;
  }
  .big-club-cards-offline {
    flex-direction: column;
  }
}
