.cart-big-club-preview {
  min-width: 310px;
  width: 100%;
  position: relative;
  border: 2px solid #1E1E1E33;
  border-radius: .5rem;
  margin: 0px 40px 30px 0px;
}

.cart-big-club-preview-row {
  padding: 0.31rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-align: center;
  padding: 16px;
}

.cart-big-club-preview-row::after {
  position: absolute;
  width: 2px;
  height: 100%;
  background: rgba(30, 30, 30, 0.2);
  content: '';
  right: 60%;
}

.cart-big-club-preview-row-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cart-big-club-preview-row-content-text {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(30, 30, 30, 0.8);
  margin-bottom: 0.125rem;
}

.cart-big-club-preview-row-content-text-flex {
  display: flex;
  align-items: center;
  justify-self: center;
  min-width: fit-content;
}

.cart-big-club-preview-row-content-wallet {
  font-family: "Open Sans" sans-serif;
  color: #1C8E47;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cart-big-club-preview-row-image {
  max-width: 11rem;
  width: 100%;
  height: auto;
  margin-right: 8px;
}

@media only screen and (max-width: 767px) {
  .cart-big-club-preview-row-image {
    padding-left: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .cart-big-club-preview-row-image {
    padding-left: 1rem;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1200px) {
  .cart-big-club-preview {
    background: none;
  }

  .cart-big-club-preview-row {
    margin: auto;
    background-color: #F0F0F0;
    justify-content: space-around;
  }
}

@media only screen and (min-device-width: 800px) and (max-device-width: 1280px) {
  .cart-big-club-preview {
    background: none;
  }

  .cart-big-club-preview-row {
    width: 100%;
    margin: auto;
    background-color: rgba(237, 242, 244, 0.60);
    border-radius: .5rem;
    justify-content: space-around;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 2224px) and (orientation: portrait) {
  .cart-big-club-preview-row {
    width: 100%;
    background-color: rgba(237, 242, 244, 0.60);
    border-radius: .5rem;
  }
}

@media screen and (max-width: 540px) and (max-height: 720px) {
  .cart-big-club-preview-row {
    margin: auto;
    background-color: rgba(237, 242, 244, 0.60);
    border-radius: .5rem;
    justify-content: space-around;
  }
}
