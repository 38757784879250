.bigclub-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 1rem 104px;
}

.bigclub-gap {
  padding: 1rem 0;
}

.bigclub-bg-grey {
  border-radius: 8px;
  background: #F6F6F6;
  padding: 1rem;
}

.bigclub-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}


.bigclub-main .bigclub-main-content-text h1 {
  margin: 0;
  font-size: 3rem;
  color: rgba(30, 30, 30, 0.80);
  font-style: normal;
  font-weight: 700;
  font-family: 'Open sans', sans-serif !important;
}

.bigclub-main .bigclub-main-content-text h2 {
  margin: 0;
  font-size: 3rem;
  color: rgba(30, 30, 30, 0.80);
  font-style: normal;
  font-weight: 700;
  font-family: 'Open sans', sans-serif !important;
}

.bigclub-main .bigclub-main-content-text h3 {
  margin: 0;
  font-size: 3rem;
  color: #CE3C25;
  font-style: normal;
  font-family: 'Open sans', sans-serif !important;
}

.bigclub-main .bigclub-main-content>h2 {
  color: #1E1E1E;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
}

.bigclub-main .bigclub-main-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.bigclub-main .bigclub-main-content img {
  width: 18rem;
  height: auto;
}

.bigclub-main-content-img img {
  width: 60%;
  height: auto;
}



.bigclub-main-content .bigclub-main-content-info {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  gap: 0.5rem;
  width: 100%;
}

.bigclub-main-content .bigclub-main-content-info p {
  color: #1E1E1E;
  font-family: "Open Sans", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 25.978px;
  letter-spacing: -0.195px;
  margin: .5rem 0;
}

.bigclub-main .bigclub-main-content-img {
  position: absolute;
  bottom: 0;
  right: 37px;
  width: 100%;
  display: flex;
  margin: 1rem 0;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.bigclub-main-content-alt {
  display: none;
}

.bigclub-main-content-secondary {
  min-height: 480px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
}

.bigclub-main-content-secondary .bigclub-main-content-secondary-img {
  width: 19rem;
  height: auto;
  position: absolute;
  bottom: 16px;
  z-index: -1;
}

.bigclub-main-content-secondary .bigclub-main-content-secondary-banner {
  width: 100%;
  height: auto;
}

.bigclub-button,
.bigclub-button-outline {
  display: flex;
  min-width: fit-content;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;
  background: #000;
  border-radius: .5rem;
  background: #CE3C25;
  text-decoration: none !important;
  color: #fff !important;
  font-weight: bold;
  cursor: pointer;
  transition: .5s ease-in-out;
}

.bigclub-button-outline {
  color: #CE3C25 !important;
  background: none !important;
  border: solid 1px #CE3C25;
}

.bigclub-button:hover,
.bigclub-button-outline:hover {
  opacity: .8;
}

.bigclub-benefits {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin: 1rem 0;
}

.bigclub-benefits .bigclub-benefit {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  flex-direction: column;
  text-align: center;
}

.bigclub-benefits .bigclub-benefit img {
  width: 3rem;
}

.bigclub-benefits .bigclub-benefit .bigclub-benefit-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}


.bigclub-benefits .bigclub-benefit .bigclub-benefit-content h3 {
  color: #212121;
  font-family: "Open Sans", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  margin: 0;
  font-weight: 700;
  line-height: 121.182%;
}

.bigclub-benefits .bigclub-benefit .bigclub-benefit-content p {
  color: #212121;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  margin: 0;
  font-weight: 400;
  line-height: 121.182%;
}

.bigclub-cashback {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.bigclub-cashback .bigclub-cashback-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.bigclub-cashback .bigclub-cashback-content .bigclub-cashback-title {
  color: rgba(30, 30, 30, 0.80);
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0 !important;
  text-align: center;
}

.bigclub-cashback .bigclub-cashback-content .bigclub-cashback-title b {
  color: #CE3C25;
}

.bigclub-cashback .bigclub-cashback-image {
  width: 14rem;
  height: auto;
}

.bigclub-cashback .bigclub-cashback-text {
  color: rgba(30, 30, 30, 0.80);
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  line-height: 121.182%;
  text-align: center;
}

.bigclub-invite {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column-reverse;
  text-align: center;
  gap: 2.5rem;
  margin-bottom: 3.75rem;

}

.bigclub-invite-title {
  color: rgba(30, 30, 30, 0.80);
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.bigclub-invite-title p {
  color: #CE3C25;
}

.bigclub-invite .bigclub-invite-text {
  color: rgba(30, 30, 30, 0.80);
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  margin: 0;
}

.bigclub-invite-img {
  max-width: 37.5rem;
  width: 100%;
  height: auto;
}

.bigclub-banner-shipping,
.bigclub-fidelity-banner {
  width: 100%;
  height: auto;
  margin: 1rem 0;
  display: none;
}

.bigclub-banner-shipping {
  margin: 5rem 0;
}


.bigclub-banner-shipping-alt {
  width: 100%;
  height: auto;
  margin: 1.75rem 0;
}

.bigclub-faq-title {
  text-align: center;
  color: #212121;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 2rem 0;
}

.bigclub-faq-title span {
  color: #CE3C25;
  font-weight: 700;
}

.bigclub-announcement {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 3rem 0;
  padding-bottom: 7.5rem;
  flex-direction: column;
}

.bigclub-announcement .bigclub-announcement-text {
  text-align: center;
  color: rgba(30, 30, 30, 0.80);
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  width: 100%;
  line-height: 120%;
  margin: 1rem 0;
}

.bigclub-announcement .bigclub-announcement-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
}

.bigclub-main-content-text-principal {
  display: flex;
  position: relative;
  flex-direction: column;
}

.bigclub-main-content-text-principal img {
  position: absolute;
  bottom: 0;
  right: 25px;
}

.bigclub-promotion {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem 0;
  width: 100%;
  gap: 2rem;
}

.bigclub-promotion .bigclub-promotion-title {
  color: rgba(30, 30, 30, 0.80);
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.bigclub-promotion .bigclub-promotion-title>p {
  color: #CE3C25;
}

.bigclub-promotion .bigclub-promotion-text {
  color: rgba(30, 30, 30, 0.80);
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  margin: 0;
  text-align: center;
}

.bigclub-promotion .bigclub-promotion-img {
  width: 20rem;
  height: auto;
}

@media screen and (max-width: 768px) {
  .bigclub-wrapper {
    padding: 2.5rem 1rem;
  }

}


@media screen and (min-width:1000px) {
  .bigclub-main {
    min-height: 48vh;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2rem;
    width: 100%;
  }

  .bigclub-main .bigclub-main-content img {
    width: 20rem;
    height: auto;
  }

  .bigclub-main-content-alt {
    display: block;
  }

  .bigclub-main .bigclub-main-content-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }




  .bigclub-main .bigclub-main-content-text h1 {
    margin: 0;
    font-size: 3.75rem;
    color: rgba(30, 30, 30, 0.80);
    font-style: normal;
    font-weight: 700;
    font-family: 'Open sans', sans-serif !important;
  }

  .bigclub-main .bigclub-main-content-text h2 {
    margin: 0;
    font-size: 3.75rem;
    color: rgba(30, 30, 30, 0.80);
    font-style: normal;
    font-weight: 700;
    font-family: 'Open sans', sans-serif !important;
  }

  .bigclub-main .bigclub-main-content-text h3 {
    margin: 0;
    font-size: 5rem;
    color: #CE3C25;
    font-style: normal;
    font-family: 'Open sans', sans-serif !important;
  }

  .bigclub-main .bigclub-main-content>h2 {
    color: #1E1E1E;
    font-family: "Open Sans", sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
  }

  .bigclub-main .bigclub-main-content {
    align-items: flex-start;
    text-align: left;
  }

  .bigclub-main-content-img {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
  }

  .bigclub-main-content-alt {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .bigclub-main .bigclub-main-content-alt img {
    width: 23rem;
    height: auto;
    left: 47px;
    position: absolute;
    right: 0;
    bottom: -100px;
    z-index: -1;
  }

  .bigclub-main-content-secondary {
    min-height: 72vh;
    display: none;
  }

  .bigclub-main-content-secondary .bigclub-main-content-secondary-img {
    width: 100%;
    position: absolute;
    bottom: -176px;
  }

  .bigclub-main-content .bigclub-main-content-info {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  .bigclub-benefits {
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;
  }

  .bigclub-benefits .bigclub-benefit {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: left;
  }

  .bigclub-cashback {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  .bigclub-cashback .bigclub-cashback-content .bigclub-cashback-title {
    font-size: 3.5rem;
    text-align: left;
  }

  .bigclub-cashback .bigclub-cashback-text {
    font-size: 1.5rem;
    text-align: left;

  }

  .bigclub-invite {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .bigclub-invite-title {
    color: rgba(30, 30, 30, 0.80);
    font-family: "Open Sans", sans-serif;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: left;
  }

  .bigclub-invite .bigclub-invite-text {
    color: rgba(30, 30, 30, 0.80);
    font-family: "Open Sans", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    margin: 0;
    text-align: left;
  }

  .bigclub-promotion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
    flex-direction: row;
    width: 100%;
  }

  .bigclub-promotion .bigclub-promotion-title {
    color: rgba(30, 30, 30, 0.80);
    font-family: "Open Sans", sans-serif;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: left;
  }

  .bigclub-promotion .bigclub-promotion-title>p {
    color: #CE3C25;
  }

  .bigclub-promotion .bigclub-promotion-text {
    color: rgba(30, 30, 30, 0.80);
    font-family: "Open Sans", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    margin: 0;
    text-align: left;
  }

  .bigclub-promotion .bigclub-promotion-img {
    width: 30rem;
  }

  .bigclub-faq-title {
    text-align: center;
    color: #212121;
    font-family: "Open Sans", sans-serif;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 2rem 0;
  }

  .bigclub-fidelity-banner {
    display: block;
  }

  .bigclub-banner-shipping {
    display: block;
  }

  .bigclub-banner-shipping-alt {
    display: none;
  }

  .bigclub-announcement .bigclub-announcement-text {
    font-size: 2rem;
    width: 60%;
  }

  .bigclub-announcement .bigclub-announcement-actions {
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .bigclub-main-content-text-principal img {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@media screen and (min-width: 1000px) {
  .bigclub-main .bigclub-main-content-alt img {
    width: 23rem;
    height: auto;
    left: 47px;
    position: absolute;
    right: 0;
    bottom: -150px;
    z-index: -1;
  }
}
