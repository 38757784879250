.info-cards {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
}

.info-cards .info-card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-right: 32px;
}
.info-cards .info-card img {
  width: 1.75rem;
  height: fit-content;
  display: block;
}
.info-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
}
.info-card-content .info-card-content-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 22px;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}
.info-card-content .info-card-content-title span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0 !important;
  padding: 0 !important;
}

@media screen and (max-width: 1366px) {
  .info-card {
    margin-right: 16px !important;
  }
}

@media screen and (max-width: 800px) {
  .info-cards {
    justify-content: initial;
    padding: 0.5rem 1rem;
  }
  .info-card {
    margin-right: 8px;
  }
}
