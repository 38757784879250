.add-after-exists-button-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ce3c25;
  width: 127px;
  height: 40px;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-after-exists-loader {
  position: absolute;
  top: 8px;
  right: -25px;
  border: 0px solid #fff;
}
.add-after-exists-button-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  width: 68px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  position: relative;
}

.add-after-exists-button-content-value {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: #ce3c25;
  margin: 0;
  padding: 0;
}

.add-after-exists-button-content-variant {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: rgba(30, 30, 30, 0.8);
  position: absolute;
  right: 4px;
  margin: 0;
  padding: 0;
}

.add-after-exists-button-main > button {
  color: #fff !important;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: auto;
  height: 100%;
  border: none;

  background-color: transparent;
}

.add-after-exists-button-main > button > i {
  font-size: 13px;
}

.add-after-exists-button-main > button:hover {
  cursor: pointer;
}
.add-after-exists-button-main > button:focus {
  outline: none;
}

.add-after-exists-button-main > button:disabled {
  cursor: default;
}

.add-after-exists-button-main > button:disabled > i {
  color: #ffffff78;
}

@media screen and (max-width: 768px) {
  .add-after-exists-button-main {
    width: 91px;
    height: 32px;
  }

  .add-after-exists-button-content {
    padding: 0px 4px;
    width: 47px;
    height: 20px;
  }

  .add-after-exists-button-content-value {
    font-size: 10px;
    line-height: 24px;
  }
  .add-after-exists-button-content-variant {
    font-size: 6px;
    line-height: 24px;
    right: 2px;
  }
}
