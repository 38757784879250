.search-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.search-input {
  border: none;
  outline: none !important;
  border: 1px solid rgba(30, 30, 30, 0.5);
  border-radius: .5rem;
  width: 100%;
  padding: .5rem;
}

.search-button {
  border: none !important;
  outline: none !important;
  margin: 0 !important;
  cursor: pointer;
  border-radius: 0px .5rem .5rem 0px;
  background: #CE3C25;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem 1rem;
  transition: .5s ease-in-out;
  color: #fff;
}

.search-button:hover {
  opacity: .7;
}

.search-button img {
  width: 1.5rem;
  height: 1.5rem;
}
