.delivery-picker-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: 1rem 0;
  width: 100%;
}

.delivery-picker-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: flex-start;
}

.delivery-picker-section-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.delivery-picker-day {
  width: 4.5rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0.3, 0.3, 0.3, 0.5);
  padding: 0.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.delivery-picker-day.selected {
  border: 1px solid #eb5b1e;
}

.delivery-picker-day:hover {
  opacity: 0.6;
}

.delivery-picker-day-title {
  font-size: 0.875rem;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #1e1e1e;
}

.delivery-picker-day-title.selected {
  color: #eb5b1e;
}

.delivery-picker-day-text {
  font-size: 0.75rem;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #1e1e1e;
}

.delivery-picker-day-text.selected {
  color: #eb5b1e;
}

.delivery-picker-option-container {
  width: 100%;
}

.delivery-picker-option-container-disabled {
  pointer-events: none;
}

@media screen and (max-width: 1000px) {
  .delivery-picker-section {
    align-items: center;
    flex-wrap: initial;
    padding: 0 0.5rem;
    gap: 0.5rem;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    -ms-overflow-style: none;
  }

  .delivery-picker-section::-webkit-scrollbar {
    width: 0;
  }
}
