.my-account-password-main {
  display: flex;
  flex-direction: column;
}

.my-account-password-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.my-account-password-header>h3 {
  color: #1E1E1E;
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.24px;
  margin-bottom: 0;
}

.my-account-password-main>form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.my-account-password-large-input {
  background-color: #fff;
  text-indent: 15px;
  border: 1px solid rgba(30, 30, 30, 0.50);
  border-radius: 8px;
  height: 43px;
  margin-bottom: 10px;
  width: 100%;
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.18px;
  padding: .5rem;
  outline: none;
}

.my-account-password-large-input::placeholder {
  color: rgba(30, 30, 30, 0.50);
}

.my-account-password-large-input:focus {
  outline: none;
}

.my-account-password-submit-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 5px;
}

.my-account-password-submit-button {
  width: 100%;
}

.my-account-password-forgot-password {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.my-account-password-forgot-password>a {
  color: #EB5B1E;
  font-feature-settings: 'calt' off;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.18px;
}

.my-account-password-forgot-password>a:hover {
  color: #EB5B1E;
  text-decoration: none;
}

.account-password-strength-text {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  float: right;
  line-height: 15px;
  color: #4a4a4a;
}

.account-password-strength-strong {
  color: #59b259;
  font-weight: bold;
}

.account-password-strength-medium {
  color: #e77600;
  font-weight: bold;
}

.account-password-strength-poor {
  color: #d0021b;
  font-weight: bold;
}

.my-account-password-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  gap: .5rem;
}

.my-account-password-close-button {
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
}

.my-account-password-close-button:focus,
.my-account-password-close-button:active {
  outline: none;
}


@media only screen and (max-width: 750px
  /*SMALL_MEDIA_SIZE*/
) {
  .my-account-password-large-input {
    font-size: 16px;
    width: 100%;
  }

  .my-account-password-form {
    width: 100%;
  }
}
