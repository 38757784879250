.pet-content {
  font-family: 'Open sans', sans-serif;
}
.pet-gap,
.pet-gap-alt {
  padding: 4rem 7.625rem;
}

.pet-gap-alt {
  padding: 4rem 7.625rem 0px 7.625rem;
}
.pet-banner {
  height: 30.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../images/petBanner.png');
}
.pet-banner-alt {
  height: 30.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../images/petBannerAlt.jpg');
}
.pet-banner-alt .pet-banner-text {
  width: 50%;
}
.pet-banner-text {
  font-size: min(4rem, 8vw);
  font-weight: 700;
  width: 30%;
  line-height: 3.9rem;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.pet-banner-text > span {
  color: #ffd888;
  font-size: min(5rem, 10vw);
}
.pet-banner-first {
  width: 40px;
  height: 40px;
  background-color: yellow;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.pet-banner-container {
  width: 100%;
  position: relative;
}
.pet-benefits {
  background-color: #ff6c29;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.pet-benefits-title {
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: min(4rem, 8vw);
  text-align: center;
  color: #fff;
  margin-bottom: 2rem;
  width: 65%;
}
.pet-benefits-title > span {
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: min(5.5rem, 9vw);
  text-align: center;
  color: #ffd888;
  margin-bottom: 2rem;
}
.pet-benefits-grid {
  margin-top: 4rem;
  display: grid;
  gap: 0.5rem;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.pet-benefits-grid:nth-last-child() .pet-benefits-grid-item-text {
  width: 70%;
  background-color: #333;
}
.pet-benefits-grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
.pet-benefits-grid-item-img {
  width: 6rem;
  height: 6rem;
}
.pet-benefits-grid-item-text {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  font-family: 'Open sans', sans-serif;
}

.pet-benefits-row {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.pet-benefits-row-img {
  height: 19.75rem;
  width: 37.5rem;
}
.pet-comment-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  min-height: 100vh;
}
.pet-comment-section-text {
  font-size: min(1.4rem, 7vw);
  text-align: center;
  width: 50%;
}
.pet-comment {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}
.pet-comment-title {
  color: #eb5b1e;
  font-size: min(1.5rem, 10vw);
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}
.pet-comment-img {
  width: 12rem;
  height: 8rem;
}
.pet-comment-text {
  font-size: min(1.4rem, 7vw);
  text-align: center;
  width: 50%;
}
.pet-comment-author {
  font-size: 1.5rem;
}
.pet-icon-column {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}
.pet-icon-column-item {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 0.5rem;
}
.pet-icon-column-item-img {
  width: 7rem;
  height: 100%;
}
.pet-icon-column-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: min(1.2rem, 6vw);
  text-align: center;
  color: #eb5b1e;
}
@media screen and (max-width: 1000px) {
  .pet-benefits-title {
    width: 100%;
  }
  .pet-banner-text {
    width: 70%;
    line-height: 12vw;
  }
  .pet-gap {
    padding: 1rem;
  }
  .pet-benefits-grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }
  .pet-comment-text {
    width: 100%;
  }
  .pet-comment-section-text {
    width: 100%;
  }
  .pet-gap-alt {
    padding: 1rem 1rem 0 1rem;
  }
  .pet-benefits-row-img {
    height: 10.75rem;
    width: 18.5rem;
    margin-top: 2rem;
  }
}
