.image-list-control {
  width: 300px;
  display: flex;
  justify-content: center;
  height: 100%;
}

.image-list-main {
  margin: 0px 15px 0px 0px;
}

@media only screen and (max-width: 500px
  /*EXTRA_SMALL_MEDIA_SIZE*/
) {
  .image-list-control {
    width: 200px;
  }

  .image-list-main {
    max-width: 300px;
  }
}
