.cart-indicator-button-container {
  text-decoration: none;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
}
.cart-indicator-button-container:hover {
  filter: opacity(0.7);
}
.cart-indicator-item {
  display: flex;
  flex-direction: column;
}
.cart-indicator-item .cart-indicator-item-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
}
.cart-indicator-item .cart-indicator-item-text,
.cart-indicator-item-text-alt {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0;
}
.cart-indicator-item .cart-indicator-item-text-alt {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.cart-indicator-item
  .cart-indicator-item-text-alt
  .cart-indicator-item-text-alt-quantity {
  color: #ce3c25;
  font-weight: 500;
}
.cart-indicator-button-container:hover {
  text-decoration: none;
}

.cart-indicator-button-icon {
  height: 2rem;
  width: 2rem;
  background-image: url('../images/shopping_cart.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.cart-indicator-spinner {
  color: #ce3c25;
  text-align: center;
  font-size: 12px;
  width: 14px;
  height: 14px;
  line-height: 14px;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .cart-indicator-item {
    display: none;
  }
}

@media only screen and (max-width: 210px /*EXTRA_SMALL_MEDIA_SIZE*/) {
  .cart-indicator-button-container {
    margin-left: 0px;
  }
}
