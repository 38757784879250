.cart-item-preview-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cart-item-preview-product-left-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.cart-item-preview-image-container {
  position: relative;
}

.cart-item-preview-image-container img {
  width: 100px;
  height: 67px;
}

.cart-item-preview-product-quantity {
  background-color: #EB5B1E;
  padding: 4px 6px;
  color: #fff;
  border-radius: 10px;
  position: absolute;
  top: -10px;
  right: -10px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.1);
}

.cart-item-preview-product-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 40%;
}

.cart-item-preview-product-content p {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  color: #212121;
  margin-bottom: 0 !important;
}

.cart-item-preview-product-content h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #212121;
  margin-bottom: 0 !important;
}

.cart-item-preview-product-price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-item-preview-product-price-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
}

.cart-item-preview-product-price-main>h6 {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-decoration: line-through;
  color: rgba(30, 30, 30, 0.5);
  margin: 0;
  padding: 0;
}

.cart-item-preview-product-price-main>h5 {
  color: #212121 !important;
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
