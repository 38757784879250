.invited-user-list-main {
    width: 70%;
    margin: auto;
    padding-top: 0.3vw;
}

@media only screen and (max-width: 920px) {
    .invited-user-list-main {
        width: 80%;
    }
}

@media only screen and (max-width: 540px) {
    .invited-user-list-main {
        padding-top: 3vw;
        width: 80%;
    }
}