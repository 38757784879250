.landing-simple-section {
  padding: 4.375rem 0 2.5rem 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-simple-section-title {
  font-size: min(2.625rem, 9vw);
  font-weight: 700;
  width: 30%;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.landing-simple-section-title > span {
  font-size: min(3rem, 10vw);
  color: #fdd532;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.landing-simple-section-subtitle {
  font-size: min(1.625rem, 7vw);
  font-family: 'Open sans', sans-serif;
  font-weight: 500;
  width: 200px;
  line-height: 2rem;
  text-align: center;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

@media screen and (max-width: 800px) {
  .landing-simple-section-title {
    width: 84%;
  }

  .landing-simple-section-subtitle {
    width: 100%;
    font-size: min(1.4rem, 7vw);
  }

  .landing-simple-section-banner {
    height: 13.875rem;
  }
}
