.big-club-banner-content {
  display: flex;
  height: 384px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 16px;
  background: linear-gradient(90deg, #ce3c25 0%, #eb5b1e 57.81%);
}

.big-club-banner-description {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
}

.big-club-banner-description > h1 {
  font-size: 87px;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}

.big-club-banner-description > p {
  font-size: 30px;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}

.big-club-banner-description > div {
  width: 2.506px;
  height: 60.152px;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .big-club-banner-content {
    height: 136px;
    padding: 10px 16px;
  }
  .big-club-banner-description > h1 {
    font-size: 39px;
  }
  .big-club-banner-description > p {
    font-size: 13px;
  }
  .big-club-banner-description > div {
    width: 1px;
    height: 26px;
  }
}
