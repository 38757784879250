.color-row-main {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  width: 100%;
  text-align: center;
  padding: 5px 5px;
  margin-bottom: 10px;
}

.color-row-red {
  background-color: #ce3c25;
}

.color-row-orange {
  background-color: #ec5a1d;
}

.color-row-black {
  background-color: #212121;
}

.color-row-grey {
  background-color: #ededed;
  color: #212121;
}

/*#2eb411*/
/*#31cd25*/
.color-row-green {
  background-color: #2eb411;
}

@media only screen and (max-width: 750px) {
  .color-row-main {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .color-row-main {
    font-size: 16px;
    padding: 10px 10px;
    height: 100%;
  }
}