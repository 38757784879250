.address-pane-checkout-continue-button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
  border: 2px solid #1e1e1e33 !important;
  border-radius: .5rem;
  outline: none !important;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  gap: .2rem;
  font-weight: 700;
  font-size: 1.5rem;
  color: #1E1E1E66;
  padding: 1rem;
}

@media screen and (max-width:800px) {
  .address-pane-checkout-continue-button {
    height: 222px;
  }
}
