.kits-footer-banner-container {
  display: flex;
  flex-direction: row;
}

.kits-footer-banner-content-container {
  width: 100%;
  border-radius: 1rem 0 0 1rem;
  background: url('../images/kits-footer-background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.kits-footer-banner-text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  padding: 1rem 2rem;
  height: 80%;

}

.kits-footer-banner-title {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  color: #FFFFFF;
}

.kits-footer-banner-text {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  color: #FFFFFF;
}

.kits-footer-banner-link {
  background-color: #CE3C25;
  width: 100% !important;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  border-radius: 0 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kits-footer-banner-link a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF !important;
  text-decoration: none;
  font-family: "Overpass", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30.38px;
  color: #FFFFFF;
  width: 100%;
  height: 100%;
}

.kits-footer-banner-link a:hover {
  color: #FFFFFF !important;
  text-decoration: none;
}

.kits-footer-banner-video {
  height: 350px;
  width: 100%;
  object-fit: cover;
  border-radius: 0 1rem 1rem 0;
}

@media only screen and (max-width: 767px) {
  .kits-footer-banner-container {
    flex-direction: column-reverse;
  }

  .kits-footer-banner-content-container {
    background: url('../images/kits-footer-mobile-background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
  }

  .kits-footer-banner-content-container,
  .kits-footer-banner-link {
    border-radius: 0 0 1rem 1rem;
  }

  .kits-footer-banner-link {
    padding: 1rem;
  }

  .kits-footer-banner-video {
    border-radius: 1rem 1rem 0rem 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .kits-footer-banner-container {
    flex-direction: column-reverse;
  }


  .kits-footer-banner-content-container {
    width: 100%;
  }

  .kits-footer-banner-content-container,
  .kits-footer-banner-link {
    border-radius: 0 0 1rem 1rem;
  }

  .kits-footer-banner-link {
    padding: 1rem;
  }

  .kits-footer-banner-video {
    border-radius: 1rem 1rem 0rem 0;
  }
}


@media only screen and (min-width: 1024px) and (max-width: 1300px) and (orientation: landscape) {
  .kits-footer-banner-content-container {
    width: auto;
  }
}
