.home-container {
  max-width: 1440px;
  padding: 0 104px;
  margin: 0 auto;
}

.banners-loading {
  width: 100%;
  height: 30vh;
}

.banners-loading i {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  font-size: 50px;
  color: #ce3c25;
}

@media screen and (max-width: 768px) {
  .home-container {
    padding: 0;
  }
}
