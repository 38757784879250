.credit-card-option-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.credit-cart-option-information-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
}

.credit-card-option-main {
  color: #474747;
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  margin-bottom: 10px;
  padding: 1rem;
  cursor: pointer;
}

.credit-card-selected {
  border: 1px solid #EB5B1E;
}

.credit-card-expired {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  cursor: default;
}

.credit-card-option-image {
  width: 88px;
  height: 88px;
}

.credit-card-option-information {
  display: flex;
  flex-direction: column;
}

.credit-card-option-information>p {
  margin: 0px;
  display: flex;

  color: #1E1E1E;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.credit-card-option-information>p:nth-of-type(2) {
  font-feature-settings: 'calt' off;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
}

.credit-card-option-information>p>span {
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 20px;
  color: #721c24;
  margin-left: 5px;
}

.credit-card-spinner {
  margin-right: 20px;
  margin-left: 45px;
}

.credit-card-option-trash {
  border: none !important;
  outline: none !important;
  border-radius: 8px;
  border: 1px solid #CE3C25 !important;
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  padding: .5rem .8rem .5rem .8rem;
  transition: .5s ease-in-out;
  gap: .5rem;

  color: #CE3C25;
  font-feature-settings: 'calt' off;

  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.14px;
  margin: 0;
}

.credit-card-option-trash img {
  transition: .5s ease-in-out;
}

.credit-card-option-trash:hover img {
  filter: brightness(100);
}

.credit-card-option-trash:hover {
  cursor: pointer;
  background-color: #CE3C25 !important;
  color: #fff !important;

}

@media only screen and (max-width: 600px) {
  .credit-card-option-main {
    flex-direction: column;
    height: auto;
  }

  .credit-card-option-trash {
    font-size: 14px;
    font-weight: 700;

  }
}

@media only screen and (max-width: 450px) {
  .credit-card-option-image {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 375px) {
  .credit-card-option-image {
    display: none;
  }
}
