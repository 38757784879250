.add-button {
  padding: 8px 12px;
  width: 127px;
  height: 40px;
  left: 20px;
  top: 20px;
  background: #ce3c25;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-button:active,
.add-button:focus {
  border: none;
  outline: none;
}

.add-button-text {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.add-button-text>p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

.add-button i {
  width: 13px;
  height: 13px;
  font-size: 13px;
  color: #ce3c25;
}

.add-button:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .add-button {
    padding: 4px 8px;
    gap: 2px;
    width: 91px;
    height: 32px;
  }

  .add-button-text>p {
    font-size: 10px;
    line-height: 24px;
  }
}
