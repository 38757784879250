.blog-anchor-card-container {
  width: 276px;
  height: 270px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 8px;
  border-radius: 8px;
  border: 1px solid #1e1e1e80;
}

.blog-anchor-card-image {
  height: 160px;

  border-radius: 8px;
  object-fit: cover;
}

.blog-anchor-card-title {
  margin: 0;

  font-family: 'Open Sans';
  flex-grow: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #1e1e1e;

  display: -webkit-box;
  overflow: hidden;
  height: 44px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-anchor-card-description {
  margin: 0;

  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #1e1e1e;

  display: -webkit-box;
  overflow: hidden;
  height: 37px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: 750px) {
  .blog-anchor-card-container {
    width: 160px;
    height: 147px;
  }
  .blog-anchor-card-image {
    height: 76px;
  }
  .blog-anchor-card-title {
    font-size: 10px;
    line-height: 13.62px;
    height: 28px;
  }
  .blog-anchor-card-description {
    font-size: 8px;
    line-height: 10.89px;
    height: 21px;
  }
}
