.page-loader {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;

  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 60px 0px 60px 0px;
}

.page-sub-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.page-loader-text {
  color: #eb5b1e;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 10px;
}

.page-loader-container {
  position: relative;
  height: 70px;
  /* This container must have a set height and width for proper alignment*/
  width: 85px;
}

.page-loader-image {
  width: auto;
  height: 100%;
}

.page-loader-animate {
  animation: MoveUpDown 1s ease-in-out infinite;
  position: absolute;
  left: 0;
  bottom: 0px;
}

@keyframes MoveUpDown {
  0% {
    bottom: 0;
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(2deg);
  }

  50% {
    bottom: 5px;
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-2deg);
  }

  100% {
    bottom: 0;
    transform: rotate(0deg);
  }
}