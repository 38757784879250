.referral-invited-user-item {
    margin: 0 1px !important;
    background-color: #efefef;
    font-family: "Roboto";
    font-weight: 400;
    min-height: 80px;
    border-radius: 5px;
    font-size: 16px;
}

.referral-invited-user-item.finished {
    color: white;
    background-color: #ce3c25;
}

.referral-user-date-and-name {
    height: 100%;
    text-align: left;
    vertical-align: baseline;
}

.referral-user-date-and-name .creator-name {
    padding-top: 0.2vw;
    font-weight: 800;
}

.referral-user-status {
    font-size: 14px;
    text-align: center;
}

@media only screen and (max-width: 540px) {
    .referral-invited-user-item {
        min-height: 60px;
        padding: 0;
    }

    .referral-user-date-and-name {
        padding-right: 0 !important;
        padding-top: 4px;
        width: 100%;
        font-size: 18px;
    }

    .referral-user-status {
        font-size: 14px;
    }

    .referral-user-date-and-name .creator-name {
        padding-top: 3pt;
    }
}

@media only screen and (max-width: 393px) {
    .referral-user-date-and-name {
        font-size: 16px;
    }
}