.product-info-cards {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  width: 80%;
}

.product-info-cards .product-info-card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 0 !important;
}
.product-info-cards .product-info-card img {
  width: 1.75rem;
  height: fit-content;
  display: block;
}
.product-info-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
}
.product-info-card-content .product-info-card-content-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 22px;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}
.product-info-card-content .product-info-card-content-title span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0 !important;
  padding: 0 !important;
}

@media screen and (max-width: 1330px) {
  .product-info-cards {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .product-info-cards {
    padding: 0;
    gap: 8px;
  }
}
