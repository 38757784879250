.check-out-page-container {
  width: 100%;
  min-height: 60vh;

}

.check-out-page-main {
  max-width: 1440px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  margin: 0 auto;
  padding: 40px 104px;
}

.check-out-page-pane {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.check-out-page-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width: 1000px) {
  .check-out-page-main {
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .check-out-page-column {
    width: 100%;
  }
}
