.form-control-input-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  position: relative;
}


.form-control-input-wrapper .form-control-input-label {
  position: absolute;
  pointer-events: none;
  transition: 0.5s ease all;
  left: 0;
  top: 10px;
}

.form-control-input-wrapper .form-control-input-rounded-label {
  position: absolute;
  pointer-events: none;
  transition: 0.5s ease all;
  left: 5px;
  top: 18px;
  color: rgba(30, 30, 30, 0.50);
  padding-left: 5px;
}

.form-control-input-wrapper .form-control-input-label span {
  color: #ce3c25;
}

.form-control-input,
.form-control-input-invalid,
.form-control-input-rounded {
  border: none;
  outline: none !important;
  border-bottom: 1px solid rgba(30, 30, 30, 0.5);
  border-radius: 1px;
  width: 100%;
}

.form-control-input-rounded {
  border: none;
  outline: none !important;
  border: 1px solid rgba(30, 30, 30, 0.5);
  border-radius: 8px;
  width: 100%;
  padding: 8px;
}


.form-control-input::placeholder {
  background: transparent;
}

.form-control-input:focus~label,
.form-control-input:valid~label,
.form-control-input:-webkit-autofill~label {
  top: -5px;
  font-size: 12px;
}


.form-control-input-rounded:focus~label,
.form-control-input-rounded:valid~label,
.form-control-input-rounded:-webkit-autofill~label {
  top: -10px;
  font-size: 12px;
  color: black;
}


.form-control-input-invalid {
  border-bottom: 1px solid #ce3c25;
}

.form-control-input-rounded-invalid {
  border: 1px solid #ce3c25;
}

.form-control-input-message {
  color: #ce3c25;
  font-size: 10px;
}

.form-control-input-message-animation {
  -webkit-animation-name: formControlInputMessageAnimation;
  animation-name: formControlInputMessageAnimation;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes formControlInputMessageAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
