.payment-pane-content {
  width: 100%;
}


.payment-pane-payment-information-title {
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1.25rem;
}

.payment-pane-method-content {
  width: 100%;
}

.payment-options-content {
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
}

.payment-pane-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

.payment-button-container {
  width: 300px;
  margin-bottom: 1rem;
}

.payment-pane-methods-message {
  margin: 16px 0;
  font-size: 14px;
  width: 100%;
}

.payment-options-content {
  display: flex;
  flex-wrap: wrap;
}

.payment-pane-select-coupon {
  margin-bottom: 1rem;
}

.payment-options-credit-card {
  margin: 1rem 0;
}

@media screen and (max-width:1000px) {
  .payment-pane-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .payment-button-container {
    width: 100%;
  }
}
