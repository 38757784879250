.healthy-gap,
.healthy-gap-alt {
  padding: 4rem 7.625rem;
}
.healthy-banner,
.healthy-banner-alt {
  min-width: 100%;
  height: 32rem;
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: flex-start;
  background-image: url('../images/healthyBanner.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.healthy-banner-alt {
  justify-content: center;
  background-image: url('../images/healthyBannerAlt.jpg');
}
.healthy-banner-text,
.healthy-banner-text-alt {
  font-size: min(2.6rem, 7vw);
  font-weight: 700;
  width: 50%;
  line-height: 3.9rem;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.healthy-banner-text-alt {
  width: 42%;
}
.healthy-banner-text > span,
.healthy-banner-text-alt > span {
  color: #ce3d25;
  font-size: min(3rem, 10vw);
}
.healthy-banner-text-alt > span {
  color: #f6e862;
}
.healthy-flavor {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.healthy-flavor-title {
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: min(3rem, 7vw);
  text-align: center;
  color: #eb5b1e;
  margin-bottom: 2rem;
  position: relative;
}
.healthy-flavor-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: min(2rem, 6vw);
  width: 40%;
  text-align: center;
  color: #454546;
}
.healthy-flavor-column {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 2rem;
  margin-top: 2rem;
}
.healthy-flavor-column-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
.healthy-flavor-column-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  width: 100%;
  font-size: min(1.4rem, 7vw);
  text-align: center;
  color: #eb5b1e;
}
.healthy-flavor-column-item-img {
  width: 7rem;
  height: 100%;
}
.healthy-quality-and-pratice {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  background-color: #fffcd5;
}
.healthy-quality-and-pratice-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.healthy-quality-and-pratice-item i {
  font-size: 5rem;
  color: #eb5b1e;
}
.healthy-quality-and-pratice-item-title {
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: min(3rem, 6vw);
  text-align: center;
  color: #eb5b1e;
}
.healthy-quality-and-pratice-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  width: 100%;
  font-size: min(1.4rem, 7vw);
  text-align: center;
  color: #454546;
}
.healthy-care {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  background-color: #f1eff5;
}
.healthy-care-img {
  width: 35rem;
  height: 20rem;
}
.healthy-care-title {
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: min(3rem, 7vw);
  text-align: center;
  color: #eb5b1e;
}
.healthy-care-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  width: 100%;
  font-size: min(1.4rem, 6vw);
  text-align: center;
  color: #454546;
}
@media screen and (max-width: 800px) {
  .healthy-gap {
    padding: 1rem;
  }
  .healthy-care-img {
    width: 100%;
    height: 13rem;
  }
  .healthy-banner-text {
    width: 100%;
  }
  .healthy-banner-text-alt {
    width: 100%;
  }
  .healthy-banner {
    justify-content: center;
  }
  .healthy-flavor-text {
    width: 100%;
  }
}
