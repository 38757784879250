.toprow-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.toprow-main .toprow-main-logo {
  width: 100%;
  height: 1.5rem;
  display: none;
}

.toprow-main-container {
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 750px) {
  .toprow-main .toprow-main-logo {
    display: block;
  }
}
