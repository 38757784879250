.wishlist-product-list-page-wrapper {
  margin-bottom: 2rem;
}


.wishlist-product-list-page-grid {
  width: 100%;
  min-height: 60vh;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  object-fit: cover;
  justify-content: space-between;
  justify-items: center;
  gap: .5rem;
  width: 100%;
  margin-bottom: 4rem;
  align-content: space-between;
}


.wishlist-product-list-page-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: 1rem;
}

.wishlist-product-list-page-pagination .wishlist-product-list-page-pagination-button {
  outline: none !important;
  background: none;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  color: rgba(30, 30, 30, 0.5);
}

.wishlist-product-list-page-pagination .wishlist-product-list-page-pagination-button.selected {
  color: #000;
}


@media screen and (max-width: 800px) {
  .wishlist-product-list-page-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
