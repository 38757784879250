.payments-list-title {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
}

.payments-list-no-card {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
}

.payments-list-container {
  width: 100%;
  border-radius: 1rem;
  background: rgba(237, 242, 244, 0.60);
  padding: 1rem;
}

.payments-list-container hr {
  width: 100%;
  border-bottom: 1.5px solid rgba(30, 30, 30, 0.20);
}

.payments-list-container>h3 {
  width: 100%;
  margin-bottom: 15px;

  color: #1E1E1E;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.credit-card-list-main>.credit-card-option-container>.credit-card-option-main:hover {
  cursor: default !important;
}

.offline-payments-pane-title {
  font-size: 18px;
  letter-spacing: 1px;
}

.offline-payments-picker-main {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.offline-payments-option-main {
  background-color: #ededed;
  color: #474747;
  border: 1px solid #fff;

  font-size: 14px;
  letter-spacing: 1px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 570px;
  height: 35px;
  margin: 4px auto;
}

.offline-payments-option-main:hover {
  cursor: default;
}

.offline-payments-pane-container {
  margin-top: 15px;
}

.offline-payments-list-container {
  margin-top: 30px;
}
