.header-download-app {
  display: none;
}

.header-main {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 6.5rem 2.5rem 6.5rem;
  align-items: center;
}

.header-hamburger-menu {
  display: none;
  overflow: hidden;
  transition: all 0.8s;
  height: 0;
  position: absolute;
  z-index: 1000;
  width: 100%;
}

.header-hamburger-menu-active {
  height: 100%;
}

.header-hamburger-bar1,
.header-hamburger-bar2,
.header-hamburger-bar3 {
  width: 35px;
  height: 5px;
  background-color: #ce3c25;
  margin: 6px 0;
  transition: all 0.5s ease-in;
}

.header-btn-mobile {
  display: none;
}

.header-btn-mobile-active .header-hamburger-bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.header-btn-mobile-active .header-hamburger-bar2 {
  opacity: 0;
}

.header-btn-mobile-active .header-hamburger-bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

.header-rows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
}

.header-menu {
  display: none !important;
}

.header-search-mobile {
  display: none;
}

@media screen and (max-width: 740px) {
  .header-btn-mobile {
    display: block;
  }

  .header-hamburger-menu {
    display: block;
  }

  .header-download-app {
    display: block;
  }

  .header-search-mobile {
    display: block;
    width: 100%;
    height: 100%;
  }

  .header-search-mobile .search-bar-main {
    display: block;
    width: 100%;
    height: 56px;
    padding: 0 1rem;
  }

  .header-search-mobile .ais-SearchBox-input {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .header-search-mobile .ais-SearchBox-form .ais-SearchBox-input {
    padding-left: 3.5rem;
    height: 100%;
  }

  .header-search-mobile .ais-SearchBox-form .ais-SearchBox-submit {
    background-color: #ce3c25 !important;
    border: 1px solid #ce3c25 !important;
    height: 100%;
  }

  .header-search-mobile .ais-SearchBox-form .ais-SearchBox-submit svg {
    fill: #fff !important;
  }

  .header-rows {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 750px) {
  .header-main {
    background-color: #ffff;
    padding: 1rem;
    margin: 0px;
  }

  .header-menu {
    display: flex !important;
    font-size: 30px;
    align-self: center;
    color: #ce3c25;
  }
}

@media screen and (min-width: 1440px) {
  .header-main {
    max-width: 1440px;
    margin: 0 auto;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1300px) {
  .header-btn-mobile {
    display: block;
  }

  .header-hamburger-menu {
    display: block;
  }

  .header-download-app {
    display: block;
  }

  .header-search-mobile {
    display: block;
    width: 100%;
    height: 100%;
  }

  .header-search-mobile .search-bar-main {
    display: block;
    width: 100%;
    height: 56px;
    padding: 0 1rem;
  }

  .header-search-mobile .ais-SearchBox-input {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .header-search-mobile .ais-SearchBox-form .ais-SearchBox-input {
    padding-left: 3.5rem;
    height: 100%;
  }

  .header-search-mobile .ais-SearchBox-form .ais-SearchBox-submit {
    background-color: #ce3c25 !important;
    border: 1px solid #ce3c25 !important;
    height: 100%;
  }

  .header-search-mobile .ais-SearchBox-form .ais-SearchBox-submit svg {
    fill: #fff !important;
  }

  .header-main {
    display: flex;
    justify-content: space-between;
  }

  .logo-main {
    display: none;
  }

  .header-rows,
  .toprow-main-container,
  .toprow-main-logo {
    display: flex !important;
  }

  .header-rows .search-bar-main,
  .header-rows .logged-out-options-main,
  .header-rows .big-club-indicator-main,
  .header-rows .bottomrow-main {
    display: none;
  }
}
