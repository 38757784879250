.login-main {
  margin: 0 auto;
  width: 100%;
  padding: 0;
  min-height: 60vh;
}
.login-main .login-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: auto;
  margin: 0 auto;
}
.login-tabs .login-tab-button,
.login-tab-button-selected {
  background: none;
  outline: none;
  border: none;
  color: rgba(30, 30, 30, 0.5);
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 22px;
  padding: 1rem;
  position: relative;
  transition: 0.5s ease-in-out;
}
.login-tabs .login-tab-button-selected {
  color: #eb5b1e;
}
.login-tabs .login-tab-button-selected::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  left: 0;
  right: 0;
  background: #eb5b1e !important;
  bottom: 0;
}
.login-tabs .login-tab-button:hover {
  opacity: 0.5;
}
.login-tabs .login-tab-button::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  left: 0;
  right: 0;
  background: rgba(30, 30, 30, 0.5);
  bottom: 0;
}
.login-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

@media screen and (max-width: 1000px) {
  .login-content {
    padding: 2rem 1rem;
  }
  .login-tabs .login-tab-button,
  .login-tab-button-selected {
    padding: 0.5rem;
  }
}
