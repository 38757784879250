.address-form {
  width: 100%;
  margin-bottom: 1rem;
}

.address-form-info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.address-form-title {
  color: #000;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
}

.address-info-checkbox {
  border-radius: 100%;
}

.address-form-info-row,
.address-form-info-row-start {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  margin-bottom: .5rem;
}

.address-form-info-row-start {
  justify-content: flex-start;
  margin: 0;
  margin-bottom: 1.5rem;
}

.address-form-info-row-start .radio-button-container {
  padding: 0;
}

.address-form-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 1rem;
  grid-template-rows: repeat(3, 1fr);
}

.address-form-select-dropdown-container {
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.address-form-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
}

.address-form-select-dropdown {
  background-color: transparent;
  outline: none !important;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 8px;
  border: 1px solid rgba(0.3, 0.3, 0.3, 0.5);
  border-radius: 8px;
  width: 100%;
  cursor: pointer;

}

.address-form-select-dropdown-error {
  color: #ce3c25;
  font-size: 10px;
}


.address-form-select-dropdown-error-animation {
  -webkit-animation-name: addressFormSelectDropdownError;
  animation-name: addressFormSelectDropdownError;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@-webkit-keyframes addressFormSelectDropdownError {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width:1000px) {
  .address-form-info-row {
    flex-direction: column;
  }

  .address-form-info-grid {
    display: flex;
    flex-direction: column;
  }
}
