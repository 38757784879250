.blog-last-posts-main {
  display: flex;
  flex-direction: column;
  padding: 24px 124px;
  justify-content: center;
  align-items: center;
}

.blog-last-posts-main > h2 {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 160%;
  align-items: center;
  color: #CE3C25;
}

.blog-last-posts-grid {
  display: grid;
  gap: 32px;
  grid-template-columns: 275px 275px 275px 275px;
  align-items: flex-start;
}

.blog-last-posts-anchor-card {
  text-decoration: none;
  width: 275px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.blog-last-posts-anchor-card > img {
  width: 275px;
  height: 123px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.16));
  border-radius: 16px;
  object-fit: cover;
}
.blog-last-posts-anchor-card > h3 {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  align-items: center;
  color: #CE3C25;
  width: 95%;
}

.blog-last-posts-anchor-card > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  padding: 0 16px;
  text-align: center;
  background: linear-gradient(180deg, #000000 41.15%, rgba(0, 0, 0, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.blog-last-posts-load-more {
  background: #CE3C25;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  border: none;
  padding: 16px;
  color: #fff;
}

.blog-last-posts-load-more-disabled {
  background: #b19b98;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  border: none;
  padding: 16px;
  color: #fff;
}

@media only screen and (max-width: 1450px) {

  .blog-last-posts-grid {
    display: grid;
    gap: 8px;
    grid-template-columns: 240px 240px 240px 240px;
  }

  .blog-last-posts-anchor-card {
    width: 260px;
  }

  .blog-last-posts-anchor-card > img {
    width: 240px;
    height: 113px;
  }

  .blog-last-posts-anchor-card > h3 {
    font-size: 16px;
  }

  .blog-last-posts-anchor-card > p {
    font-size: 14px;
  }

}

@media only screen and (max-width: 1050px) {

  .blog-last-posts-grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

}

@media only screen and (max-width: 750px) {

  .blog-last-posts-grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

}