.answers-main-container-closed {
  width: 100%;
  border-radius: 8px;
  background: #F2F5FF;
}

.answers-main-container-open {
  border-radius: 8px;
  width: 100%;
  background: rgba(242, 245, 255, 0.60)
}

.answers-question-container,
.answers-open-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: .5rem .5rem .5rem .5rem;
  color: rgba(30, 30, 30, 0.80);
  font-family: "Open Sans", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.answers-answer-container {
  padding: .5rem 4rem .5rem .5rem;
  color: #212121;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.answers-answer-container p {
  margin-bottom: 0;
}

.answers-question-text-open {
  color: #1E1E1E;
}

.answers-question-container p {
  margin-bottom: 0;
}

.answers-question-container button {
  background: none;
  color: none;
  border: none;
  padding: 0;
  font: none;
  cursor: pointer;
  outline: none !important;
  margin: 0 .5rem;
}

.answers-question-container .arrowIconUp {
  color: #EB5B1E;
}

.answers-open-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

@media only screen and (max-width: 767px) {

  .answers-question-container,
  .answers-open-button,
  .answers-question-text-closed,
  .answers-question-text-open {
    font-size: 20px;
    font-weight: 700;
  }

  .answers-answer-container,
  .answers-open-button {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
  }

  .answers-answer-container {
    padding: .5rem .5rem .5rem .5rem;
  }
}
