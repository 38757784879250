.price-slider-main-div {
    width: 95%;
    padding-left: 5%;
}

.price-slider-text-div {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  display: inline-flex;
}

.price-slider-text-div > p {
  height: auto;
  font-size: 18;
  font-weight: 400;
  letter-spacing: 1;
  color: #eb5b1e;
}
