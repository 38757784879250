 .my-account-information {
   display: flex;
   flex-direction: column;
   border-radius: 1rem;
   background: rgba(237, 242, 244, 0.60);
   padding: 1rem;
 }

 .my-account-information-header-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
 }

 .my-account-information-header-text-content h3,
 .my-account-information-header-text-content h6 {
   color: #1E1E1E;
   font-family: "Open Sans", sans-serif;
   font-size: 20px;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
 }

 .my-account-information-header-text-content h6 {
   font-feature-settings: 'calt' off;
   font-size: 14px;
   font-weight: 400;
   letter-spacing: -0.14px;
 }

 .my-account-information-change-password-button {
   cursor: pointer;
   outline: inherit;
   background: none;
   display: flex;
   align-items: center;
   gap: .5rem;
   border-radius: 8px;
   border: 1px solid #EB5B1E;
   height: 50px;

   color: #EB5B1E;
   font-feature-settings: 'calt' off;
   font-family: "Open Sans", sans-serif;
   font-size: 14px;
   font-style: normal;
   font-weight: 700;
   line-height: 24px;
   letter-spacing: -0.14px;
 }

 .my-account-information-change-password-button:focus,
 .my-account-information-change-password-button:active {
   border: 1px solid #EB5B1E;
   outline: none;
 }

 .my-account-information-main-container {
   display: flex;
   flex-direction: column;
   gap: 1rem;
 }

 .my-account-information-main-container .my-account-address-main {
   display: flex;
   flex-direction: column;
   border-radius: 1rem;
   background: rgba(237, 242, 244, 0.60);
   padding: 1rem;

 }

 .my-account-information hr {
   border-bottom: 2px solid rgba(30, 30, 30, 0.20);
   width: 100%;
 }

 .my-account-information>h3 {
   font-size: 20px;
   font-weight: 400;
 }

 .my-account-information-content {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-top: .5rem;
   gap: .5rem;
   flex-wrap: wrap;
 }

 .my-account-information-content-row {
   display: flex;
   align-items: center;
   gap: 1rem;
   width: 100%;
 }

 .my-account-information-medium-content {
   width: 32%;
 }

 .my-account-information-medium-content-email-input {
   width: 100%;
 }

 .my-account-information-medium-input {
   background: none;
   text-indent: 15px;
   width: 100%;
   height: 43px;
   border-radius: 8px;
   border: 1px solid rgba(30, 30, 30, 0.50);
   margin-bottom: 10px;

   font-feature-settings: 'calt' off;
   font-family: " Open Sans", sans-serif;
   font-size: 18px;
   font-style: normal;
   font-weight: 400;
   line-height: 150%;
   letter-spacing: -0.18px;
 }

 .my-account-information-medium-input::placeholder {
   color: rgba(30, 30, 30, 0.50);
   font-feature-settings: 'calt' off;
   font-family: " Open Sans", sans-serif;
   font-size: 18px;
   font-style: normal;
   font-weight: 400;
   line-height: 150%;
   letter-spacing: -0.18px;
 }

 .my-account-information-medium-error {
   width: 100%;
 }

 .my-account-information-submit-content {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   width: 100%;
   margin-top: 5px;
 }

 .my-account-information-submit-button {
   width: 150px;
   margin-bottom: 5px;
 }

 .my-account-information-password-popup-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.5);
   z-index: 1000;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .my-account-information-password-popup {
   position: relative;
   background: #fff;
   border-radius: 8px;
   padding: 1rem 1.5rem;
   border-radius: 1rem;
   z-index: 1001;
   width: 470px;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
 }

 @media only screen and (max-width: 750px) {
   .my-account-information-content {
     display: flex;
     flex-direction: column;
     align-items: center;
   }

   .my-account-information-header-container {
     flex-direction: column;
   }

   .my-account-information-change-password-button {
     height: 40px;
     width: 130px;
   }

   .my-account-address-item-main {
     gap: 1.5rem;
     padding: 1rem;
   }

   .my-account-information-medium-content-email-input {
     width: 100%;
   }

   .my-account-address-item-main-actions {
     align-self: center;
   }

   .my-account-information-medium-content {
     width: 100%;
   }

   .my-account-information-medium-input {
     font-size: 16px;
   }
 }
