.landing-encourage-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 4rem 1rem 6.5rem 1rem;
  gap: 0.5rem;
}

.landing-encourage-title {
  font-size: min(3.375rem, 9vw);
  font-weight: 700;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  width: 50%;
  color: #eb5b1e;
  text-align: center;
}
.landing-encourage-paragraph {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: min(1.8rem, 6vw);
  text-align: center;
  color: #ce3c25;
  width: 50%;
}
.landing-encourage-illustration {
  width: min(26.25rem, 50%);
  height: min(26.25rem, 50%);
}
@media screen and (max-width: 800px) {
  .landing-encourage-title {
    width: 100%;
  }
  .landing-encourage-paragraph {
    width: 100%;
  }
}

.landing-everything-to-facilitate-container {
  display: flex;
  background-color: #fff3bb;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding: 4rem 0.5rem 4.9375rem 0.5rem;
}
.landing-everything-to-facilitate-title {
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  width: 60%;
  font-size: min(3.375rem, 9vw);

  text-align: center;
  color: #eb5b1e;
  margin-bottom: 4rem;
}
.landing-everything-to-facilitate-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4.375rem;
}
.landing-everything-to-facilitate-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.landing-everything-to-facilitate-item-img {
  width: 7rem;
  height: 100%;
}
.landing-everything-to-facilitate-item-text,
.landing-everything-to-facilitate-item-text-alt {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  width: 60%;
  font-size: min(1.8rem, 6vw);
  text-align: center;
  color: #eb5b1e;
}
.landing-everything-to-facilitate-item-text-alt {
  width: 100%;
}
@media screen and (max-width: 800px) {
  .landing-everything-to-facilitate-title {
    width: 100%;
  }
  .landing-everything-to-facilitate-item-text {
    width: 100%;
  }
}
