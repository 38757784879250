.flip-unit-container {
  display: block;
  position: relative;
  padding: 8px;
  border-radius: 8px;
  background: linear-gradient(167deg, #1c1c1c 5.46%, #505050 125.19%);
  width: 60px;
  height: 52px;
}

.upper-card,
.lower-card {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
}
.upper-card span,
.lower-card span {
  font-size: 40px;
  font-weight: bold;
  color: #ffff;
}

.upper-card {
  align-items: flex-end;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.upper-card span {
  transform: translateY(50%);
}

.lower-card {
  align-items: flex-start;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.lower-card span {
  transform: translateY(-50%);
}

.filp-card {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.filp-card span {
  font-size: 40px;
  font-weight: bold;
  color: #ffff;
}
.filp-card.unfold {
  top: 50%;
  align-items: flex-start;
  transform-origin: 50% 0%;
  transform: rotateX(180deg);
  background: linear-gradient(167deg, #1c1c1c 5.46%, #505050 125.19%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.filp-card.unfold span {
  transform: translateY(-50%);
}
.filp-card.fold {
  top: 0%;
  align-items: flex-end;
  transform-origin: 50% 100%;
  transform: rotateX(0deg);
  background: linear-gradient(167deg, #1c1c1c 5.46%, #505050 125.19%);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.filp-card.fold span {
  transform: translateY(50%);
}

.fold {
  -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1
    normal forwards;
  animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal
    forwards;
  transform-style: preserve-3d;
}

.unfold {
  -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1
    normal forwards;
  animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal
    forwards;
  transform-style: preserve-3d;
}

@-webkit-keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}
@-webkit-keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
