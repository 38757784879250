.recipe-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.recipe-header {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.recipe-header-title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
}

.recipe-body-main-content {
  display: flex;
  gap: 32px;
  align-items: flex-start;
}

.recipe-body-main-content-text {
  width: auto;
}
.recipe-body-main-content-player {
  width: 60%;
  position: sticky;
  top: 40px;
}

.recipe-body-main-content-recipe-steps {
  color: #4a4a4a;
}

@media only screen and (max-width: 750px) {
  .recipe-body-main-content {
    flex-direction: column;
  }
}
