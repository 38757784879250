.kits-presentation-card-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.kits-presentation-card-main-container-inverted {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.kits-presentation-card-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  height: 350px;
  max-height: 350px;
}

.kits-presentation-card-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}

.kits-presentation-card-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 28.8px;
}

.kits-presentation-card-link {
  text-align: center;
  background-color: #CE3C25;
  color: #FFFFFF !important;
  border-radius: .5rem;
  padding: 16px 26px 16px 26px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32.68px;
}

.kits-presentation-card-link:hover {
  text-decoration: none;
}

.kits-presentation-card-image {
  width: 45%;
  height: 350px;
  max-height: 350px;
}

.kits-presentation-card-image img {
  width: 100%;
  height: 350px;
  max-height: 350px;
}

@media only screen and (max-width: 767px) {

  .kits-presentation-card-main-container,
  .kits-presentation-card-main-container-inverted {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .kits-presentation-card-text-container {
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .kits-presentation-card-title {
    font-size: 32px;
    text-align: center;
  }

  .kits-presentation-card-text {
    font-size: 20px;
    text-align: center;

  }

  .kits-presentation-card-image {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .kits-presentation-card-main-container,
  .kits-presentation-card-main-container-inverted {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .kits-presentation-card-text-container {
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .kits-presentation-card-title {
    font-size: 32px;
    text-align: center;
  }

  .kits-presentation-card-text {
    font-size: 20px;
    text-align: center;
  }

  .kits-presentation-card-image {
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .kits-presentation-card-image img {
    width: 100%;
    object-fit: contain;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1300px) and (orientation: landscape) {
  .kits-presentation-card-text-container {
    height: auto;
    max-height: 100%;
  }

  .kits-presentation-card-image {
    width: 45%;
    height: 405px;
    max-height: 405px;
  }

  .kits-presentation-card-image img {
    width: 100%;
    height: 405px;
    max-height: 405px;
  }

  .kits-presentation-card-title {
    font-size: 36px;
  }

  .kits-presentation-card-text {
    font-size: 22px;
  }
}
