.department-cards-container {
  height: auto;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
}

.department-cards-wrapper {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 16px auto;
  gap: 32px;
}

.department-cards-container::-webkit-scrollbar {
  display: none !important;
}

.department-cards-item {
  width: 216px;
  height: auto;
  display: flex !important;
  text-decoration: none !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.department-cards-item-img {
  width: 216px;
  height: auto;
  border-radius: 8px;
}

.department-cards-item-description {
  font-family: 'Open Sans';
  font-style: normal;
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: #1e1e1e;
}
.slick-list,
.slick-track {
  touch-action: pan-y !important;
}

@media screen and (min-width: 1336px) {
  .department-cards-wrapper {
    gap: 16px;
  }
}

@media screen and (max-width: 750px) {
  .department-cards-container {
    width: 95%;
  }
  .department-cards-wrapper {
    gap: 8px;
  }

  .department-cards-item {
    width: 180px;
    height: auto;
  }

  .department-cards-item-img {
    width: 180px;
    height: auto;
  }

  .department-cards-item-description {
    font-size: 14px;
    line-height: 19px;
  }
}
