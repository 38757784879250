.credit-card-editor-main {
  display: flex;
  flex-flow: row nowrap;
}

.credit-card-editor-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
  padding: 10px 0px;
}

.credit-card-editor-form-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: .5rem;
}

.credit-card-editor-form-control {
  background-color: #f6f6f6;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  text-indent: 15px;
  border: 0px solid #fff;
  border-radius: 2px;
  width: 100%;
  max-width: 450px;
  height: 35px;
  margin-bottom: 5px;
}

.credit-card-editor-display {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 750px
  /*SMALL_MEDIA_SIZE*/
) {
  .credit-card-editor-display {
    display: none;
  }

  .credit-card-editor-form-control {
    font-size: 16px;
  }

  .credit-card-editor-main {
    display: block;
    justify-content: center;
    flex-flow: row;
  }

  .credit-card-editor-form {
    width: 100%;
  }

  .credit-card-editor-form-main {
    width: 100%;
  }

  .credit-card-editor-form-control {
    width: 100% !important;
    max-width: 750px !important;
  }
}
