.cart-panel-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border: 2px solid #1E1E1E33;
  border-radius: .5rem;
}

.cart-panel-header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cart-panel-cart-items-quantity {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.cart-panel-cart-items-quantity h1 {
  margin-bottom: 0px;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.24px;
}


.cart-panel-select-all-items-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
}

.cart-panel-cashback-container,
.cart-panel-freightage-container {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .cart-panel-header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-panel-cart-items-quantity {
    width: 100%;
  }
}
