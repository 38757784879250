.card-preview {
  width: 100%;
  border: 2px solid #1E1E1E33;
  border-radius: .5rem;
  padding: 1rem;
  display: block;
}

.card-preview-title {
  font-size: 1.25rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}


.card-preview-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5rem;
}

.card-preview-info-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: 700;
  font-weight: 400;
  margin: 0;
  color: rgba(30, 30, 30, 1);
}

.card-preview-info-text-alt {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  color: rgba(30, 30, 30, 1);
}

.card-preview-divisor {
  width: 100%;
  background-color: rgba(30, 30, 30, .5);
  height: 1px;
  margin: 1rem 0;
}

.card-preview-info-text-orange {
  font-size: 1.25rem;
  color: #EB5B1E;
}


.card-preview-resume {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.card-preview-resume-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.card-preview-resume-item-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card-preview-resume-item-content-text {
  overflow: hidden;
  text-overflow: clip;
  margin: 0;
}

.card-preview-resume-item-button {
  background: none !important;
  outline: none !important;
  border: none !important;
  margin: 0;
  color: #EB5B1E;
  transition: .5s ease-in-out;
  font-weight: 700;
}

.card-preview-resume-item-button:hover {
  opacity: .6;
}


.card-preview-cart-items-container-scroll {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0;
  max-height: 400px;
  overflow-y: auto;
  padding: .5rem;
}

.card-preview-cart-items-container-scroll::-webkit-scrollbar {
  width: 6px;
  margin-left: 10px
}

.card-preview-cart-items-container-scroll::-webkit-scrollbar-thumb {
  background-color: #EB5B1E;
  border-radius: 2px;
}

.card-preview-cart-items-container-scroll::-webkit-scrollbar-track {
  background-color: rgba(30, 30, 30, 0.6);
  border-radius: 2px;
}


@media screen and (max-width: 800px) {
  .card-preview {
    display: none;
  }
}
