.logged-out-options-main {
  font-weight: 600;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-right: 2.5rem;
}
.logged-out-link {
  text-decoration: none !important;
  color: #000;
  transition: 0.5s ease-in-out;
}
.logged-out-link:hover {
  color: #000;
  filter: opacity(0.8);
}
.logged-out-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.logged-out-container .logged-out-container-img {
  height: 2rem;
  width: 2.75rem;
}
.logged-out-container .logged-out-content h3 {
  margin: 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
}
.logged-out-container .logged-out-content p {
  margin: 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
}
.logged-out-options-register {
  background-color: #ce3c25 !important;
  color: #ffffff;
  letter-spacing: 1px;

  height: 25px;

  border-radius: 7px;
  padding: 0px 5px;
}

.logged-out-options-register:hover {
  text-decoration: none;
  color: #ffffff;
}

.logged-out-options-login {
  background-color: #ffffff !important;
  color: #ce3c25;
}

.logged-out-options-login:hover {
  text-decoration: none;
  color: #ce3c25;
}

@media only screen and (max-width: 1100px) {
  .logged-out-options-register {
    padding: 3px 5px;
  }

  .logged-out-options-main {
    font-size: 14px;
  }

  .logged-out-options-main {
    margin: 0px 5px 0px 5px;
  }
}

@media only screen and (max-width: 750px) {
  .logged-out-options-main {
    display: none;
  }
}
