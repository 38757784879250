.no-product-main {
  font-family: 'Lato';
  font-size: 14px;
  font-weight: 400;

  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 60px 0px 60px 0px;
}

.no-product-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.no-product-sub-container>h3 {
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}

.no-product-text {
  color: #ce3c25;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 10px;
}

.no-product-container {
  position: relative;
  height: 120px;
  /* This container must have a set height and width for proper alignment*/
  width: 110px;
}

.no-product-image {
  width: auto;
  height: 100%;
}