.horizontal-divider-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.horizontal-divider-wrapper .horizontal-divider-divisor,
.horizontal-divider-wrapper .horizontal-divider-divisor-alt {
  width: 95%;
  height: 0.1rem;
  background: rgba(30, 30, 30, 0.5);
  border-radius: 0.5rem;
}
.horizontal-divider-wrapper .horizontal-divider-divisor-alt {
  width: 5%;
}
.horizontal-divider-wrapper .horizontal-divider-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 19px;
  white-space: nowrap;
  display: inline;
  margin: 0 0.5rem;
}
.horizontal-divider-wrapper .horizontal-divider-divisor-link {
  text-decoration: none;
  color: #000;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.horizontal-divider-wrapper .horizontal-divider-divisor-link:hover {
  filter: opacity(0.6);
}

@media screen and (max-width: 768px) {
  .horizontal-divider-wrapper .horizontal-divider-title {
    font-size: 0.875rem;
  }
}
