.payment-summary-main>p {
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0px !important;
}

@media only screen and (max-width: 500px
  /*SMALL_MEDIA_SIZE*/
) {
  .payment-summary-main>p {
    font-size: 12px;
  }
}
