.black-friday-base-banner-main-container {
  width: 100%;
  background-color: lightgray;
  padding-top: 5rem;
  background-image: url('../images/black-friday-base-banner-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  border-radius: 16px 16px 0px 0px;
}

.black-friday-base-banner-top-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.black-friday-base-banner-top-content>h1 {
  width: 720px;
  color: #fff;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 2rem;
}

.black-friday-base-banner-top-content-first-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 720px;
}

.black-friday-base-banner-top-content-first-container div {
  border-radius: 16px;
  border: 2px solid #eb5b1e;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2rem 2rem 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 350px;
}

.black-friday-base-banner-top-content-first-container h1,
.black-friday-base-banner-top-content-first-container span,
.black-friday-base-banner-top-content-first-container p {
  color: #eb5b1e;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 108px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.black-friday-base-banner-top-content-first-container span {
  font-size: 48px;
}

.black-friday-base-banner-top-content-first-container p {
  color: #fff;
  font-weight: 400;
  font-size: 24px;
}

.black-friday-base-banner-top-content-second-container {
  max-width: 720px;
  margin-top: 1rem;
  border-radius: 16px;
  border: 2px solid #eb5b1e;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5rem 1rem 5rem;
  margin-bottom: 2rem;
}

.black-friday-base-banner-top-content-second-container h1,
.black-friday-base-banner-top-content-second-container p {
  color: #eb5b1e;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.black-friday-base-banner-top-content-second-container p {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.black-friday-base-banner-lower-content {
  padding: 6rem 15rem 5rem 15rem;
  border-radius: 9rem 9rem 0 0;
  width: 100%;
  background-image: url('../images/black-friday-base-banner2-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.black-friday-base-banner-lower-content h1,
.black-friday-base-banner-lower-content span {
  color: #fff;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64.5px;
  letter-spacing: -1px;
}

.black-friday-base-banner-lower-content span {
  font-weight: 700;
}

.black-friday-base-banner-lower-links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  margin: auto;
  margin-top: 2rem;
  gap: 5px;
}

.black-friday-base-banner-signup-link,
.black-friday-base-banner-signin-link {
  text-decoration: none;
  border-radius: 8.659px;
  background: #ce3c25;
  width: 150px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-feature-settings: 'calt' off;
  font-family: 'Open Sans', sans-serif;
  font-size: 19.483px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.978px;
  letter-spacing: -0.195px;
}

.black-friday-base-banner-signup-link {
  width: 150px;
}

.black-friday-base-banner-signup-link:hover {
  color: #fff;
  text-decoration: none;
}

.black-friday-base-banner-signin-link {
  width: 180px;
  background: none;
  border-radius: 8.659px;
  border: 1.082px solid #fff;
}

.black-friday-base-banner-signin-link:hover {
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .black-friday-base-banner-top-content>h1 {
    font-size: 56px;
    font-weight: 700;
    width: 100%;
    padding: 0 2rem;
    padding-bottom: 1rem;
  }

  .black-friday-base-banner-top-content-first-container {
    flex-direction: column;
    width: 100%;
  }

  .black-friday-base-banner-top-content-first-container div {
    margin-bottom: 2rem;
    padding-top: 3rem;
    width: 330px;
  }

  .black-friday-base-banner-top-content-second-container {
    width: 330px;
    margin: auto;
    padding-top: 3rem;
  }

  .black-friday-base-banner-top-content-second-container p {
    margin: 1rem 0 2rem 0;
    width: 280px;
  }

  .black-friday-base-banner-lower-content {
    margin-top: 70px;
    padding: 2rem 0.5rem 5rem 0.5rem;
    border-radius: 4rem 4rem 0 0;
  }

  .black-friday-base-banner-lower-content h1 {
    padding: 2.5rem 1rem 0 1rem;
  }

  .black-friday-base-banner-lower-content h1,
  .black-friday-base-banner-lower-content span {
    font-size: 32px;
    font-style: normal;
    line-height: 47.5px;
    letter-spacing: -1px;
  }

  .black-friday-base-banner-signup-link {
    margin-bottom: 1rem;
  }

  .black-friday-base-banner-lower-links {
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .black-friday-base-banner-top-content-first-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .black-friday-base-banner-top-content>h1 {
    padding: 0 3rem 1rem 3rem;
    width: 100%;
  }

  .black-friday-base-banner-top-content-second-container {
    width: 90%;
    padding: 0 1rem 1rem 1rem;
  }

  .black-friday-base-banner-lower-content {
    padding: 6rem 2rem 5rem 2rem;
  }

  .black-friday-base-banner-lower-content h1,
  .black-friday-base-banner-lower-content span {
    font-size: 48px;
  }

  .black-friday-base-banner-lower-links {
    width: 70%;
  }
}
