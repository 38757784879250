.buyprice-main {
  text-align: center;
  display: flex;
  flex-direction: row;
}

.buy-price-single {
  display: flex;
  font-family: 'Open Sans', sans-serif;
  font-size: 26px;
  color: #ce3c25;
  font-weight: 700;
  margin: 0;
  gap: 8px;
  align-items: flex-end;
}

.buy-price-single > h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  font-weight: 700;
  margin: 0px;
}

.buy-price-single > h3 > small {
  color: #1e1e1e;
  font-weight: 700;
  font-size: 14px;
}

.buyprice-show-list-price > h6 {
  display: inline !important;
}

.buyprice-main > .buy-price-single > h6 {
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  text-decoration: line-through;

  color: #1e1e1e;
  opacity: 0.5;
  display: none;
  margin: 2px 0px;
}

.buyprice-estimated-content {
  text-align: center;
  display: flex;
  gap: 8px;
  align-items: flex-end;
}

.buyprice-estimated-value {
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  color: #ce3c25;
  font-weight: 700;
  margin: 0;
}

.buyprice-estimated-value > small {
  color: #1e1e1e;
  font-weight: 700;
  font-size: 14px;
}

.buyprice-value {
  color: #1e1e1e;
  opacity: 0.5;
  font-size: 18px;
  margin: 0px;
  font-weight: 400;
}

@media only screen and (max-width: 750px) {
  .buyprice-estimated-content {
    height: 40px;
  }

  .buy-price-single {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
  .buy-price-single > h3,
  .buyprice-estimated-value {
    font-size: 24px;
  }

  .buyprice-main > .buy-price-single > h6,
  .buyprice-value {
    font-size: 14px;
  }

  .buyprice-estimated-content {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}
