.main-product-content-main {
  margin: 0px 40px 20px 40px;
}

.main-product-container {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 32px;
}

@media only screen and (max-width: 1250px /*SMALL_MEDIA_SIZE*/) {
  .main-product-container {
    display: flex;
    flex-direction: column;
  }
}
