.resume-info {
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2.5rem;
}

.resume-info-wrapper {
  width: 100%;
  background-color: #fff;
  padding: 0.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: .5rem;
  border: 1px solid rgba(30, 30, 30, 0.50);
}

.resume-info-address {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.resume-info-address .address-summary-main p {
  font-size: 0.875rem !important;
}

.resume-info-address-title {
  font-family: 'Open sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.resume-info-delivery-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.resume-info-delivery-content .delivery-summary-main {
  font-size: 0.875rem !important;
}

.resume-info-delivery-content-title {
  font-family: 'Open sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.resume-info-delivery-content-text {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1c8e47;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
}

.resume-info-delivery-content-text>p {
  margin: 0 !important;
}

.resume-info-payment {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.resume-info-payment-title {
  font-family: 'Open sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.resume-info-payment .payment-summary-main p {
  font-size: 0.875rem !important;
}

.resume-info-button {
  outline: none !important;
  border: none !important;
  background: none !important;
  cursor: pointer;
  color: #eb5b1e;
  font-size: 0.875rem;
  font-family: 'Open sans', sans-serif;
  font-weight: 700;
  transition: 0.5s ease-in-out;
}

.resume-info-button:hover {
  opacity: 0.5;
}

.resume-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(20px, 50%));
  gap: .5rem;
  margin-bottom: 1rem;
}

.resume-container-divisor {
  width: 100%;
  height: 1px;
  background-color: rgba(0.3, 0.3, 0.3, 0.5);
  margin-bottom: 1rem;
}

.resume-container-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0;
}

.resume-container-row>span {
  color: rgba(0.3, 0.3, 0.3, 0.5);
  font-family: 'Open sans', sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.resume-container-row-alt>span {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #ce3c25;
}


@media screen and (max-width: 1000px) {
  .resume-container {
    display: flex;
    flex-direction: column;
  }
}
