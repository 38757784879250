.buy-box-main {
  color: #212121;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 20px;
  gap: 24px;
}

.buy-box-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.buy-box-manufacturer {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  line-height: 150%;
  font-family: 'Open-sans', sans-serif;
}

.buy-box-product-row {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.buy-box-product-name {
  font-family: 'Open-sans', sans-serif;
  font-weight: 700;
  color: #ce3c25;
  padding: 0;
  margin: 0;
}

.buy-box-section-price-container {
  display: flex;
  gap: 16px;
}

.buy-box-section-price-container-action {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.buy-box-section-price-container-action>button,
.buy-box-section-price-container-action>div {
  height: 56px;
  width: 173px;
}

.buy-box-number-of-pieces {
  width: 70%;
}

.buy-box-number-of-pieces-text {
  font-weight: 400;
  color: #8e8e8e;
  font-size: 12px;
  text-align: center;
  margin: 0px;
}

.buy-box-number-of-pieces-value {
  font-weight: 700;
  color: #eb5b1e;
  font-size: 20px;
  text-align: center;
  margin-bottom: 0px;
  /* between paragraphs */
}

.buy-box-min-quantity {
  color: #8e8e8e;
  font-size: 12px;
  margin: 0;
}

.buy-box-sub-container {
  display: flex;
  flex-direction: column;
}

.buy-box-sub-container-wrapper {
  display: flex;
  position: relative;
  cursor: pointer;
  max-width: max-content;
}

.buy-box-sub-container-title {
  font-size: 22px;
  line-height: 22px;
  margin: 0;
}

.buy-box-understand-the-price {
  font-size: 12px;
  margin: 0;
  color: #1e1e1e;
  opacity: 0.5;
}

.buy-box-question-mark {
  width: auto;
  height: 12px;
  align-self: center;
  margin: 2px 5px;
}

.buy-box-price-content {
  visibility: hidden;

  position: absolute;
  top: -170px;
  right: -40px;
}

.buy-box-sub-container-wrapper:hover .buy-box-price-content {
  visibility: visible;
}

.buy-box-promo-tag {
  background-color: #4f26a2;
  color: white;
  border-radius: 5px;
  text-align: center;
  width: 70px;
  height: 25px;
}

.buy-box-main>h4 {
  font-size: 18px;
  font-weight: 400;
  margin: 0px 0px -2px 0px;
}

.buy-box-main>h5 {
  font-size: 12px;
  font-weight: 400;
}

@media only screen and (max-width: 750px) {
  .buy-box-main {
    height: auto;
    padding: 4px 0px 0px 0px;
  }

  .buy-box-manufacturer {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-top: 3px;
    margin-bottom: -4px;
  }
}
