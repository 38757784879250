.butcher-gap,
.butcher-gap-alt {
  padding: 4rem 7.625rem;
}
.butcher-landing {
  width: 100%;
  height: 100%;
}
.butcher-gap-alt {
  padding: 0 7.625rem;
}
.butcher-main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 60vh;
}
.butcher-main-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: min-content;
  gap: 1rem;
}
.butcher-main-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: min(1.5rem, 6vw);
  line-height: 121.18%;
  text-align: center;
  color: #000000;
}
.butcher-main-img {
  width: 30rem;
  height: 15rem;
}
.butcher-main-button {
  background: #ce3c25;
  border-radius: 6px;
  border: none;
  outline: none;
  padding: 1rem 2rem;
  color: #fff;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 160%;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.butcher-main-button:hover {
  opacity: 0.8;
}
.butcher-banner {
  background-position: center;
  min-height: 100vh;
  background-image: url('../images/butcherBanner.jpg');
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100%;
  background-size: cover;
}
.butcher-banner-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.butcher-banner-section img {
  width: 100%;
  height: 100vh;
}

.butcher-primary-section {
  width: 100%;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  gap: 4rem;
}
.butcher-primary-section-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: min(4.5rem, 12vw);
  width: 45%;
  line-height: 121.18%;
  text-align: center;
  color: #000000;
}
.butcher-primary-section-title > span {
  font-weight: 700;
  color: #ce3c25;
}
.butcher-primary-section-grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: auto;
  width: 100%;
  height: 100%;
  gap: 1.5rem;
}
.butcher-primary-section-grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  min-height: 60vh;
}
.butcher-primary-section-grid-item-img {
  width: 16rem;
  height: 11rem;
}
.butcher-primary-section-grid-item-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: min(2rem, 8vw);
  line-height: 121.18%;
  text-align: center;
  color: #212121;
}
.butcher-primary-section-grid-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: min(1.5rem, 6vw);
  line-height: 121.18%;
  text-align: center;
  color: #212121;
}
.butcher-primary-section-grid-item-text span {
  font-weight: 700;
}
.butcher-slider-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #ce3c25;
}
.butcher-slider-section-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: min(4.5rem, 12vw);
  line-height: 121.18%;
  text-align: center;
  color: #ffffff;
}
.butcher-slider-section-title > span {
  font-weight: 700;
}
.butcher-slider-section-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 2rem;
}
.butcher-slider-section-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: min(1.5rem, 6vw);
  line-height: 121.18%;
  color: #ffffff;
  margin: 0;
  width: 40%;
}
.butcher-slider-section-text > span {
  font-weight: 400;
}
.butcher-slider-select-container {
  position: relative;
}
.butcher-slider-select-container img {
  content: '';
  left: 5px;
  position: absolute;
  top: 1rem;
  height: 2rem;
  width: 2.5rem;
}
.butcher-slider-select-container::after {
  content: '›';
  font: 1.5rem 'Open sans', monospace;
  color: #333;
  font-weight: 900;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 11px;
  color: #ce3c25;
  top: 1rem;
  position: absolute;
  pointer-events: none;
}
.butcher-slider-select-container select::-ms-expand {
  display: none;
}
.butcher-slider-section-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 1rem 3rem;
  border: none;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: 1.25rem;
  line-height: 160%;
  color: #212121;
  cursor: pointer;
}

.butcher-secondary-section {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;
  padding-right: 0;
  position: relative;
  min-height: 70vh;
}

.butcher-secondary-section-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2rem;
  width: 100%;
}
.butcher-secondary-section-content-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: min(3.5rem, 10vw);
  line-height: 121.18%;
  color: #ce3c25;
}
.butcher-secondary-section-content-title > span {
  font-weight: 400;
}
.butcher-secondary-section-content-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: min(1.5rem, 6vw);
  text-align: left;
  line-height: 121.18%;
  color: #212121;
}
.butcher-secondary-section-content-text > span {
  font-weight: 500;
}
.butcher-secondary-section-content-img {
  width: 22rem;
  height: 7.2rem;
}
.butcher-secondary-section-content-img-alt {
  width: 100%;
  height: 100%;
}
.butcher-product-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.butcher-product-section-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: min(3.5rem, 10vw);
  line-height: 121.18%;
  width: 37%;
  text-align: center;
  color: #ce3c25;
  margin-bottom: 3rem;
}
.butcher-product-section-title > span {
  font-weight: 700;
}
.butcher-product-list {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, auto);
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.butcher-faq-questions > .landing-faq {
  background-color: #fff;
}
.butcher-faq-questions .landing-faq-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: min(3.5rem, 10vw);
  line-height: 121.18%;
  text-align: center;
  color: #ce3c25;
  text-transform: capitalize !important;
}
.butcher-faq-questions .landing-faq-title span {
  font-weight: 700;
}
.butcher-faq-questions .landing-faq-collapse {
  background-color: #fff;
  border: 1px solid rgba(235, 91, 30, 0.5);
}
.butcher-faq-questions .landing-faq-collapse-title {
  color: #212121;
}
.butcher-faq-questions .landing-faq-collapse-btn {
  background-color: #fff;
}
.butcher-faq-questions .landing-faq-collapse-btn i {
  color: #ce3c25;
}

.butcher-product-list-container {
  padding: 4rem 2rem;
  background-color: #ce3c25;
}
.butcher-product-list-title {
  font-family: 'Open sans', sans-serif;
  font-weight: 400;
  font-size: min(4.5rem, 12vw);
  text-align: center;
  color: #fff;
  margin-bottom: 2rem;
}
.butcher-product-list-title > span {
  font-weight: 700;
}
.butcher-product-list-container .slick-dots {
  position: absolute;
  bottom: 5rem;
  left: auto;
  right: auto;
  top: auto;
  display: flex !important;
  gap: 1rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  list-style: none;
}
.butcher-product-list-container .slick-dots li {
  flex-grow: 1;
  border-radius: 0.5625rem;
  background: #d9d9d9;
  max-width: 6rem;
  margin: 0 0.5rem;
}
.butcher-product-list-container .slick-dots .slick-active {
  background: #212121;
}
.butcher-product-list-container .slick-dots .slick-active button {
  color: #212121;
}
.butcher-product-list-container .slick-dots li button {
  background: none;
  border: none;
  outline: none;
  color: #d9d9d9;
  width: 100%;
}

.butcher-product-list-slider-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
}

.butcher-product-list-slider-meal-card {
  background-color: #fff;
  border-radius: 1rem;
  border: 0.2rem solid #ce3c25;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 64rem;
  gap: 1rem;
  height: 26rem;
  margin-bottom: 6rem;
}
.butcher-product-list-slider-meal-card .progressive-image-loader-final-img {
  object-fit: contain;
  width: 25rem;
  height: 18.75rem;
  border-radius: 0.5rem;
}
.butcher-product-list-slider-meal-card img {
  pointer-events: none;
}
.butcher-product-list-slider-meal-card-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.butcher-product-list-slider-meal-card-title {
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: min(2.6rem, 5vw);
  width: 100%;
  inline-size: break-all;
  color: #eb5b1e;
}
.butcher-product-list-slider-meal-card-text {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: min(1.4rem, 4vw);
}
.butcher-product-list-slider-meal-card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  height: 100%;
}

.butcher-product-list-slider-meal-card-actions .product-price-main > h6 {
  width: 100%;
}
.butcher-product-list-slider-meal-card-actions .container {
  margin: 0;
  padding: 0;
  width: min-content;
}
.butcher-product-list-slider-meal-card-thumbnails {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.butcher-product-list-slider-meal-card-thumbnail {
  height: min(4.375rem, 10vw);
  width: min(4.375rem, 10vw);
  border-radius: 50%;
  background: #d9d9d9;
}

@media screen and (max-width: 1000px) {
  .butcher-product-list-slider-meal-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 38rem;
  }
  .butcher-product-list-slider-meal-card-actions {
    width: 100%;
    justify-content: center;
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .butcher-product-list-slider-meal-card-actions .product-price-main > h6 {
    text-align: center;
  }
  .butcher-product-list-slider-meal-card .progressive-image-loader-final-img {
    object-fit: contain;
    width: 100%;
    height: 12rem;
    border-radius: 0.5rem;
  }
  .butcher-product-list-container {
    padding: 4rem 1rem;
  }
}
@media only screen and (max-width: 1280px) {
  .butcher-product-list-container .slick-dots {
    left: -22px;
  }
}
@media screen and (max-width: 1000px) {
  .butcher-gap,
  .butcher-gap-alt {
    padding: 1rem;
  }
  .butcher-primary-section-grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, auto);
    width: 100%;
    height: 100%;
    gap: 1.5rem;
  }
  .butcher-product-list {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
    gap: 0.5rem;
  }
  .butcher-main-img {
    width: 21rem;
    height: 15rem;
  }
  .butcher-banner-section img {
    height: 40vh;
  }

  .butcher-primary-section-title {
    width: 100%;
  }
  .butcher-product-section-title {
    width: 100%;
  }
  .butcher-primary-section-grid-item-img {
    width: 12rem;
    height: 11rem;
  }
  .butcher-secondary-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .butcher-secondary-section-content {
    gap: 1rem;
  }
  .butcher-secondary-section-content-img {
    width: 15.625rem;
    height: 4.375rem;
  }
  .butcher-secondary-section-content-img-alt {
    width: 100%;
    height: 15rem;
  }
  .butcher-slider-section-row {
    flex-direction: column;
    width: 100%;
  }
  .butcher-slider-section-text {
    width: 100%;
    text-align: center;
  }
}
