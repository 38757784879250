.blog-promotional-features-main {
  padding: 32px 122px 16px 122px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  gap: 60px;
  background-color: #CE3C25;
}

.blog-promotional-features-card >  span {
  white-space: nowrap;
}

@media only screen and (max-width: 1440px) {
	.blog-promotional-features-card >  span {
    white-space: normal;
  }

  .blog-promotional-features-main {
    padding: 32px 104px 16px 104px;
    gap: 120px;
}
}
@media only screen and (max-width: 1050px) {
	.blog-promotional-features-main {
    padding: 32px 70px 16px 70px;
  }
}

@media only screen and (max-width: 750px) {
	.blog-promotional-features-main {
    padding: 32px 70px 16px 70px;
    align-items: center;
    flex-direction: column;
    gap: 16px;
}
}

.blog-promotional-features-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.blog-promotional-features-card > p {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
}

.blog-promotional-features-card >  span {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
}