.black-friday-banner-main-container {
  width: 100%;
  background-color: black;
  border-radius: 4rem 4rem 1rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
}

.black-friday-banner-top-content {
  border-radius: 4rem 4rem 1rem 1rem;
  padding: 5rem 4rem 0 4rem;
  max-width: 100%;
  height: 900px;
  background-image: url('../images/black-friday-banner-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.black-friday-banner-header-title,
.black-friday-banner-header-text {
  color: #fff;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 74px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.black-friday-banner-header-title {
  margin-bottom: 1rem;
}

.black-friday-banner-bold-text {
  font-weight: 700;
}

.black-friday-banner-header-text {
  font-size: 28px;
  line-height: normal;
}

.black-friday-banner-low-container {
  background-image: url('../images/black-friday-banner2-image.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 2rem 2rem 3.75rem 2rem;
  border-radius: 9rem 9rem 1rem 1rem;
  width: 100%;
  margin-top: -150px;
  box-sizing: border-box;
}

.black-friday-banner-low-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
}

.black-friday-banner-low-content {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: auto;
  gap: 32px;
}


.black-friday-banner-frames {
  width: 45%;
  display: flex;
  flex-direction: row;
  margin: 15px;
}

.black-friday-banner-frames img {
  border-radius: 32px;
  width: 200px;
  height: 200px;
}

.black-friday-banner-frames div {
  padding-left: 2rem;
}

.black-friday-banner-frames h1,
.black-friday-banner-frames p {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.black-friday-banner-frames p {
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  letter-spacing: -0.16px;
}

@media only screen and (max-width: 767px) {
  .black-friday-banner-top-content {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 1200px;
    background-image: url('../images/black-friday-banner-mobile-image.png');
    background-size: 100% 900px;
  }

  .black-friday-banner-top-content-text {
    margin-top: -20px;
  }

  .black-friday-banner-header-title {
    font-size: 48px;
  }

  .black-friday-banner-header-text {
    font-size: 24px;
    text-align: center;
  }

  .black-friday-banner-frames {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .black-friday-banner-frames img {
    width: 200px;
    height: 200px;
  }

  .black-friday-banner-low-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
  }

  .black-friday-banner-frames div {
    padding-top: 1rem;
    padding-left: 0;
  }

  .black-friday-banner-frames h1,
  .black-friday-banner-frames p {
    text-align: center;
  }

  .black-friday-banner-low-container {
    margin-top: -480px;
    padding: 2rem 1.5rem 3.75rem 1.5rem;
    border-radius: 64px 64px 16px 16px;
    background-image: url('../images/black-friday-banner2-mobile-image.png');
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .black-friday-banner-low-container {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
  }

  .black-friday-banner-top-content {
    height: 1200px;
    padding: 6rem 1rem;
  }

  .black-friday-banner-top-content-text {
    margin-top: -40px;
    width: 100%;
  }

  .black-friday-banner-frames {
    width: 80%;
    margin: 5px;
  }

  .black-friday-banner-frames div {
    padding-left: 1rem;
  }

  .black-friday-banner-frames img {
    width: 180px;
    height: 170px;
  }
}
