.progression-bar-container {
  width: 100%;
  margin-top: -16px;
}

.progression-bar-progress-box {
  width: 100%;
  margin: 0;
  margin-top: 35px;
}

.progression-bar-progress-box-bar {
  height: 8px;
  width: 100%;
  border-radius: 6px;
  margin-top: 6px;
  background: #F2F5FF;
}

.progression-bar-progress-box-progression-span {
  position: relative;
  display: flex;
  height: 100%;
  border-radius: 6px;
  transition: width 0.4s ease-in-out;
}
