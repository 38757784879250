.search-result-hit-horizontal-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 160px;
}

.search-result-hit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.search-result-hit-horizontal-title {
  color: #212121;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  width: 300px;
  line-height: 27px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result-hit-container a:hover {
  text-decoration: none;
  cursor: pointer;
}

.search-result-hit-container .search-result-hit-manufacture {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-feature-settings: 'calt' off;
  color: #212121;
  text-transform: uppercase;
}

.search-result-hit-container .search-result-hit-cooking-methods {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
}

.search-result-hit-container .search-result-hit-cooking-methods span {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-feature-settings: 'calt' off;
  color: #212121;
}

.search-result-hit-container .search-result-hit-cooking-methods img {
  width: 1.75rem;
  height: auto;
}

@media only screen and (max-width: 750px) {
  .search-result-hit-horizontal-main {
    min-height: 120px;
  }

  .search-result-hit-horizontal-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 380px) {
  .search-result-hit-horizontal-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 260px;
    margin-bottom: 20px;
  }

  .search-result-hit-container {
    min-height: 85px;
  }

  .search-result-hit-horizontal-title {
    min-height: 45px;
    font-size: 14px;
    text-align: center;
  }
}
