.wishlist-product-square {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: .5rem;
  padding: 1rem;
  background-color: #EDF2F499;
  flex-direction: column;
  height: 280px;
  width: 196px;
}

.wishlist-product-square a {
  text-decoration: none !important;
  color: #1e1e1e;
  margin: 0;
  padding: 0;
}


.wishlist-product-square .wishlist-product-square-link {
  text-decoration: none;
  color: #1e1e1e;
}

.wishlist-product-square .wishlist-product-square-info {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}


.wishlist-product-square-info .wishlist-product-square-variant {
  color: #1e1e1e;
  font-family: "Open Sans", sans-serif;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.wishlist-product-square-info .wishlist-product-square-name {
  font-size: 1rem;
  color: #1e1e1e;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  max-width: 156px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media screen and (max-width:800px) {
  .wishlist-product-square {
    width: 138px;
    height: 256px;
  }

  .wishlist-product-square-info .wishlist-product-square-name {
    font-size: .875rem;
    max-width: 100px;
  }

}
