.blog-hero-main {
  display: flex;
  background: linear-gradient(180deg, rgba(206, 60, 37, 0) 0%, rgba(47, 47, 47, 0.5) 100%), url(../images/BlogHeroDesktop.jpg);  
  background-repeat: no-repeat;
  justify-content: center;
  background-size: cover;
	background-position: top;

}

.blog-hero-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
}

.blog-hero-container-video-on {
	padding: 0;
}

.blog-hero-h1 {
  font-family: 'QUARTZO-light';
  text-align: center;
  font-style: normal;
  font-size: 96px;
  line-height: 160%;
  color: #FFFFFF;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);	
}

.blog-hero-h2 {
  font-family: 'Open Sans';
  text-align: center;
  width: 70%;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 160%;
  color: #FFFFFF;
  text-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
}

.blog-hero-play {
	display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: none;
	margin-top: 100px;
}

.blog-hero-play img {
	color: #fff;
	opacity: 1;
	margin-left: 10px;
}


@keyframes modal-video {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes modal-video-inner {
	from {
		transform: translate(0, 100px);
	}

	to {
		transform: translate(0, 0);
	}
}

.modal-video {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	z-index: 1000000;
	cursor: pointer;
	opacity: 1;
	animation-timing-function: ease-out;
	animation-duration: .3s;
	animation-name: modal-video;
	-webkit-transition: opacity .3s ease-out;
	-moz-transition: opacity .3s ease-out;
	-ms-transition: opacity .3s ease-out;
	-o-transition: opacity .3s ease-out;
	transition: opacity .3s ease-out;
}

.modal-video-effect-exit {
	opacity: 0;
}
.modal-video-effect-exit > .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px);
}

.modal-video-body {
	max-width: 960px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 0 10px;
  display: flex;
  justify-content: center;
	box-sizing: border-box;
}

.modal-video-inner {
	display: flex;
  justify-content: center;
  align-items: center;
	width: 100%;
	height: 100%;  
}
@media (orientation: landscape) {
  .modal-video-inner {
        padding: 10px 60px;
        box-sizing: border-box;
    }
  }

.modal-video-movie-wrap {
	width: 100%;
	height: 0;
	position: relative;
	padding-bottom: 56.25%;
	background-color: #333;
	animation-timing-function: ease-out;
	animation-duration: .3s;
	animation-name: modal-video-inner;
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition: -webkit-transform .3s ease-out;
	-moz-transition: -moz-transform .3s ease-out;
	-ms-transition: -ms-transform .3s ease-out;
	-o-transition: -o-transform .3s ease-out;
	transition: transform .3s ease-out;
}

.modal-video-movie-wrap > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

.modal-video-close-btn {
	position: absolute;
	z-index: 2;
	top: -45px;
	right: 0px;
	display: inline-block;
	width: 35px;
	height: 35px;
	overflow: hidden;
	border: none;
	background: transparent;

}
 @media (orientation: landscape) {
  .modal-video-close-btn{
    top: 0;
    right: -45px;
  }     
  }

	.modal-video-close-btn:before {
		transform: rotate(45deg);
	}

	.modal-video-close-btn:after {
		transform: rotate(-45deg);
	}

	.modal-video-close-btn:before,
	.modal-video-close-btn:after {
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		top: 50%;
		left: 0;
		margin-top: -1px;
		background: #fff;
		border-radius: 5px;
		margin-top: -6px;
	}

@media only screen and (max-width: 1440px) {
	
	.blog-hero-h1 {
		font-size: 60px;
	}
	.blog-hero-h2 {
		font-size: 26px;
		width: 70%;
	}

}

@media only screen and (max-width: 1050px) {
	
	.blog-hero-container {
		justify-content: center;
		align-items: center;
		height: 640px;
	}

	.blog-hero-h1 {
		font-size: 60px;
	}

	.blog-hero-h2 {
		font-size: 26px;
		width: 80%;
	}

	.blog-hero-play {
		margin-top: 220px;
	}
}


@media only screen and (max-width: 750px) {

	.blog-hero-h1 {
		font-size: 36px;
		width: 90%;
	}
	.blog-hero-h2 {
		font-size: 16px;
		width: 70%;
	}
}