.out-of-stock-notice-main {
  margin-top: 5px;
  background-color: #FFDCE0;
  color: #1E1E1E;
  width: 100%;
  min-height: 30px;
  text-align: center;
  letter-spacing: 1px;
  padding: 2px 5px 0px 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.out-of-stock-notice-main>i {
  margin-right: 5px;
  color: #EF4D61;
}

.out-of-stock-notice-white-text-span {
  font-weight: 700;
}

@media only screen and (max-width: 500px) {
  .out-of-stock-notice-main {
    font-size: 14px !important;
    padding: 4px 5px 0px 5px;
  }
}
