.fat-level-selector-container{
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.fat-level-option-container{
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #b7b7b7;
    display: flex;
    align-items: center;
    border-radius: 8px;
    gap: 8px;
    height: 40px;
    padding: 8px 12px;
    cursor: pointer;
}

.fat-level-option-title{
    padding: 0px;
    margin: 0px;
    font-weight: bold;
    color: #b7b7b7;
}

.selected{
  border-color: #ce3c25;
  color: #ce3c25;
}


