.black-friday-product-list-main-container .slick-dots {
  display: none !important;
}

.black-friday-product-list-arrow-icon {
  display: none !important;
}

.black-friday-product-list-content {
  background-image: url('../images/black-friday-banner2-image.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 4rem 4rem;
  border-radius: 16px;
  width: 100%;
}

.black-friday-product-list-image-container {
  border-radius: 8px;
  width: 320px;
  height: 320px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.black-friday-product-discout-square {
  width: 50px;
  height: 60px;
  clip-path: polygon(0 0, 100% 0%, 100% 65%, 50% 100%, 0 65%);
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Open Sans' !important;
  font-style: normal;
  color: #fff !important;
  align-self: flex-end;
  justify-self: flex-end;
  margin-right: 10px;
}

.black-friday-product-discout-square p {
  color: #fff;
  font-feature-settings: 'calt' off;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 150%;
}

.black-friday-product-discout-square>span {
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
}

.black-friday-product-list-individual-product-container {
  padding: 0 4rem;
  display: flex;
  flex-direction: row;
}

.black-friday-product-list-product-details {
  padding: 1rem 2rem;
}

.black-friday-product-list-product-details .black-friday-product-list-product-text {
  padding-bottom: 1rem;
}

.black-friday-product-list-product-details .black-friday-product-list-product-manufacturer,
.black-friday-product-list-product-details h4,
.black-friday-product-list-product-details h1,
.black-friday-product-list-icons-container p {
  color: #fff;
  font-feature-settings: 'calt' off;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.black-friday-product-list-product-details h4 {
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 32px;
  font-weight: 700;
}

.black-friday-product-list-product-details h1 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 1rem;
}

.black-friday-product-list-icons-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 1rem;
}

.black-friday-product-list-icons-container div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.black-friday-product-list-icons-container p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
}

.black-friday-product-list-buy-container {
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 1rem;
}

.black-friday-product-list-buy-container .product-price-main {
  gap: 16px;
}

.black-friday-product-list-buy-container .product-price-main h6 {
  align-self: flex-end;
  padding-left: 2px;
}

.black-friday-product-list-buy-container .product-price-main h6,
.black-friday-product-list-buy-container .product-price-main p {
  color: #fff;
  font-size: 20px;
}

.black-friday-product-list-buy-container .product-price-main h5 {
  font-size: 40px;
  font-weight: 700;
}

.black-friday-product-list-buy-container .add-after-exists-button-content-value {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  margin: 0;
  padding: 0;
  color: #ce3c25 !important;
}

.black-friday-product-list-buy-container .product-price-main {
  height: 80px;
}

.black-friday-product-list-product-lower-content {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
}

.black-friday-product-list-icons-container {
  flex-direction: column;
}

.black-friday-product-list-icons-content {
  display: flex;
  flex-wrap: wrap;
}

.black-friday-product-list-icons-content div {
  margin-right: 1rem;
}

@media only screen and (max-width: 767px) {
  .black-friday-product-list-content {
    padding: 3.75rem 1rem;
    padding-bottom: 0;
    height: 900px;
  }

  .black-friday-product-list-individual-product-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }

  .black-friday-product-list-image-container {
    width: 260px;
    height: 230px;
  }

  .black-friday-product-list-product-details {
    padding: 1rem 0;
    width: 280px;
  }

  .black-friday-product-list-product-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .black-friday-product-list-product-details h1 {
    font-size: 24px;
    text-align: center;
  }

  .black-friday-product-list-icons-container {
    flex-direction: column;
  }

  .black-friday-product-list-buy-container {
    flex-direction: column;
    justify-content: center;
  }

  .black-friday-product-list-icons-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: row;
  }

  .black-friday-product-list-icons-container>div {
    width: 34%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .black-friday-product-list-buy-container .product-price-main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .black-friday-product-list-buy-container .product-price-main {
    gap: 14px;
    margin-bottom: 2rem;
  }

  .black-friday-product-list-buy-container .product-price-main p,
  .black-friday-product-list-buy-container .product-price-main h6 {
    font-size: 16px;
    font-weight: 400;
  }

  .black-friday-product-list-buy-container .product-price-main h6 {
    align-self: center;
  }

  .black-friday-product-list-buy-container .product-price-main h5 {
    font-size: 28px;
    font-weight: 700;
  }

  .black-friday-product-list-buy-container .add-to-cart-before-button {
    width: 240px;
    height: 40px;
    gap: 8px;
  }

  .black-friday-product-list-buy-container .add-to-cart-before-button-text p {
    color: #fff;
    font-feature-settings: 'calt' off;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.14px;
    margin-bottom: 2px;
  }

  .black-friday-product-list-content .landing-product-list-arrow-container-right,
  .black-friday-product-list-content .landing-product-list-arrow-container-left {
    display: none !important;
  }

  .black-friday-product-list-arrow-icon {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    display: block !important;
  }

  .black-friday-product-list-product-lower-content {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .black-friday-product-list-icons-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .black-friday-product-list-icons-content {
    width: 100% !important;
  }

  .black-friday-product-list-icons-content div {
    width: 30%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .black-friday-product-list-individual-product-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }

  .black-friday-product-list-buy-container {
    flex-direction: column;
  }
}
