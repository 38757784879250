.burger-shop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 4rem 0.5rem 0 0.5rem;
}
.burger-shop-icon-column {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
}

.burger-shop-icon-column-item {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 0.5rem;
}
.burger-shop-icon-column-item-img {
  width: 7rem;
  height: 100%;
}
.burger-shop-icon-column-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: min(1.2rem, 6vw);
  text-align: center;
  color: #eb5b1e;
}
.burger-shop-banner-alt {
  min-width: 100%;
  height: 32rem;
  display: flex;
  padding: 2rem;
  align-items: flex-start;
  justify-content: center;
  background-image: url('../images/hambugerAlt.png');
  background-position: center;
  background-repeat: no-repeat;
  filter: opacity(0.95);
}
.burger-shop-banner-alt-text {
  color: #ffffff;
  font-size: min(2.6rem, 6vw);
  font-weight: 700;
  width: 54%;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.burger-shop-banner-alt-text > span {
  color: #eab91f;
}
.burger-shop-grid-column {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2rem;
  counter-reset: gridcounter;
  margin-bottom: 2rem;
}

.burger-shop-grid-item,
.burger-shop-grid-item-alt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.burger-shop-grid-item-alt {
  margin-top: 1rem;
}
.burger-shop-grid-item-line {
  height: 1px;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  margin-top: 1rem;
}
.burger-shop-grid-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  width: 100%;
  font-size: min(1.4rem, 7vw);
  text-align: center;
  color: #494949;
  position: relative;
}
.burger-shop-grid-item-text-number {
  counter-increment: gridcounter;
  content: counter(gridcounter);
  color: #eb5b1e;
  font-size: min(4rem, 15vw);
  font-weight: 700;
}

.burger-shop-grid-item-img {
  width: 15rem;
  height: auto;
}
.burger-shop-grid-item-img-alt {
  width: 10rem;
  height: auto;
}
.burger-shop-meat-combo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #eb5b1e;
  font-size: 4rem;
  font-weight: 900;
}
.burger-shop-meat-combo img {
  width: 12rem;
  height: 10rem;
}
.burger-shop-grid-item-img-comments {
  width: 12rem;
  height: 8rem;
}
.burger-shop-grid-item-title-comments {
  color: #eb5b1e;
  font-size: min(1.5rem, 10vw);
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}
.burger-shop-grid-item-text-comments {
  font-size: min(1.4rem, 7vw);
  text-align: center;
  width: 50%;
}
.burger-shop-grid-item-client-comments {
  font-size: 1rem;
}
@media screen and (max-width: 800px) {
  .burger-shop-icon-column-item-img {
    width: 4rem;
    height: 100%;
  }
  .burger-shop-banner-alt-text {
    width: 100%;
  }
  .burger-shop-meat-combo {
    flex-direction: column;
    gap: 0;
  }
  .burger-shop-meat-combo > img {
    width: 10rem;
    height: 7rem;
  }
  .burger-shop-grid-item-text::before {
    left: -18rem;
    top: -3rem;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 10;
  }
  .burger-shop-grid-item-text-comments {
    font-size: 1.1rem;
    text-align: center;
    width: 100%;
  }
  .burger-shop-grid-item-title-comments {
    font-size: 1.6rem;
  }
  .burger-shop-grid-item-text {
    width: 100%;
  }
  .burger-shop-grid-item-text {
    font-size: min(1.2rem, 6vw);
  }
}
