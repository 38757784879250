.blog-featured-posts-main {
  display: flex;
  flex-direction: column;
  padding: 30px 122px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.blog-featured-posts-main > h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 160%;
  align-items: center;
  color: #CE3C25;
}

.blog-featured-posts-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
}

.blog-featured-posts-anchor-card {
  text-decoration: none;
}

.blog-featured-posts-card {
  width: 582px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-featured-posts-card > img {
  display: flex;
  width: 582px;
  height: 275px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.16));
  border-radius: 16px;
  object-fit: cover;
}

.blog-featured-posts-card > h3 {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 160%;
  align-items: center;
  color: #CE3C25;
}

.blog-featured-posts-card > p {
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  padding: 0 16px;
  text-align: center;
  background: linear-gradient(180deg, #000000 41.15%, rgba(0, 0, 0, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media only screen and (max-width: 1440px) {
	.blog-featured-posts-card {
  width: 434px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-featured-posts-card > img {
  display: flex;
  width: 434px;
  height: 204px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.16));
  border-radius: 16px;
  object-fit: cover;
}

.blog-featured-posts-card > h3 {
  font-size: 26px;
}

.blog-featured-posts-card > p {
  font-size: 16px;
}

}

@media only screen and (max-width: 1050px) {
	.blog-featured-posts-main > h2 {
    font-size: 26px;
  }

  .blog-featured-posts-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

@media only screen and (max-width: 750px) {

  .blog-featured-posts-main > h2 {
    font-size: 20px;
  }

  .blog-featured-posts-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  .blog-featured-posts-card {
   width: 256px;
  }

  .blog-featured-posts-card > img {
    width: 256px;
    height: 144px;
  }

  .blog-featured-posts-card > h3 {
    font-size: 16px;
  }

  .blog-featured-posts-card > p {
    font-size: 14px;
  }

}
