@font-face {
  font-family: 'Retrosans';
  src: url('../../common/fonts/Retrosans_portuguese.otf');
}

.barbecue-button {
  font-size: min(1.3rem, 10vw);
  width: min(17rem, 80vw);
  padding: 10px 20px;
  height: auto;
  border-radius: 15px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  background: linear-gradient(84.63deg, #9a2b18 0.04%, #c63d1e 100%);
  color: #fff;
  box-sizing: border-box;
  text-decoration: none;
}
.barbecue-button:hover {
  text-decoration: none;
  color: #fff;
}

.barbecue-banner-section {
  width: 100%;

  align-items: center;
  justify-content: flex-start;
  background-image: url('../images/barbecueBanner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.barbecue-banner-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  align-items: center;
  padding: 4rem 2rem;
}

.barbecue-banner-section-text {
  font-size: min(6rem, 18vw);
  font-weight: 500;
  text-align: center;
  font-family: 'Retrosans', sans-serif;
  text-transform: uppercase;
  color: #fde700;
  display: flex;
  line-height: 3.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.barbecue-banner-section-text > small {
  color: #fff;
  font-size: min(2rem, 9vw);
  line-height: 2.5rem;
}

.barbecue-banner-section-text > span {
  color: #fff;
  font-size: min(6rem, 18vw);
  line-height: 3.5rem;
}

.barbecue-banner-section-benefits {
  font-family: 'Retrosans', sans-serif;
  color: #fde700;
  font-size: min(3rem, 10vw);
  line-height: 2rem;
}

.barbecue-banner-section-benefits-item {
  display: flex;
  justify-content: center;
  align-self: flex-end;
  gap: 0.8rem;
}
.barbecue-banner-section-benefits-item-icon {
  height: 2.5rem;
}

.barbecue-banner-section-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 1.3rem;
  font-size: min(1.2rem, 11vw);
  color: #fff;
  text-align: center;
}

.barbecue-calculator-section {
  width: 100%;
  height: auto;
  z-index: 1;
  top: -25px;
  height: 24rem;
  position: relative;
  background-image: url('../images/calculatorBackground.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 0;
  display: flex;
  align-items: flex-start;
}

.barbecue-calculator-section-info {
  font-family: 'Retrosans', sans-serif;
  width: 100%;
  color: #fde700;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.barbecue-calculator-section-info-text {
  line-height: 2.2rem;
  font-size: min(2.5rem, 13vw);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.barbecue-calculator-section-info-text small,
.barbecue-calculator-section-info-text big {
  color: #fff;
}
.barbecue-calculator-section-info-text p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.barbecue-calculator-section-info-icon {
  height: min(5rem, 14vw);
  width: auto;
}

.barbecue-calculator-section-info-button {
  font-size: 1.2rem;
  width: min(18rem, 80vw);
  padding: 10px 20px;
  border-radius: 15px;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  background: linear-gradient(84.63deg, #9a2b18 0.04%, #c63d1e 100%);
  color: #fff;
  box-sizing: border-box;
}

.barbecue-calculator-section-info-button:hover {
  text-decoration: none;
  color: #fff;
}

.barbecue-calculator-section-info-button > span {
  display: none;
}

.barbecue-products-section {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 1.3rem;
  text-align: center;
  gap: 5rem;
  padding-bottom: 7rem;
  border-end-start-radius: 20px;
  border-end-end-radius: 20px;
  position: relative;
  bottom: -20px;
  z-index: 1;
}

.barbecue-products-section-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.barbecue-products-section-header-title,
.barbecue-products-section-info-title {
  font-family: 'Retrosans';
  text-transform: uppercase;
  font-size: min(2.7rem, 14vw);
  color: #eb5b1e;
  padding: 0 0.1rem;
}

.barbecue-products-section-header-subtitle,
.barbecue-products-section-info-description {
  font-family: 'Montserrat', sans-serif;
  padding: 0 1.5rem;
}
.barbecue-products-section-info-subtitle {
  font-weight: 500;
  font-size: 1.2rem;
}

.barbecue-products-section-info-description {
  font-weight: 600;
  font-size: 0.8rem;
}

.barbecue-products-section-info-paragraph {
  font-weight: 600;
  font-size: 1.17rem;
  color: #eb5b1e;
  padding: 0 5rem;
}

.barbecue-products-section-info-container {
  width: 20rem;
}

.barbecue-feedback-section {
  background-color: #000;
  padding: 7rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5rem;
}

.barbecue-feedback-section-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.barbecue-feedback-section-info-title {
  font-family: 'Retrosans';
  text-transform: uppercase;
  font-size: min(2.7rem, 14vw);
  color: #ffbb00;
  padding: 0 0.1rem;
}

.barbecue-feedback-section-info-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  color: #fff;
  padding: 0 1rem;
  margin: 0;
}

.barbecue-feedback-section-comment {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.barbecue-feedback-section-comment-card {
  height: 21rem;
  width: min(25rem, 85vw);
  background-color: #fff;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
}

.barbecue-feedback-section-comment-card-stars {
  height: 10%;
  display: flex;
  gap: 0.4rem;
}

.barbecue-feedback-section-comment-card-stars > i {
  color: #ffc02b;
}
.barbecue-feedback-section-comment-card-text {
  height: 60%;
  font-size: 1.1rem;
  color: #121212;
}
.barbecue-feedback-section-comment-card-author {
  height: 30%;
  color: #ffbb00;
  display: flex;
  padding: 1rem 0;
  gap: 1rem;
  align-items: center;
}

.barbecue-feedback-section-comment-card-author-img {
  height: 3rem;
}
.barbecue-feedback-section-comment-card-author-user {
  font-family: 'Retrosans';
  height: 50%;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  font-size: 1.5rem;
}
.barbecue-feedback-section-comment-card-author-user > sub {
  color: #121212;
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  font-size: 0.7rem;
  font-weight: 500;
}

.barbecue-feedback-section-faq {
  padding: 0 1rem;
  width: 100%;
}

.barbecue-feedback-section-info-text {
  display: flex;
  flex-direction: column;
  font-family: 'Retrosans', sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: min(1.8rem, 11vw);
  color: #ffbb00;
  line-height: 2.2rem;
}

.barbecue-feedback-section-info-text > big {
  font-size: 3rem;
  color: #eb5b1e;
}

.barbecue-feedback-section-info-paragraph {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

.barbecue-feedback-section-invite {
  background-image: url('../images/meatRight.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30rem;
  width: 100%;
  align-self: start;
}

.barbecue-feedback-section-invite-text {
  width: 80%;
  margin-left: 2rem;
  margin-top: 2rem;
  text-align: left;
  text-transform: uppercase;
}
.barbecue-feedback-section-invite-text > h1 {
  font-family: 'Retrosans';
  font-size: min(3rem, 15vw);
  line-height: 2rem;
  color: #ffe400;
  margin-bottom: 2rem;
  width: 80%;
}
.barbecue-feedback-section-invite-text > h1 > small {
  color: #fff;
  font-size: min(1.8rem, 12vw);
}
.barbecue-feedback-section-invite-text > p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #ffe400;
  font-size: min(1.8rem, 13vw);
  padding-right: 3rem;
  line-height: 1.8rem;
  width: 100%;
}
.barbecue-feedback-section-invite-text > p > span {
  font-size: min(1.1rem, 12vw);
  line-height: 0.7rem;
}

.barbecue-feedback-section-invite-text > p > span > span {
  color: #fff;
}
@media screen and (min-width: 400px) {
  .barbecue-banner-section-text {
    line-height: 5rem;
  }
}

@media screen and (min-width: 450px) {
  .barbecue-calculator-section-img {
    width: 80%;
    align-self: center;
    margin: 0;
  }
  .barbecue-feedback-section-comment-card {
    height: 17rem;
  }
  .barbecue-feedback-section-info-text {
    font-size: min(3.5rem, 11vw);
    line-height: 4rem;
  }
  .barbecue-feedback-section-info-text > big {
    font-size: 5rem;
  }
}

@media screen and (min-width: 800px) {
  .barbecue-banner-section {
    background-image: url('../images/barbecueBannerDesktop.png');
    height: 80vh;
  }
  .barbecue-banner-section-wrapper {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 40.83%,
      #ffffff 100%
    );
    align-items: flex-start;
    padding: 4rem 10vw;
  }

  .barbecue-banner-section-text {
    line-height: 5rem;
    font-size: min(4rem, 12vw);
    align-self: flex-start;
    width: 50%;
    text-align: left;
    align-items: flex-start;
    padding-right: 10%;
  }
  .barbecue-banner-section-benefits-item {
    display: none;
  }
  .barbecue-banner-section-subtitle {
    display: none;
  }
  .barbecue-banner-section > .barbecue-button {
    align-self: flex-start;
    margin-left: 5%;
  }

  .barbecue-calculator-section {
    background-image: url('../images/calculatorBackgroundDesktop.png');
    width: min(75rem, 80vw);
    height: 30rem;
    top: -35px;

    align-items: center;
    margin: 0 auto;
    background-position: center;
  }
  .barbecue-calculator-section-info {
    margin-left: 200px;
  }

  .barbecue-calculator-section-info-text p {
    flex-direction: row;
  }

  .barbecue-calculator-section-info-text small,
  .barbecue-calculator-section-info-text big {
    font-size: min(2.5rem, 13vw);
  }

  .barbecue-calculator-section-info-text small::after {
    content: ' ';
    white-space: pre;
  }

  .barbecue-calculator-section-info-text span::after {
    content: ' ';
    white-space: pre;
  }

  .barbecue-calculator-section-info-icon {
    display: none;
  }
  .barbecue-calculator-section-info-button {
    width: min(450px, 40vw);
  }
  .barbecue-calculator-section-info-button > span {
    display: inline;
  }

  .barbecue-products-section-main {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  .barbecue-products-section-info {
    width: 23rem;
    border: 2px solid #000000;
    border-radius: 30px;
    padding: 2rem;
  }
  .barbecue-products-section-info-container {
    width: 100%;
    height: 20rem;
  }

  .barbecue-products-section-info-paragraph {
    font-size: 0.8rem;
    padding: 0;
    width: 100%;
  }
  .barbecue-products-section-info-title {
    font-size: 2rem;
  }
  .barbecue-products-section-info-description {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1000px) {
  .barbecue-banner-section {
    height: 90vh;
  }
  .barbecue-banner-section-wrapper {
    padding: 4rem 15vw;
  }

  .barbecue-banner-section-text {
    padding-right: 20%;
  }

  .barbecue-feedback-section-invite {
    background-image: url('../images/meatRightDesktop.png');
    padding: 0 20rem;
    align-self: center;
    height: 35rem;
    background-size: contain;
  }
  .barbecue-feedback-section-invite-text {
    width: 50%;
  }
  .barbecue-feedback-section-invite-text > h1 {
    font-size: 4rem;
    line-height: 3rem;
  }
  .barbecue-feedback-section-invite-text > h1 > small {
    color: #fff;
    font-size: 3rem;
  }
  .barbecue-feedback-section-invite-text > p {
    font-size: 3rem;
    font-family: 'Retrosans';
    font-weight: 500;
    line-height: 2.8rem;
  }
  .barbecue-feedback-section-invite-text > p > span {
    font-size: 2.8rem;
  }
  .barbecue-feedback-section-invite-text > p > span > span {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1440px) {
  .barbecue-banner-section {
    height: 80vh;
  }

  .barbecue-products-section-main {
    gap: 9rem;
  }
}

@media screen and (min-width: 1920px) {
  .barbecue-banner-section {
    height: 70vh;
  }
  .barbecue-banner-section-wrapper {
    padding-top: 11rem;
    padding-right: 35rem;
  }
}
